<template>
  <v-container fluid>
    <v-list>
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ modifier.name }}
                <small class="mx-2">
                  <template v-if="modifier.min > 0">
                    <b>{{ modifier.min }} Required</b>
                  </template>
                  <template v-else>
                    <i>Optional</i>
                  </template>
                </small>
                <small color="cyan" class="px-3">Max {{ modifier.max }}</small>

                <v-icon small v-if="valid" color="green">checked</v-icon>
                <v-icon small v-else color="red">block</v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-for="subItem in modifier.options">
          <v-list-item :key="subItem.id" v-if="subItem.available">
            <v-radio-group
              v-model="selected"
              v-if="modifier.min == 1 && modifier.max == 1"
            >
              <v-radio :value="subItem.id"></v-radio>
            </v-radio-group>
            <v-checkbox
              v-model="selected"
              :value="subItem.id"
              v-else
            ></v-checkbox>

            <v-list-item-content>
              <v-list-item-title>{{ subItem.name }}</v-list-item-title>
            </v-list-item-content>
            <small>{{ subItem.available ? '' : 'NA' }}</small>
            <v-list-item-action>
              <span>{{ subItem.price | currency }}</span>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list-group>
    </v-list>
  </v-container>
</template>

<script>
export default {
  props: {
    modifier: {
      Type: Object
    }
  },
  data() {
    return {
      selected: []
    }
  },
  watch: {
    selected: function() {
      this.$emit('updateModifier', {
        selected: this.selected,
        //id: this.modifier.id,
        modifier: this.modifier,
        valid: this.valid
      })
    }
  },
  computed: {
    valid() {
      let valid = true

      if (this.modifier.min && this.modifier.min > 0) {
        if (Array.isArray(this.selected)) {
          valid = this.selected.length >= this.modifier.min
        } else {
          valid = this.selected != null
        }
      }

      if (valid && this.modifier.max) {
        if (Array.isArray(this.selected)) {
          valid = this.selected.length <= this.modifier.max
        }
      }
      return valid
    }
  }
}
</script>
