import firebase, { analytics } from '@/firebase-init'
import axios from 'axios'

import { i18n } from 'fod-core'
import moment from 'moment'
/*
const CalcTotalPrice = cart => {
  let totalPrice = 0
  if (cart) {
    cart.forEach(item => {
      totalPrice += item.totalPrice
        ? item.totalPrice
        : item.dish.price * item.orderAmount
    })
  }
  return totalPrice
} */
export default {
  state: {
    order: null,
    cart: [],
    deliveryEstimate: null,
    orders: []
  },
  mutations: {
    setOrder(state, payload) {
      state.order = payload
    },
    setCart(state, payload) {
      state.cart = payload
    },

    setOrders(state, payload) {
      state.orders = payload
    },

    setDeliveryEstimate(state, payload) {
      state.deliveryEstimate = payload
    },
    onChangeQuantityHandler(state, payload) {
      let quantity = payload.e.target.value,
        cartItem = payload.cartItem,
        indexOfItem = state.cart.cart.indexOf(cartItem)
      state.cart.cart[indexOfItem].totalPrice = cartItem.dish.price * quantity
      state.cart.cart[indexOfItem].orderAmount = quantity
    },
    onAddProductToCart(state, payload) {
      let newProduct = {
        id: payload.objectID,
        productImg: payload.image,
        name: payload.name,
        price: payload.price,
        quantity: 1,
        total: payload.price
      }
      state.cart.cart.push(newProduct)
    },
    onDeleteProductFromCart(state, payload) {
      let index = state.cart.cart.indexOf(payload)
      state.cart.cart.splice(index, 1)
    },

    onDeleteCard(state, payload) {
      let index = state.creditCard.indexOf(payload)
      state.creditCard.splice(index, 1)
    },

    toggleEcommerceSidebarFilterHandler(state, payload) {
      state.ecommerceSidebarFilter = payload
    }
  },
  actions: {
    touchCart(context) {
      let uid = context.getters.user.id
      firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .update('touchTime', moment.utc().valueOf())
    },
    loadCart(context, payload) {
      let uid = context.getters.user.id
      //context.commit('setLoading', true)
      let unsubscribe = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .onSnapshot(data => {
          if (data.exists) {
            let home = data.data()
            context.commit('setCart', home)

            if (
              payload &&
              payload.load_chef &&
              home &&
              home.cart &&
              home.cart.length > 0
            ) {
              // fully load chef
              let chef = home.cart[0].dish.chef

              context.dispatch('loadChefById', chef.id)
            }
          }
        })

      return unsubscribe
    },
    loadCartOld(context, payload) {
      let uid = context.getters.user.id
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .get()
        .then(d => {
          if (d.exists) {
            let cart = d.data().cart
            context.commit('setCart', cart)
            if (payload && payload.load_chef && cart && cart.length > 0) {
              // fully load chef
              let chef = cart[0].dish.chef
              context.dispatch('loadChefById', chef.id)
            }
            return d.data()
          } else {
            context.commit('setCart', [])
          }
        })
        .then(o => {
          context.commit('setLoading', false)
          return o
        })
        .catch(e => {
          context.commit('setLoading', false)
          console.error('Error loading cart', e)
        })
    },
    syncCart(context) {
      let cart = context.getters.cart.cart
      let uid = context.getters.user.id
      const now = moment.utc().valueOf()
      // let totalPrice = CalcTotalPrice(cart)
      let payload = { cart, updatedTime: now }

      return firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .update(payload)
    },
    addToCart({ commit, getters, dispatch }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      let uid = getters.user.id
      const producer =
        payload.dish && payload.dish.chef ? payload.dish.chef.id : null
      if (uid === producer) {
        commit('setError', {
          message: 'You can not request order for your own product!'
        })
        return
      }

      analytics.logEvent('add_to_cart', {
        uid: uid,
        amount: payload.orderAmount,
        product_id: payload.dish.id,
        producer_id: producer,
        category: 'ecommerce',
        modifiers: payload.modifiers ? Object.keys(payload.modifiers).length : 0
      })

      let updatedTime = firebase.firestore.Timestamp.now().toMillis()

      payload.uid = uid
      payload.updateDate = updatedTime
      payload.orderDate = new Date().getTime()

      console.log('Cart:', payload)

      const orderRef = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
      payload = JSON.parse(JSON.stringify(payload))
      return firebase
        .firestore()
        .runTransaction(function(transaction) {
          return transaction.get(orderRef).then(function(order) {
            if (!order.exists) {
              console.log('cart does not exist')
              orderRef.set({
                cart: []
              })
            } else {
              let list = order.data().cart
              console.log(`cart exists ${uid} as:`, list)

              let found = false

              if (list && list.length > 0) {
                for (let index = 0; index < list.length; index++) {
                  let item = list[index]

                  if (item.dish.chef.id != producer) {
                    throw 'Cart has item from different chef!'
                  }
                  item.index = index
                  if (item.dish.id == payload.dish.id && !payload.modifiers) {
                    item.orderAmount =
                      parseInt(item.orderAmount) + parseInt(payload.orderAmount)
                    found = true
                    list[index] = item
                    console.log(
                      `Updating cart ${item.orderAmount} amount:  ${payload.orderAmount} `
                    )
                  }
                }
              } else {
                list = []
              }
              //trace.putAttribute('user', uid)
              //trace.putMetric('cartTotalItem', list.length)
              //trace.stop()

              if (!found) {
                console.log('adding to cart:', payload)
                // updates time to trigger firebase functions
                orderRef.set({ updatedTime: updatedTime }, { merge: true })

                transaction.update(orderRef, {
                  cart: firebase.firestore.FieldValue.arrayUnion(payload)
                })
                list.push(payload)
              } else {
                // updates time to trigger firebase functions
                orderRef.set({ updatedTime: updatedTime }, { merge: true })

                transaction.update(orderRef, { cart: list })
              }

              commit('setCart', list)
              return list
            }
          })
        })
        .then(d => {
          console.log('Transaction successfully committed!:', d)
          dispatch('setMessage', {
            title: 'In Progress',
            body: 'Added to your shopping cart.'
          })
        })
        .catch(function(error) {
          console.warn('Transaction failed: ', error)
          commit('setError', {
            title: 'Start a New Cart?',
            message: i18n.t('Common.cart_conflict_msg')
          })
          throw 'Unable to process the cart!'
        })
    },

    addProductToCart(context, payload) {
      context.commit('onAddProductToCart', payload)
    },
    onDeleteProductFromCart(context, payload) {
      let uid = context.getters.user.id
      const orderRef = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
      return firebase
        .firestore()
        .runTransaction(function(transaction) {
          return transaction.get(orderRef).then(function(order) {
            if (!order.exists) {
              console.log('cart does not  exists')
            } else {
              console.log('cart exists')
              let list = order.data().cart

              if (list) {
                list = list.filter(li => li.dish.id !== payload.dish.id)
                transaction.update(orderRef, {
                  cart: list,
                  updatedTime: moment().unix()
                })
              }
              context.commit('onDeleteProductFromCart', payload)
              analytics.logEvent('remove_from_cart', {
                uid,
                category: 'ecommerce'
              })
            }
          })
        })
        .then(function() {
          console.log('Transaction successfully committed!')
        })
        .catch(function(error) {
          console.warn('Transaction failed: ', error)
          context.commit('setError', {
            title: 'Unable to delete',
            message: 'Problem deleting item from Cart.'
          })
        })
    },
    changeQuantityHandler(context, payload) {
      context.commit('onChangeQuantityHandler', payload)
      context.dispatch('syncCart')
    },
    onDeleteCard(context, payload) {
      context.commit('onDeleteCard', payload)
      context.dispatch('syncCart')
    },

    applyPromoCode: async (context, payload) => {
      let uid = context.getters.user.id
      let token = await firebase.auth().currentUser.getIdToken()
      console.log('token: ', token)
      let api = `https://derby.${process.env.VUE_APP_DOMAIN}`

      // let api = `http://localhost:9090`

      return await axios({
        method: 'get',
        url: `${api}/coupon?code=${payload.code}&pid=${payload.chef_id ||
          ''}&uid=${uid}`,
        headers: { Authorization: 'Bearer ' + token }
      })
        .then(({ data }) => {
          if (data) {
            console.log('Promo code retrieved: ', data)
            // TODO: check the expiration
            if (data.Status === 'active') {
              analytics.logEvent('checkout_progress', {
                coupon: payload.code,
                uid
              })
              firebase
                .firestore()
                .collection('orders')
                .doc(uid)
                .update({ promo_code: payload.code })
              return {
                code: data,
                status: data.Status
              }
            }

            if (data.StatusCode == 414 && payload.cart) {
              // try product-by-product
              payload.cart.cart.forEach(p => {
                if (p.dish) {
                  console.log('Getting coupon for ,', p.dish.id)
                  axios
                    .get(
                      `${api}/coupon?code=${payload.code}&pid=${payload.chef_id}&uid=${uid}&did=` +
                        p.dish.id,
                      {
                        headers: { Authorization: 'Bearer ' + token }
                      }
                    )
                    .then(par => {
                      console.log('Result for product: ', par.data)
                      if (par.data.Status === 'active') {
                        console.log('Found coupon for ', p.dish.id)

                        firebase
                          .firestore()
                          .collection('orders')
                          .doc(uid)
                          .update({ promo_code: payload.code })
                        context.commit('setMessage', {
                          title: 'Coupon will be applied to some items in cart',
                          body: ''
                        })
                      }
                    })
                }
              })
            }
            return {
              code: data,
              status: data.Status
            }
          }
        })
        .catch(e => {
          analytics.logEvent('excption', {
            description: `${e}`,
            coupon: payload.code,
            uid
          })
        })
    },
    toggleEcommerceSidebarFilter(context, payload) {
      context.commit('toggleEcommerceSidebarFilterHandler', payload)
    },
    emptyCart({ commit, getters }) {
      let uid = getters.user.id
      firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .update({ cart: [] })
        .then(() => {
          commit('setCart', null)
        })
    },
    saveCart(context, payload) {
      let uid = context.getters.user.id
      const now = moment().unix()
      payload.updatedTime = now

      payload = JSON.parse(JSON.stringify(payload))
      firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .set({ updatedTime: now, checkout: payload }, { merge: true })
        .catch(e => console.error(e))
    },
    updateCartReOrder({ commit, getters }, payload) {
      const uid = payload.uid
      const reorderId = payload.orderId
      console.log(reorderId, 'for reorder!!!')
      firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .update({ reorder: reorderId })
    },
    submitOrder({ commit, getters, dispatch }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      let uid = getters.user.id
      //let profile = getters.profile
      let cart = getters.cart
      if (!cart || cart.length == 0) {
        commit('setError', {
          message: 'Cart is empty!'
        })
        return
      }
      let chef = getters.cart.cart[0].dish.chef

      if (uid === chef.id) {
        commit('setError', {
          message: 'You can not request order for your own product!'
        })
        return
      }
      payload.uid = uid
      // archive will set this flag to false
      payload.active = true
      payload.buyerUserId = uid
      payload.sellerUserId = chef.id

      /*payload.buyer = {
        id: uid,
        name: profile.name
      }
      if (profile.avatar) {
        payload.buyer.avatar = profile.avatar
      }*/
      /*payload.chef = {
        id: chef.id,
        name: chef.name,
        avatar: chef.avatar ? chef.avatar : null
      }*/

      if (!payload.deliveryDate) {
        // default it for today
        payload.deliveryDate = moment().format('YYYY-MM-DD')
      }
      payload.orderDate = new Date().getTime()
      payload.deliveryDateTime = moment(
        `${payload.deliveryDate} ${payload.deliveryTime}`,
        'YYYY-MM-DD HH:mm'
      ).valueOf()
      console.log('Submitting order', payload)
      //commit('setLoading', true)
      analytics.logEvent('ecommerce_purchase', {
        delivery_method: payload.deliveryMethod,
        producer_id: payload.sellerUserId,
        uid: payload.uid,
        value: cart.all_fees.total,
        discount: cart.discount,
        tip: cart.tip
      })
      payload = JSON.parse(JSON.stringify(payload))

      return firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection('buyer-orders')
        .add(payload)
        .then(data => {
          commit('setLoading', false)
          payload.id = data.id

          commit('setOrder', payload)
          commit('setCart', [])
          analytics.logEvent('ecommerce_purchase', {
            value: cart.all_fees.total,
            delivery_method: payload.deliveryMethod
          })

          if (payload.payment) {
            //&& payload.orderType === 'now'
            payload.payment.orderId = data.id
            payload.payment.chefId = payload.chefId
            payload.payment.description = payload.foodName
            return dispatch('submitCharge', payload.payment)
          }
          return payload
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
          return null
        })
    },
    setOrderStatus({ commit, getters }, payload) {
      let uid = getters.user.id

      commit('setLoading', true)
      const orderRef = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection('buyer-orders')
        .doc(payload.id)

      let req = {
        updatedTime: new Date().getTime()
      }
      if (payload.status) {
        req.status = payload.status
      }
      if (payload.archive) {
        req.active = false
      }
      if (payload.deliveryDate) {
        req.deliveryDate = payload.deliveryDate
      }
      if (payload.response) {
        req.buyerResponse = payload.response
      }
      console.log('Setting Order status', req)
      return orderRef
        .update(req)
        .then(function() {
          commit('setLoading', false)
          console.log('Document successfully updated!')
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    },
    loadOrders({ commit, getters }, all) {
      let uid = getters.user.id

      let ref = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection('buyer-orders')
      if (!all) {
        ref = ref.where('active', '==', true)
      }

      commit('setLoading', true)
      let observer = ref.onSnapshot(querySnapshot => {
        const list = []
        querySnapshot.forEach(data => {
          if (data.exists) {
            const obj = data.data()
            obj.id = data.id
            list.push(obj)
          }
        })
        commit('setOrders', list)
        commit('setLoading', false)
      })

      return observer
    },

    estimateDelivery({ commit, dispatch }, payload) {
      //commit('setLoading', true)
      if (payload.reset) {
        commit('setDeliveryEstimate', null)

        return
      }
      if (!payload.pickup_address || !payload.dropoff_address) {
        commit('setError', {
          title: 'address is missing',
          message: 'One of the addresses is not complete'
        })
        console.info('pickup_address', payload.pickup_address)
        console.log('dropoff_address', payload.dropoff_address)
        return
      }
      let api = process.env.VUE_APP_API
      axios
        .post(`${api}/callback/pm/delivery_quotes`, {
          pickup_address: payload.pickup_address,
          dropoff_address: payload.dropoff_address
        })
        .then(({ data }) => {
          commit('setLoading', false)
          console.log('quote', data)
          commit('setDeliveryEstimate', data)
        })
        .catch(err => {
          commit('setLoading', false)
          dispatch('setError', {
            message: 'Unable to get the estimate ' + err
          })
        })
    },
    loadOrder({ commit, getters, dispatch }, payload) {
      let uid = getters.user.id
      let collection = 'buyer-orders'
      if (payload.source) {
        collection = payload.source + '-orders'
      } else {
        if (getters.isChef) {
          collection = 'seller-orders'
        }
      }
      console.log(`loading order ${payload.orderId} from ${collection}`)
      commit('setLoading', true)
      let observer = firebase
        .firestore()
        .collection('orders')
        .doc(uid)
        .collection(collection)
        .doc(payload.orderId)
        .onSnapshot(data => {
          if (data.exists) {
            const order = data.data()
            order.id = data.id
            //dispatch('findById', order.foodId)
            // if (order.buyerUserId) {
            //   dispatch('findById', order.foodId)
            // }
            commit('setOrder', order)
            commit('setLoading', false)
            console.log('Order loaded', payload.orderId)
            return order
          } else {
            console.error('Unable to load order ', payload.orderId)
            dispatch('setError', {
              message: 'Unable to load the order: ' + payload.orderId
            })
          }
          commit('setLoading', false)
        })
      return observer
    }
  },
  getters: {
    order: state => {
      return state.order
    },
    cart: state => {
      return state.cart
    },
    activeOrders: state => {
      let orders = state.orders
        ? state.orders.filter(o => {
            return (
              o.status === 'accepted' ||
              o.status === 'confirmed' ||
              o.status === 'payment_accepted' ||
              o.status === 'submitted'
            )
          })
        : []
      if (orders.length == 0) {
        return []
      } else {
        return orders.sort((a, b) =>
          a.deliveryDateTime > b.deliveryDateTime ? -1 : 1
        )
      }
    },
    archiveOrders: state => {
      let orders = state.orders
        ? state.orders.filter(o => {
            return !(o.status === 'confirmed' || o.status === 'submitted')
          })
        : []
      if (orders.length == 0) {
        return []
      } else {
        return orders.sort((a, b) =>
          a.deliveryDateTime > b.deliveryDateTime ? -1 : 1
        )
      }
    },
    newOrders: state => {
      let orders = state.orders
        ? state.orders.filter(o => {
            return o.status === 'new'
          })
        : []
      if (orders.length == 0) {
        return []
      } else {
        return orders.sort((a, b) =>
          a.deliveryDateTime > b.deliveryDateTime ? -1 : 1
        )
      }
    },
    orders: state => {
      return state.orders
    },
    deliveryEstimate: state => {
      return state.deliveryEstimate
    }
  }
}
