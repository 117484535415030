<template>
  <v-container>
    <food-search type="Chef" :user="user"></food-search>
    <map-search type="Chef"></map-search>
    <featured-chefs></featured-chefs>
  </v-container>
</template>

<script>
import FeaturedChefs from './FeaturedChefs'
import MapSearch from './MapSearch'
import FoodSearch from './FoodSearch'

import { mapGetters } from 'vuex'
// https://github.com/tamiat/tamiat/blob/feature/refactor/src/components/home/Home.vue
//   <li class="small" :style="{'background-image': `url(${require('../../assets/img/coast.jpg')})`}"></li>

/*const cooking = { template: `<div>

  <h3>Cooking now</h3>
  <v-divider></v-divider>
  </div>` }
*/
export default {
  name: 'home',
  components: {
    MapSearch,
    FoodSearch,
    FeaturedChefs
  },
  computed: {
    ...mapGetters({
      isChef: 'isChef',
      user: 'user'
    })
  }
}
</script>
