// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import firebase, { analytics } from './firebase-init'
import VueRx from 'vue-rx'
import '@firebase/messaging'

import App from './App'
import vuetify from '@/plugins/vuetify'
import '@/assets/scss/_style.scss'
import { store } from './store'
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'

import '@/assets/css/style.css'
import '@mdi/font/css/materialdesignicons.css'
import moment from 'moment'
import Vue2Filters from 'vue2-filters'
import StarRating from 'vue-star-rating'
import VueMoment from 'vue-moment'
import VueLocalStorage from 'vue-localstorage'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ValidationProvider } from 'vee-validate'
import VueMeta from 'vue-meta'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration, CaptureConsole } from '@sentry/integrations'

//import { required } from 'vee-validate/dist/rules'

//import { globalMixin } from '@/mixins/globalMixin'
import './registerServiceWorker'
import VueGtm from 'vue-gtm'
import FlagIcon from 'vue-flag-icon'
import VueI18n from 'vue-i18n'
import VueQRCodeComponent from 'vue-qrcode-component'

import { i18n, globalMixin } from 'fod-core'
import PrismicVue from 'prismic-vue'
import linkResolver from './prismic/link-resolver'
import htmlSerializer from './prismic/html-serializer'

const AlertComp = () => import('./components/common/Alert.vue')

Vue.component('qr-code', VueQRCodeComponent)

Vue.config.productionTip = false
Vue.use(Vue2Filters)
Vue.use(VueMoment)
Vue.use(VueI18n)
Vue.use(VueRx)
// Register it globally

/* extend('required', {
  ...required,
  message: 'The {field} is required'
}) */
Vue.component('ValidationProvider', ValidationProvider)

Vue.component('vue-perfect-scrollbar', VuePerfectScrollbar)
Vue.component('deleteConfirmationDialog', () =>
  import('fod-core/src/components/common/DeleteConfirmationDialog')
)
Vue.component('star-rating', StarRating)
Vue.component('app-alert', AlertComp)
Vue.use(FlagIcon)

Vue.use(VueLocalStorage)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAWvOJtQ0I3EJA8wlLdjuBaC4KVqdjtDVY',
    libraries: ['places']
  }
})

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
//console.log('firebaseInit', firebaseInit)
Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(value).format('MM/DD/YYYY hh:mm a')
  }
})
Vue.filter('formatTimeOnly', function(value) {
  if (value) {
    return moment(value).format('hh:mm a')
  }
})
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(value).format('MM/DD/YYYY')
  }
})
Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(value, 'HH:mm').format('h:mm a')
  }
})

Vue.filter('showPics', function(value) {
  if (!Vue.config.devtools) {
    return value
  }
  return value
})

const env = process.env.VUE_APP_ENV

window.prismic = {
  endpoint: `https://takein${
    env === 'development' ? 'dev' : ''
  }.prismic.io/api/v2`
}
Vue.use(PrismicVue, {
  endpoint: window.prismic.endpoint,
  linkResolver,
  htmlSerializer
})

Vue.use(VueGtm, {
  id: process.env.VUE_APP_ID,
  loadScript: true,
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
})

Sentry.init({
  dsn: process.env.VUE_APP_DSN,
  enabled:  process.env.VUE_APP_ENV === 'production',
  release: process.env.VUE_APP_VERSION,
  environment: process.env.VUE_APP_ENV,
  maxBreadcrumbs: 50,
  integrations: [
    new VueIntegration({ Vue, attachProps: true, logErrors: true }),
    new CaptureConsole({
      levels: ['error']
    })
  ]
})

Vue.mixin(globalMixin)
/*Vue.use(VueAnalytics, {
  id: 'UA-102695348-3',
  router
})*/
/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  components: {
    ValidationProvider
  },
  localStorage: {
    userlocation: {
      type: Object,
      default: {}
    }
  },
  created() {
    const dispatch = this.$store.dispatch
    dispatch('setPageSize', 6)
    //dispatch('setChefPortalAddress')
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        Sentry.setUser({ email: user.email, id: user.uid })
        analytics.setUserId(user.uid)
        this.$store.dispatch('autoSignIn', user)
        this.$store.dispatch('loadProfile')
        this.$store.dispatch('loadCart')
        this.$store.dispatch('newMessageCount')
        // this.$store.dispatch('getUserSettings', 'notification_settings')
        //this.$store.dispatch('loadOrders')
      }
      this.$store.dispatch('loadFoodTypes')
      // location is set in MapSearch.vue setUserPosition
      //this.$store.dispatch('setUserLocation')
    })
    const messaging = firebase.messaging()
    messaging.onMessage(function(payload) {
      console.log('Message received. ', payload)
      dispatch('setMessage', payload.notification)
    })
  }
}).$mount('#app')
