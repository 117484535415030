import firebase from '@/firebase-init'

export default {
  state: {
    userSettings: null
  },
  mutations: {
    setUserSettings(state, payload) {
      state.userSettings = payload
    }
  },
  actions: {
    saveDeliverySettings({ commit, getters }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log(`Saving settings of uid ${uid}`, payload)
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .update({ settings: payload })
        .then(() => {
          commit('setLoading', false)
          commit('setUserSettings', payload)
          return payload
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
          return null
        })
    },
    getUserSettings({ commit, getters }, type) {
      if (!getters.user) {
        console.warn('error, not user detected')
        return
      }

      commit('setLoading', true)
      let uid = getters.user.id
      console.log(`Getting settings of uid ${uid}`)
      return firebase
        .firestore()
        .doc(`profile/${uid}/settings/${type}`)
        .get()
        .then(data => {
          if (data.exists) {
            let settings = data.data()
            commit('setUserSettings', settings)
            commit('setLoading', false)
          }
          commit('setLoading', false)
          return data.data()
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
          return null
        })
    },
    saveSettings({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Saving setting for ', uid, payload)
      return firebase
        .firestore()
        .doc(`profile/${uid}`)
        .set({ settings: payload }, { merge: true })
        .then(() => {
          commit('setLoading', false)
          commit('setUserSettings', payload)
          return payload
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
          return null
        })
    }
  },
  getters: {
    userSettings: state => {
      return state.userSettings
    }
  }
}
