import Vue from 'vue'
import Geocoder from '../util/geocoder'
import moment from 'moment'
import axios from 'axios'

Vue.use(Geocoder, {
  googleMapsApiKey: 'AIzaSyAWvOJtQ0I3EJA8wlLdjuBaC4KVqdjtDVY'
})
const domain = process.env.VUE_APP_DOMAIN

const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  neighborhood: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name'
}
const formatResult = place => {
  let returnData = {}
  for (let i = 0; i < place.address_components.length; i++) {
    let addressType = place.address_components[i].types[0]
    if (ADDRESS_COMPONENTS[addressType]) {
      let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]]
      returnData[addressType] = val
    }
  }
  returnData['latitude'] = place.geometry.location.lat
  returnData['longitude'] = place.geometry.location.lng
  return returnData
}
const timeList = []
for (let index = 0; index < 24 * 60; index = index + 30) {
  timeList.push(
    moment('00:00', 'mm:hh')
      .add(index, 'm')
      .format('hh:mm a')
  )
}

const globalMixin = {
  computed: {
    you() {
      return this.user && this.user.id === this.profile.id
    },
    showPics() {
      if (Vue.config.devtools) {
        return true
      }
      return true
    },
    profileAvatar() {
      return this.profilePic(this.profile)
    },

    chefAvatar() {
      return this.profilePic(this.chef)
    },
    chefPortalLink() {
      return `https://biz.${domain}`
    },

    profileDefaultAddress() {
      if (!this.profile) {
        return null
      }
      return this.profile.addresses && this.profile.addresses.length > 0
        ? this.profile.addresses[0]
        : this.profile.address
    },
    consumerLink() {
      const domain = process.env.VUE_APP_DOMAIN
      return `https://${domain}`
    }

  },
  methods: {
    profilePic(profile) {
      return !profile
        ? null
        : profile.avatar
        ? profile.avatar
        : profile.photoUrl
    },
    getAddressData: async function(placeResultData) {
      if (!placeResultData || !placeResultData.results) {
        return
      }
      let addressData = formatResult(placeResultData.results[0])

      console.log('addressData', addressData)

      let address = {}

      address.address1 =
        (addressData.street_number || '') + ' ' + (addressData.route || '')
      address.city = addressData.locality || ''
      address.state = addressData.administrative_area_level_1 || ''
      address.zipcode = addressData.postal_code || ''
      address.latitude = addressData.latitude || ''
      address.neighborhood = addressData.neighborhood
      address.longitude = addressData.longitude || ''
      console.log('Address is', address)
      return address
    },
    GeoCoder: async function(options) {
      return await this.getPosition(options).then(async position => {
        Vue.$geocoder.setDefaultMode('latlng') // this is default
        if (position.coords) {
          var latLngObj = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          console.log('1 getPosition')
          let result = await Vue.$geocoder
            .send(latLngObj, response => {
              console.log('2', response)
              return this.getAddressData(response)
            })
            .then(e => {
              console.log('3', e)
            })
          return result
        }
      })
    },
    getPosition(options) {
      if (navigator.geolocation) {
        return new Promise((resolve, reject) =>
          navigator.geolocation.getCurrentPosition(resolve, reject, options)
        )
      } else {
        console.error('Location service is off or unable to get the location')
        return new Promise(resolve => resolve({}))
      }
    },

    profileAddress(profile, type) {
      if (!profile) {
        return null
      }
      if (profile.addresses && profile.addresses.length>0) {
        let address = profile.addresses[0]

        if (type){
          let list =  profile.addresses.filter( a => a.type === type)
          if (list && list.length>0){
            address = list[0]
          }
        }
        return address
      }
      return null
    },
    profileFormattedAddress(profile, type) {

      let address  = this.profileAddress(profile, type)
      if (!address) {
        return null
      }

      if (address.formatted_address) {
        return address.formatted_address
      }

      return `${address.address1 ? address.address1 : ''} ${
        address.address2 ? address.address2 : ''
      } ${address.city ? address.city + ',' : ''} ${
        address.state ? address.state : ''
      } ${address.zipcode ? address.zipcode : ''}`
    },
    eachHalfHour(t1, t2) {
      return timeList.filter(t =>
        moment(t, 'HH:mm a')
          .add(-30, 'm')
          .isBetween(moment(t1, 'HH:mm'), moment(t2, 'HH:mm'))
      )
    },
    timeStep(t1  = '06:00',  t2 ='23:30', step=15) {
      let list = []
      for (let index = 0; index < 24 * 60; index = index + step) {
        list.push(
          moment('00:00', 'mm:hh')
            .add(index, 'm')
            .format('hh:mm a')
        )
      }

      return list.filter(t =>
        moment(t, 'HH:mm a')
          .add(-step, 'm')
          .isBetween(moment(t1, 'HH:mm'), moment(t2, 'HH:mm'))
      )
    },
    
  }
}

export default globalMixin
