<template>
  <div v-if="dish.global_promo">
    <h6 v-if="!iconOnly" class="pink--text mb-0">{{promotionalText()}}</h6>
    <v-avatar v-if="showIcon && dish.global_promo && dish.global_promo.promo_type" tile size="35px">
      <v-img
        :src="dish.global_promo.promo_type.value === 'delivery' ? '/svg/delivery_coupon.svg' : '/svg/price_coupon.svg'"
      />
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: {
    dish: {
      Type: Object
    },
    showIcon: {
      Type: Boolean
    },
    iconOnly: {
      Type: Boolean
    }
  },
  methods: {
    promotionalText() {
      const gPromo = this.dish.global_promo;
      const deliveryDiscount = gPromo && gPromo.promo_type.value === "delivery";
      const deliveryDiscountString = deliveryDiscount ? "delivery" : "";
      if (
        deliveryDiscount &&
        gPromo.amount_type === "%" &&
        gPromo.amount === 100
      ) {
        return " (Free Delivery)";
      }
      return gPromo && gPromo.amount_type === '$'
        ? ` ($${gPromo.amount.toFixed(2)} OFF ${deliveryDiscountString})`
        : gPromo.amount_type === '%'
        ? ` (${gPromo.amount}% OFF ${deliveryDiscountString})`
        : "";
    }
  }
};
</script>
