import firebase from '@/firebase-init'

export default {
  state: {
    userMessage: null,
    messageLoading: false,
    newMessageCount: 0,
    newInvitationCount:0,
    threads: [],
    messages: [],
    member_info: {},
    unsubscribe: null,
    orderMessages: {},
    users: {},
  },
  mutations: {
    clearUsers(state) {
      state.users = {}
    },
    setMessages(state, payload) {
      state.messages = payload
    },
    setUserMessage(state, payload) {
      state.userMessage = payload
    },
    setMessageLoading(state, payload) {
      state.messageLoading = payload
    },
    setThreads(state, payload) {
      state.threads = payload
    },
    setNewMessageCount(state, payload) {
      state.newMessageCount = payload
    },
    setOrderMessages(state, payload) {
      state.orderMessages = payload
    },
    setNewInvitationCount(state, payload) {
      state.newInvitationCount = payload
    },
    setMemberInfo(state, payload) {
      state.member_info = payload
    },
    setUnsubscribe(state, payload) {
      state.unsubscribe = payload
    },
    unsubscribe(state) {
      if (state.unsubscribe) state.unsubscribe()
    }
  },
  actions: {
    clearUsers({ commit }) {
      commit('clearUsers')
    },
    // @param: payload: {body(str), thread_id(str)}
    // sends message: adds message object to message_items and lastMessage
    sendMessage({ commit, getters }, payload) {      
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }

      if (!payload.thread_id) {
        console.error('error, thread is missing')
        return
      }

      let uid = getters.user.id
      // create new message object
      let message = {
        uid: uid,
        timestamp: new Date().getTime(),
        body: payload.body,
        thread_id: payload.thread_id
      }

      // updates lastMessage on thread reference
      firebase
        .firestore()
        .collection('message_threads')
        .doc(payload.thread_id)
        .update({lastMessage: message})
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })

      // adds status to message
      message.status = 'read'
      // adds message to message_item
      firebase
        .firestore()
        .collection('messages')
        .doc(uid)
        .collection('message_items')
        .add(message)
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    // loads threads of a user
    loadThreads: async ({ commit, getters }) => {
      let uid = getters.user.id
      await firebase
        .firestore()
        .collection('message_threads')
        .where(`member_list.${uid}`, "==", true)
        .where("status", "==", "open")
        //.orderBy('timestamp')
        .get()
        .then(querySnapshot => {
          const list = []
          querySnapshot.forEach(data => {
            if (data.exists && data.data().lastMessage) {
              const thread = data.data()
              thread.id = data.id
              list.push(thread)
            }
          })
          // sort by lastest message
          list.sort((a,b) => (a.lastMessage.timestamp < b.lastMessage.timestamp) ? 1 : ((b.lastMessage.timestamp < a.lastMessage.timestamp) ? -1 : 0));
          commit('setThreads', list)
          commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          commit('setThreads', [])
          commit('setLoading', false)
          commit('setError', error)
        })
    },
    // load messages for that thread
    // @param: payload.thread_id = current thread id
    loadMessages: async ({ commit, getters }, payload) => {
      let uid = getters.user.id
      let thread_id = payload.thread_id
      let unsubscribe = firebase
        .firestore()
        .collection('messages')
        .doc(uid)
        .collection('message_items')
        .where('thread_id', '==', thread_id)
        .orderBy('timestamp')
        //.limit(20)
        .onSnapshot(function(snapshot) {
          let list = []
          snapshot.forEach(msg => {
            list.push(msg.data())
          })
          commit('setMessages', list)
        })
      commit('setUnsubscribe', unsubscribe)
    },
    unsubscribe: async ({ commit }) => {
      commit('unsubscribe')
    },
    // checks if thread exist, if it doesn't make a new one
    // @param: recipient
    getThread: async ({ getters}, payload) => {
      
      if (!payload.recipient) {
        return
      }
      let uid = getters.user.id
      let recipient = payload.recipient

      // query for thread
      // create new doc if missing
      return firebase
        .firestore()
        .collection('message_threads')
        .where(`member_list.${uid}`, "==", true)
        .where(`member_list.${recipient}`, "==", true)
        .where("status", "==", "open")
        .where("type", "==", "direct_message")
        .get()
        .then(querySnapshot => {
          
          if (querySnapshot.empty) {
            // create new thread along with information
            let thread = {
              member_list: {
                [uid]: true,
                [recipient]: true
              },
              status: 'open',
              type: 'direct_message'
            }

            const ref = firebase
              .firestore()
              .collection('message_threads')
              .doc()
            return ref
              .set(thread)
              .then(() => {
                return ref.id
              })
          } else {
            // returns existing thread id
            return querySnapshot.docs[0].id
         }
    })
  },
  newMessageCount: async ({ commit, getters }) => {
    let uid = getters.user.id
    let email = getters.user.email
    console.log('getting message count:', uid)
    await firebase
      .firestore()
      .collection('messages')
      .doc(uid)
      .collection('message_items')
      .where('status', '==', 'new')
      .onSnapshot(querySnapshot => {
        console.log('Message Count: ', querySnapshot.size)
        commit('setNewMessageCount', querySnapshot.size)
      })
      if (email){
        await firebase
        .firestore()
        .collection('invitation')
        .where('status', '==', 'sent')
        .where('to', '==', email)
        .onSnapshot(querySnapshot => {
          console.log('Invitation Count ', querySnapshot.size)
          commit('setNewInvitationCount', querySnapshot.size)
        })
      }
  },
  // @param: payload: thread_id
  // query all the messages not from the user
  markAsRead: async ({commit, getters}, payload) => {
    let uid = getters.user.id

    return firebase
      .firestore()
      .collection('messages')
      .doc(uid)
      .collection('message_items')
      .where('thread_id', '==', payload.thread_id)
      .where('status', '==', 'new')
      .onSnapshot(snapshot => {
        if (!snapshot.empty) {
            // create batch to update messages at once
            let batch = firebase.firestore().batch();
            // mark every message as read
            snapshot.forEach(data => {
              batch.set(data.ref, {status: 'read'},  {merge:true})
            })

            batch
              .commit()
              .then(
                console.log('marked as read')
              )
        }
      })

  },

  // query all the new messages not from the user
  loadOrderMessages: async ({commit, getters}) => {
    let uid = getters.user.id

    return firebase
      .firestore()
      .collection('messages')
      .doc(uid)
      .collection('message_items')
      .where('status', '==', 'new')
      .onSnapshot(querySnapshot => {
        
        if (!querySnapshot.empty) {
          let messages = {}
          querySnapshot.docs.forEach(message => {
            messages[message.id] = message.data()
          })
          commit('setOrderMessages', messages)
        }
      })
      
  },
  // gets memeber_info from thread
  // @parma: payload.thread_id = current thread id
  getMemberInfo: async ({commit}, payload) => {
    if (!payload.thread_id) {
      console.error('missing thread')
      return
    }
    firebase
      .firestore()
      .collection('message_threads')
      .doc(payload.thread_id)
      .get()
      .then(snapshot => {
        commit('setMemberInfo', snapshot.data().member_info)
      }).catch(e => {
        console.error('Unable to get getMemberInfo for:'+payload.thread_id, e)
      })
  }
},
  getters: {
    messages: state => {
      return state.messages
    },
    threads: state => {
      return state.threads
    },
    userMessage: state => {
      return state.userMessage
    },
    inbox: state => {
      return state.inbox
    },
    outbox: state => {
      return state.outbox
    },
    newMessages: state => {
      return state.messages
    },
    messages: state => {
      return state.messages
    },
    messageLoading: state => {
      return state.messageLoading
    },
    newMessageCount: state => {
      return state.newMessageCount
    },
    newInvitationCount: state => {
      return state.newInvitationCount
    },
    member_info: state => {
      return state.member_info
    },
    orderMessages: state => {
      return state.orderMessages
    },
    userFromCache(state) {
      return id => state.users[id]
    },
    users(state) {
      return state.users
    },
  }
}
