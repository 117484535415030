import { render, staticRenderFns } from "./FoodView.vue?vue&type=template&id=7951877d&scoped=true&"
import script from "./FoodView.vue?vue&type=script&lang=js&"
export * from "./FoodView.vue?vue&type=script&lang=js&"
import style0 from "./FoodView.vue?vue&type=style&index=0&id=7951877d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7951877d",
  null
  
)

export default component.exports