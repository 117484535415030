<template>
  <v-container>
    <v-progress-circular
      v-if="localLoading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <template v-if="chefs && chefs.length > 0">
      <h4>Featured Producers</h4>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="chef in chefs"
          :key="chef.id"
          class="pr-1"
        >
          <chef-view :chefId="chef.id" />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12" md="5" lg="4">
          <v-card height="360px">
            <v-img
              class="white--text"
              height="200px"
              src="https://storage.googleapis.com/img.fod.live/static/app/food/pexels-photo-357586.jpeg"
            >
              <v-container>
                <v-row>
                  <v-col class="align" cols="12">
                    <span class="headline white--text"
                      >{{ $t('Chef.join_title') }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
            <v-card-title>
              <div class="fs-14" v-html="$t('Chef.join_sub_title')"></div>
            </v-card-title>
            <v-card-actions>
              <v-footer absolute color="transparent">
                <v-btn text color="orange">Share</v-btn>
                <v-btn text color="orange" to="/signup/chef">Join</v-btn>
              </v-footer>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="5" lg="4" class="offset-sm1">
          <v-card height="360px">
            <v-img
              class="white--text"
              height="200px"
              src="https://storage.googleapis.com/img.fod.live/static/app/food/pexels-photo-266642.jpeg"
            >
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <span class="fs-12 pink--text "
                      >{{ $t('Chef.refer_title') }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
            <v-card-title>
              <div class="fs-14" v-html="$t('Chef.refer_sub_title')"></div>
            </v-card-title>
            <v-card-actions>
              <v-footer absolute color="transparent">
                <v-btn text color="pink" to="/invite">Invite</v-btn>
              </v-footer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import ChefView from './profile/public/ChefView'
import { mapGetters } from 'vuex'

export default {
  components: { ChefView },
  computed: {
    ...mapGetters({
      chefs: 'nearbyChefs',
      loading: 'loading'
    })
  },
  data() {
    return {
      localLoading: false
    }
  },
  mounted() {
    //this.$store.dispatch('loadNearbyFoods')
    this.localLoading = true
    this.$store.dispatch('SearchChefs').then(e => {
      this.localLoading = false
    })
  }
}
</script>
