<template>
  <v-card v-if="!loading" height="360px">
    <v-container v-if="chef" @click="viewChef">
      <v-row align="center" justify="space-around">
        <v-col cols="6">
          <div class="title">{{ chef.name }}</div>
        </v-col>

        <v-col cols="6">
          <v-avatar size="90px">
            <img v-if="chefAvatar" :src="chefAvatar" height="90px" />
          </v-avatar>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div>{{ chef.title | truncate(100) }}</div>
        </v-col>
        <v-col cols="12">
          <vue-perfect-scrollbar v-if="chef.description" style="height:130px;" :settings="scroll_settings">
            <div
                class="fs-12 word-breaking"
                v-html="markedDown"
                style="height:100%"
              ></div>
          </vue-perfect-scrollbar>
          <!-- {{ chef.description | truncate(100) }} -->
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-footer absolute color="transparent">
        <v-btn text color="primary" :to="'/chef/' + chef.id">Profile</v-btn>
         <v-rating
          v-if="chef.meta && chef.meta.ratings"
          v-model="chef.meta.ratings.overall_rating"
          :readonly="true"
          :half-increments="true"
          background-color="orange lighten-3"
          color="orange"
          small
        ></v-rating>
        
        <v-spacer></v-spacer>
        <img
          v-if="isOpen"
          width="35"
          class="mx-3"
          src="/svg/noun_open_2721472.svg"
        />
        <v-chip
          label
          small
          color="purple lighten-1 white--text"
          class="mx-1"
          v-if="isCooking"
        >
          {{ cookingStatus }}</v-chip
        >

        <template v-if="liveStreaming">
          <span @click.prevent="watchLive" class="ml-1">
            <v-icon color="red" title="Live Video Broadcast"
              >fa fa-circle Blink</v-icon
            >
          </span>
        </template>
        <template v-else-if="liveStatus">
          <v-icon
            color="pink darken-1 white--text"
            title="Live Video Broadcast"
            @click.prevent="watchLive"
            >camera</v-icon
          >
        </template>
      </v-footer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { profileMixin } from 'fod-core'
import marked from 'marked'

export default {
  props: {
    chefId: {
      type: String
    },
    selectedChef: {
      type: Object
    }
  },
  mixins: [profileMixin],
  data() {
    return {
      scroll_settings: {
        maxScrollbarLength: 360
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',      
      loading: 'loading'
    }),
    markedDown() {
      return this.chef.description ? marked(this.chef.description, { sanitize: true }) : ''
    },
    chef() {
      if (this.selectedChef) {
        return this.selectedChef
      }
      return this.$store.getters.nearbyChef(this.chefId)
    },
    id() {
      if (this.selectedChef) {
        return this.selectedChef.id
      }
      return this.chefId
    },

    liveStatus() {
      return this.chef.cookingStatus === 'cooking' ? `Cooking` : null
    },
    liveStreaming() {
      return this.chef.stream_status === 'live'
    },
    cookingStatus() {
      return this.chef.cookingStatus === 'cooking' ? `Cooking` : null
    },
    isCooking() {
      return this.chef.cookingStatus === 'cooking'
    },
    isOpen() {
      if (this.chef.businessHours) {
        return this.isOpenHour(this.chef)
      }
      return false
    }
  },
  methods: {
    viewChef() {
      console.log('viewing')
      
      this.$router.push('/chef/' + this.id)
    },
    watchLive() {
      console.log('Leaving')

      this.$router.push(
        '/watch/' + this.id + '?stream_id=' + this.chef.stream_id || ''
      )
    }
  }
}
</script>
<style scoped>

.word-breaking {
  word-break: normal !important;
}
</style>