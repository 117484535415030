<template>
  <div>
    <v-list two-line v-if="profile">
      <v-list-item :href="producerLink">
        <template v-if="profileAvatar">
          <v-list-item-avatar>
            <img :src="profileAvatar" />
          </v-list-item-avatar>
        </template>
        <template v-else>
          <v-list-item-action>
            <v-btn color="blue accent-1" small fab class="elevation-2">
              <v-icon color="white">face</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
        <v-list-item-content>
          <v-list-item-title
            >{{ profile.name }} {{ you }}
            <v-icon class="right blue--text accent-1">visibility</v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="profile.businessPhone">
        <v-list-item-action>
          <v-btn color="blue accent-1" small fab class="elevation-2">
            <v-icon color="white">phone</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ profile.businessPhone }}</v-list-item-title>
          <v-list-item-subtitle>Business Phone</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>phone_forwarded</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider inset v-if="profile.website"></v-divider>
      <v-list-item v-if="profile.website">
        <v-list-item-action>
          <v-btn color="blue accent-1" small fab class="elevation-2">
            <v-icon color="white">public</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            ><a :href="profile.website" target="_blank">{{
              profile.website
            }}</a></v-list-item-title
          >
          <v-list-item-subtitle>Website</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action> <v-icon>cloud</v-icon> </v-list-item-action>
      </v-list-item>
      <v-divider inset v-if="profile.businessEmail"></v-divider>
      <v-list-item v-if="profile.businessEmail">
        <v-list-item-action>
          <v-btn color="blue accent-1" small fab class="elevation-2">
            <v-icon color="white">mail</v-icon>
          </v-btn>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            <a :href="'mailto:' + profile.businessEmail">
              {{ profile.businessEmail }}</a
            >
          </v-list-item-title>
          <v-list-item-subtitle>Business Email</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action> <v-icon>chat</v-icon> </v-list-item-action>
      </v-list-item>

      <v-divider inset></v-divider>
      <v-list-item>
        <v-list-item-action>
          <v-btn color="blue accent-1" small fab class="elevation-2">
            <v-icon color="white">location_on</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ address }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="!loading && center.lan !== 0">
        <v-list-item-content> </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-card
      v-if="center.lat !== 0"
      color="grey lighten-1"
      class="mb-5 map demo"
      height="300px"
    >
      <google-map :center="location" class="map" :zoom="13" ref="map">
        <google-marker
          :icon="icon"
          :position="location"
          :clickable="true"
          :draggable="true"
          @click="viewChef"
        ></google-marker>

        <google-circle
          :center="location"
          :radius="1000"
          :visible="true"
          :options="{
            fillColor: 'red',
            fillOpacity: 0.1,
            strokeWeight: 1,
            strokeColor: '#FF0000',
            strokeOpacity: 0.5
          }"
        >
        </google-circle>
      </google-map>
      <!--
        <googlemaps-map ref="map"
                        class="map"
                        :center.sync="center"
                        :zoom.sync="zoom">
          <googlemaps-marker :label="{
                                color:'white',
                                fontFamily: 'Material Icons',
                                fontSize: '20px',
                                text: 'star_rate',
                              }"
                             :icon='icon'
                             :position="center" />
        </googlemaps-map>
      -->
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as VueGoogleMaps from 'vue2-google-maps'
const domain = process.env.VUE_APP_DOMAIN

export default {
  props: ['profile'],
  components: {
    'google-map': VueGoogleMaps.Map,
    'google-marker': VueGoogleMaps.Marker,
    'google-circle': VueGoogleMaps.Circle
  },

  data() {
    return {
      center: {
        lat: 0,
        lng: 0
      },
      zoom: 12
    }
  },
  mounted() {
    let location = this.profileDefaultAddress
      ? this.profileDefaultAddress
      : null

    if (location) {
      this.center.lat = location.latitude
      this.center.lng = location.longitude
      console.log('Profile address:', this.center)
    } else {
      console.warn('Address is empty')
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      user: 'user'
    }),
    address() {
      if (!this.profileDefaultAddress) {
        return ''
      }
      const { city, state, zipcode } = this.profileDefaultAddress
      return `${city} ${state}, ${zipcode}`
    },
    you() {
      return this.user && this.user.id == this.profile.id ? ' (You)' : ''
    },
    icon() {
      return {
        url: 'svg/chef.svg',
        scaledSize: { width: 30, height: 30 }
      }
    },
    location() {
      if (this.profileDefaultAddress && this.profileDefaultAddress.location) {
        return {
          lat: this.profileDefaultAddress.location.latitude,
          lng: this.profileDefaultAddress.location.longitude
        }
      }
      return 'center'
    },
    producerLink() {
      /* if (this.dish.shareLink) {
        return this.dish.shareLink
      } */
      return `https://view.${domain}/producer/` + this.profile.handle
    }
  },
  methods: {
    viewChef() {
      this.$router.push(`/chef/${this.profile.id}`)
    }
  }
}
</script>

<style scoped>
.demo {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.map {
  flex: 100% 1 1;
}
.location_icon {
  position: relative;
}
</style>
