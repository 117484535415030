h<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="10">
        <v-card v-if="!loading && dish" class="elevation-0 transparent">
          <template v-if="imageUrl">
            <template v-if="imageUrl && imageUrl.indexOf('mp4') > 0">
              <video
                height="300px"
                playsinline
                autoplay
                muted
                controls
                ref="video"
              >
                <source :src="imageUrl" type="video/mp4" />
              </video>
            </template>
            <template v-else>
              <v-img :src="imageUrl" contain height="300px"></v-img>
            </template>
          </template>
          <v-card-title primary-title class="layout justify-center">
            <div class="headline text-center">{{ dish ? dish.name : '' }}</div>
          </v-card-title>
          <v-card-text>{{ dish ? dish.description : '' }}</v-card-text>

          <v-card-actions>
            <vue-goodshare-facebook
              :page_url="renderLink"
              has_counter
              has_icon
            ></vue-goodshare-facebook>
            <vue-goodshare-pinterest
              :page_url="renderLink"
              :page_description="`${dish.name} : ${dish.description}`"
              :page_image="imageUrl"
              has_counter
              has_icon
            ></vue-goodshare-pinterest>

            <vue-goodshare-twitter
              :page_url="renderLink"
              :page_title="`${dish.name}: ${dish.description}`"
              has_counter
              has_icon
            ></vue-goodshare-twitter>
            <vue-goodshare-reddit
              :page_url="renderLink"
              :page_title="`${dish.name}: ${dish.description}`"
              has_counter
              has_icon
            ></vue-goodshare-reddit>
            <v-icon @click="selectText('selectable')">link</v-icon>
            <div id="selectable" @click="selectText('selectable')">
              {{ renderLink }}
            </div>

            <v-spacer></v-spacer>
            <code
              v-if="foodStatus"
              :class="{
                'red--text': isNew,
                'green--text': isReady,
                'blue--text': isCooking
              }"
              >{{ foodStatus }}</code
            >
            <template v-if="profile">
              <v-icon
                v-if="isFavorite"
                color="red"
                class="mx-3"
                @click.prevent="updateFavorite"
                >favorite</v-icon
              >
              <v-icon
                v-else
                color="red"
                class="mx-3"
                @click.prevent="updateFavorite"
                >favorite_border</v-icon
              >
            </template>
            <dish-rate :rating="dish.ratings"></dish-rate>
          </v-card-actions>

          <div class="hidden-md-and-up">
            <small>
              <vue-goodshare-whats-app
                class="mx-3"
                :title-social="`${dish.name}: ${dish.description}`"
                :page_url="renderLink"
                has_icon
              ></vue-goodshare-whats-app>

              <vue-goodshare-telegram
                :title-social="`${dish.name}: ${dish.description}`"
                :page_url="renderLink"
                has_icon
              ></vue-goodshare-telegram>
            </small>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- card -->
    <v-row>
      <v-col cols="12" sm="10">
        <v-btn rounded :href="renderLink"
          >Buy NOW - {{ dish.price | currency }}</v-btn
        >
      </v-col>
      <v-col cols="12" sm="10" v-if="false">
        <v-card>
          <v-card-text v-if="!user || (user && dish.uid != user.id)">
            <v-btn
              v-if="false"
              :to="`/order/new/dish/${id}`"
              :disabled="!showOrderButton"
              color="red darken-2 white--text"
              >Order</v-btn
            >
            <v-row align="center" justify="center">
              <v-col class="xs" sm="2">
                <v-text-field
                  v-model="orderAmount"
                  type="number"
                  label="Qty"
                  :rules="[rules.orderMin, rules.orderMax]"
                  append-outer-icon="add"
                  @click:append-outer="increment"
                  prepend-icon="remove"
                  @click:prepend="decrement"
                ></v-text-field>

                <v-text-field
                  v-model="orderAmount"
                  label="Qty"
                  v-if="false"
                ></v-text-field>
                <input
                  v-if="false"
                  type="number"
                  min="0"
                  style="text-align:center;border: 1px solid; border-radius: 16px;"
                  v-model="orderAmount"
                />
              </v-col>

              <v-spacer></v-spacer>
              <v-col cols="12" sm="4">
                <span class="fs-14">{{ $t('Chef.available_qty') }}</span>
                : {{ getAmount(dish.amountLeft) }}
              </v-col>
              <v-col cols="12">
                <v-textarea
                  rows="3"
                  placeholder="Add Special Order Instructions"
                  v-model="specialInstruction"
                  auto-grow
                  counter="100"
                ></v-textarea>
              </v-col>

              <v-spacer></v-spacer>
              <v-col sm="3" class="mx-3">
                <v-btn
                  color="primary"
                  rounded
                  @click="addToCart"
                  :disabled="!checkAddToCart"
                  >{{ $t('Common.addToCart') }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col cols="12">
                <modifiers
                  :modifiers="dish.modifiers"
                  @updateModifiers="updateModifiers"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- List -->
    <v-row>
      <v-col cols="12" sm="10">
        <v-list three-line class="dropdown-list">
          <v-subheader>
            Detail Info
            <v-spacer class="text-right"></v-spacer>

            <v-btn text icon :to="'/dish/label/' + id">
              <v-icon class="green--text">fas fa-tags</v-icon>
            </v-btn>
          </v-subheader>
          <v-list-item v-if="false">
            <v-list-item-action>
              <v-icon class="accent--text">fa fa-dollar-sign fa-lg</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Price</v-list-item-title>

              <v-list-item-subtitle v-if="dish.lastCall">
                <s class="title gray--text">{{ dish.price | currency }}</s>
                <span class="title info--text">{{
                  lastCallInfo.price | currency
                }}</span>
                ( {{ lastCallInfo.off + '%' }} off) ({{
                  lastCallInfo.amount_left
                }}
                left)
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                <v-row align="center">
                  <v-col cols="6" sm="4" md="3">
                    <span class="title">{{ dish.price | currency }}</span>
                    <view-product-discount :dish="dish" />
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list-item v-if="false">
          <v-list-item-action class="pt-3">
            <v-icon class="accent--text">fa fa-leaf fa-lg</v-icon>
          </v-list-item-action>
          <v-list-item-content class="mytile">
            <v-list-item-title>Ingredients</v-list-item-title>
            <v-list-item-subtitle class="text-truncate d-inline-block">
              <v-chip
                v-for="ing in dish.ingredients"
                :key="ing"
                small
                outlined
                color="indigo"
                class="mr-1"
                >{{ ing }}</v-chip
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="false">
          <v-list-item-action class="pt-3">
            <v-icon class="accent--text">fa fa-exclamation fa-lg</v-icon>
          </v-list-item-action>
          <v-list-item-content class="mytile">
            <v-list-item-title>Diets</v-list-item-title>
            <v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-chip
                  v-for="diet in dish.diets"
                  :key="diet"
                  outlined
                  label
                  small
                  color="indigo"
                  >{{ diet }}</v-chip
                >
              </v-list-item-subtitle>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item v-if="dish.origins">
          <v-list-item-action class="pt-3">
            <v-icon class="accent--text">fa fa-object-group fa-lg</v-icon>
          </v-list-item-action>
          <v-list-item-content class="mytile">
            <v-list-item-title>Cuisine</v-list-item-title>
            <v-list-item-subtitle>
              <v-list-item-subtitle>
                <food-origin :init="dish.origins" readonly :view="true" />
              </v-list-item-subtitle>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row v-if="dish.photos">
      <v-col cols="12" sm="10">
        <v-row justify="center">
          <v-carousel>
            <v-carousel-item
              v-for="(item, i) in photosOnly"
              :src="item.url || item.imageUrl"
              :key="i"
            ></v-carousel-item>
          </v-carousel>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10">
        <v-card v-for="(video, key) in videosOnly" :key="key">
          <v-card-title primary-title>
            <video width="300" playsinline muted controls ref="video">
              <source :src="video.url || video.imageUrl" type="video/mp4" />
            </video>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="12" sm="10">
        <h4>Provided by</h4>
        <p v-if="!showChefInfo">Hidden</p>
        <contact-info v-if="showChefInfo" :profile="chef"></contact-info>
      </v-col>
    </v-row>
    <v-btn class="primary" @click="$router.go(-1)" icon>
      <v-icon class="white--text">chevron_left</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import ContactInfo from '../../common/ContactInfo'
import FoodOrigin from '@/components/common/FoodOrigin'

import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook.vue'
import VueGoodsharePinterest from 'vue-goodshare/src/providers/Pinterest.vue'
import VueGoodshareReddit from 'vue-goodshare/src/providers/Reddit.vue'
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter.vue'
import VueGoodshareTelegram from 'vue-goodshare/src/providers/Telegram.vue'
import VueGoodshareWhatsApp from 'vue-goodshare/src/providers/WhatsApp.vue'
import ViewProductDiscount from 'fod-core/src/components/dish/ViewProductDiscount.vue'
import VueGoodshare from 'vue-goodshare'
import { mapGetters } from 'vuex'
import DishRate from './DishRate'
import Modifiers from './FoodModifiers'

import { FoodMixin } from 'fod-core/src/mixins/foodMixin'
import { globalMixin } from 'fod-core'

const domain = process.env.VUE_APP_DOMAIN

export default {
  props: ['dish', 'food'],
  mixins: [FoodMixin, globalMixin],
  data() {
    return {
      isFavorite: false,
      modifiers: null,
      hasValidModifiers: false,
      orderAmount: 1,
      specialInstruction: null,
      unsubscribe: null,
      rules: {
        required: value => !!value || 'Required.',
        orderMin: value => value >= 1 || 'Min should be above 0',
        orderMax: value => value <= 100 || 'Max should not be above 100'
      }
    }
  },

  metaInfo() {
    if (!this.loading && this.dish) {
      return {
        title: this.dish.name,
        meta: [
          { name: 'description', content: this.dish.description },
          { property: 'keywords', content: this.dish.ingredients.toString() },

          { property: 'og:title', content: this.dish.name },
          { property: 'og:description', content: this.dish.description },
          {
            property: 'og:url',
            content: `https://view.${domain}/product/${this.dish.slug}`
          },
          { property: 'og:image', content: this.imageUrl },
          { property: 'og:site_name', content: `${domain}` },
          { property: 'og:type', content: 'article' },
          { property: 'fb:app_id', content: '589382464873646' },

          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:title', content: this.dish.name },
          { name: 'twitter:description', content: this.dish.description },

          {
            name: 'twitter:creator',
            content: `${this.chef ? this.chef.twitter : ''}`
          },
          { name: 'twitter:image:src', content: this.imageUrl },

          { itemprop: 'name', content: this.dish.name },
          { itemprop: 'description', content: this.dish.description },
          { itemprop: 'image', content: this.imageUrl }
        ]
      }
    }
  },
  components: {
    ContactInfo,
    VueGoodshareFacebook,
    VueGoodsharePinterest,
    VueGoodshareReddit,
    VueGoodshareTwitter,
    VueGoodshareTelegram,
    VueGoodshareWhatsApp,
    VueGoodshare,
    DishRate,
    FoodOrigin,
    Modifiers,
    ViewProductDiscount
  },
  async mounted() {
    console.log(this.dish, 'DISH!!!')
    this.$gtm.trackView('ProductView', 'currentpath')
    this.checkFavorite()
    if (this.dish.last_call && this.dish.last_call.id) {
      await this.$store
        .dispatch('getLastCall', {
          id: this.dish.last_call.id
        })
        .then(s => {
          this.unsubscribe = s
        })
    }
    this.updateModifiers(this.dish.modifiers)
    // finds dish with the selected id to get info
    // var selectedDish = this.cart.filter(cartObj => {
    //   return cartObj.dish.id === this.dish.id
    // })
    // this.specialInstruction = selectedDish[0].specialInstruction
    // // qty is savd as string, must be int to show prepropulate
    // this.orderAmount = parseInt(selectedDish[0].orderAmount)
  },
  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile',
      chef: 'chef',
      loading: 'loading',
      cart: 'cart',
      lastCall: 'lastCall'
    }),
    renderLink() {
      /* if (this.dish.shareLink) {
        return this.dish.shareLink
      } */
      return `https://view.${domain}/product/` + this.dish.slug
    },
    showChefInfo() {
      return this.chef && this.chef.profileStatus !== 'hide'
    },
    showOrderButton() {
      return (
        this.chef &&
        this.chef.status === 'approved' &&
        this.chef.profileStatus !== 'hide'
      )
    },
    photosOnly() {
      this.dish.photos
        ? this.dish.photos.filter(p => p.imageUrl.indexOf('mp4') < 0)
        : []
      // sort gifs first
      let gifsList = this.dish.photos
        ? this.dish.photos.filter(p => p.imageUrl.indexOf('gif') >= 0)
        : []
      let photosList = this.dish.photos
        ? this.dish.photos.filter(p => p.imageUrl.indexOf('gif') < 0)
        : []
      return gifsList.concat(photosList)
    },
    videosOnly() {
      return this.dish.photos
        ? this.dish.photos.filter(p => p.imageUrl.indexOf('mp4') >= 0)
        : []
    },
    lastCallInfo() {
      if (this.lastCall && this.lastCall.items) {
        const list = this.lastCall.items.filter(i => i.product_id === this.id)
        let item = list && list.length > 0 ? list[0] : null
        if (item) {
          delete item.dish
        }

        return item
      }
    },
    checkAddToCart() {
      return (
        this.orderAmount > 0 &&
        (this.dish.modifiers ? this.hasValidModifiers : true)
      )
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
  methods: {
    async checkFavorite() {
      this.isFavorite =
        this.profile &&
        this.profile.favorite_dishes &&
        this.profile.favorite_dishes.includes(this.dish.id)
    },
    updateFavorite() {
      this.isFavorite = !this.isFavorite
      this.$store.dispatch('updateFavorite', {
        id: this.id,
        type: 'dish',
        add: this.isFavorite
      })
    },
    addToCart() {
      if (this.orderAmount < 1) {
        alert('Please set order qty ')
        return
      }
      if (!this.user) {
        this.$router.push('/signin')
      }
      let request = {
        orderAmount: this.orderAmount,
        modifiers: this.modifiers,
        specialInstruction: this.specialInstruction,
        // dish has ref to chef
        // chef: this.chef,
        dish: this.dish,
        last_call: this.lastCallInfo || null
      }

      this.$store
        .dispatch('addToCart', request)
        .then(data => {
          console.log('order data', data)

          if (data != null) {
            this.order = data
          }
          this.$router.push('/order/cart')
        })
        .catch(error => {
          console.log(error)
          this.$store.dispatch('setError', {
            message: 'Can not submit the order'
          })
        })
    },
    checkRequiredModifiers() {
      let valids = []
      console.log(this.dish.modifiers, this.modifiers, 'this.dish.modifiers')

      if (this.dish.modifiers) {
        for (let i = 0; i < Object.keys(this.dish.modifiers).length; i++) {
          valids.push(true)
        }
        Object.keys(this.dish.modifiers).forEach((modifier, p) => {
          if (this.dish.modifiers[modifier].min > 0) {
            valids[p] = false
            if (this.modifiers && this.modifiers[modifier]) {
              let max =
                this.dish.modifiers[modifier].max ||
                this.modifiers[modifier].selected.length
              if (
                this.modifiers[modifier].selected &&
                this.modifiers[modifier].selected.length >=
                  this.dish.modifiers[modifier].min &&
                this.modifiers[modifier].selected.length <= max
              ) {
                console.log(
                  `Met modifier's min:  ${this.dish.modifiers[modifier].min}`
                )
                valids[p] = true
              } else {
                console.info(`Didn't meet ${this.dish.modifiers[modifier].id}`)
                valids[p] = false
              }
            }
          }
        })
      }

      console.log(valids, ' wether')
      return !valids.includes(false)
    },
    updateModifiers(modifiers) {
      this.modifiers = modifiers
      this.hasValidModifiers = this.checkRequiredModifiers()
    },
    getAmount(amount) {
      return amount == -1 ? 'Unlimited' : amount
    },
    increment() {
      this.orderAmount = parseInt(this.orderAmount, 10) + 1
    },
    decrement() {
      this.orderAmount = parseInt(this.orderAmount, 10) - 1
    },
    selectText(containerid) {
      if (document.selection) {
        // IE
        let range = document.body.createTextRange()
        range.moveToElementText(document.getElementById(containerid))
        range.select()
      } else if (window.getSelection) {
        let range = document.createRange()
        range.selectNode(document.getElementById(containerid))
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
      }
      document.execCommand('copy')
      this.$store.dispatch('setMessage', {
        title: 'Copied',
        body: 'to clipboard'
      })
    }
  }
}
</script>
<style scoped>
.mytile {
  height: 120px !important;
}
</style>
