<template>
  <div>
    <v-btn
      v-if="false"
      class="location_icon"
      :disabled="!userPosition"
      small
      text
      @click="centerOnUser"
    >
      <v-icon>my_location</v-icon>
    </v-btn>
    <v-alert
      type="error"
      :value="true"
      outlined
      v-if="!loading && !(searchLocation || location)"
    >
      The search location is not set. Select city or turn of your location.
    </v-alert>

    <v-card color="" class="my-4" height="300px">
      <v-container class="fill-height py-0 px-0">
        <v-row class="fill-height">
          <v-col cols="12" class="py-0">
            <span class="map demo">
              <google-map
                :center="center"
                class="map"
                :zoom="zoom"
                @center_changed="updateCenter"
                @zoom_changed="updateZoom"
                @idle="sync"
                ref="map"
              >
                <google-cluster ref="cluster" :max-zoom="10">
                  <google-marker
                    v-for="marker of markers"
                    :key="marker._id"
                    :label="{
                      color: 'white',
                      fontFamily: 'Material Icons',
                      fontSize: '20px',
                      text: 'star_rate'
                    }"
                    :position="marker.position"
                    :icon="marker.icon"
                    @click="selectMarker(marker)"
                  ></google-marker>
                </google-cluster>
              </google-map>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line
import { mapGetters } from 'vuex'
import * as VueGoogleMaps from 'vue2-google-maps'
import { MapMixin } from '@/mixins/mapMixin'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
/*
20 : 1128.497220
19 : 2256.994440
18 : 4513.988880
17 : 9027.977761
16 : 18055.955520
15 : 36111.911040
14 : 72223.822090
13 : 144447.644200
12 : 288895.288400
11 : 577790.576700
10 : 1155581.153000
9  : 2311162.307000
8  : 4622324.614000
7  : 9244649.227000
6  : 18489298.450000
5  : 36978596.910000
4  : 73957193.820000
3  : 147914387.600000
2  : 295828775.300000
1  : 591657550.500000
*/
const getRange = zoom => {
  switch (zoom) {
    case 20:
    case 19:
      return 0.5
    case 18:
    case 17:
      return 1
    case 16:
      return 2
    case 15:
      return 3
    case 14:
      return 4
    case 13:
      return 5
    case 12:
      return 7
    case 11:
      return 15
    case 10:
      return 20
    case 9:
      return 30
    /*case 8:
      return 462.2324614
    case 7:
      return 9244649.227
    case 6:
      return 18489298.45
    case 5:
      return 36978596.91
    case 4:
      return 73957193.82
    case 3:
      return 147914387.6
    case 2:
      return 295828775.3
    case 1:
      return 591657550.5*/
    default:
      return 462.2324614
  }
}

export default {
  props: ['type'],
  mixins: [MapMixin],
  components: {
    'google-map': VueGoogleMaps.Map,
    'google-marker': VueGoogleMaps.Marker,
    'google-cluster': GmapCluster
  },
  data() {
    return {
      center: {
        lat: 0,
        lng: 0
      },
      reportedMapCenter: {
        lat: 0,
        lng: 0
      },
      clusterObject: null,
      userPosition: null,
      highGeohash: null,
      zoom: 12
    }
  },
  watch: {
    center: {
      handler() {
        console.log(`Watch for center ${this.type} center:`, this.center)

        this.$store.dispatch('updateSearchFilter', {
          center: { lat: this.center.lat, lon: this.center.lng },
          distance_range: getRange(this.zoom)
        })
        if (this.type == 'Chef') {
          this.$store.dispatch('SearchChefs')
        } else {
          this.$store.dispatch('loadNearbyFoods').then(data => {
            console.log('Search Finished', data)
          })
        }
      },
      deep: true
    },
    zoom: function() {
      console.log(`Watch for zoom ${this.type} zoom:`, this.zoom)
      this.$store.dispatch('updateSearchFilter', {
        center: { lat: this.center.lat, lon: this.center.lng },
        distance_range: getRange(this.zoom)
      })
      if (this.type === 'Chef') {
        this.$store.dispatch('SearchChefs')
      } else {
        this.$store.dispatch('loadNearbyFoods').then(data => {
          console.log('Search Finished', data)
        })
      }
    },
    searchLocation: function() {
      console.log('watch searchLocation ', this.searchLocation)
      if (!this.location && this.searchLocation) {
        this.reportedMapCenter.lat = this.searchLocation.latitude
        this.reportedMapCenter.lng = this.searchLocation.longitude
      }
    },
    location: function(newVal, oldVal) {
      console.log(`Watch for location ${this.type} location:`, this.center)
      console.log('newVal and oldVal', newVal, oldVal)

      let location = this.location
      if (this.searchLocation) {
        location = this.searchLocation
      }
      if (location) {
        this.reportedMapCenter.lat = location.latitude
        this.reportedMapCenter.lng = location.longitude
        if (this.location.highGeohash !== this.highGeohash) {
          this.highGeohash = location.highGeohash
          if (this.type !== 'Chef') {
            this.$store.dispatch('loadNearbyFoods')
          }
        }
      }
    },

    searching: function() {
      console.log('watch searching ', this.searching)
      if (this.searching && this.clusterObject) {
        this.clusterObject.clearMarkers()
      }
      if (this.$refs.map && this.$refs.cluster) {
        this.clusterObject = this.$refs.cluster.$clusterObject
      }
    }
    /*   nearbyFoods: function() {
      console.log('watch nearbyFoods ', this.nearbyFoods)
      // Keep this block
    }*/
    /*  $route: function() {
      // Call resizePreserveCenter() on all maps
      console.log('resize watched')
      Vue.$gmapDefaultResizeBus.$emit('resize')
    },
    center: function() {
      console.log('resize center', this.center)
    }*/
  },

  mounted() {
    if (this.clusterObject) {
      this.clusterObject.clearMarkers()
    }
    if (this.$route.query.deal_amount>0){      
      this.$store.dispatch('saveSearchFilter', {
        discount: this.$route.query.deal_amount
        
      })
    }
    
    this.setUserLocationOnMap(false)
  },
  computed: {
    ...mapGetters({
      location: 'userLocation',
      searchLocation: 'searchLocation',
      loading: 'loading',
      nearbyFoods: 'nearbyDishes',
      nearbyChefs: 'nearbyChefs',
      foodCategories: 'foodCategories',
      searching: 'searching'
    }),
    locationIsReady() {
      return this.location || this.searchLocation
    },
    markers() {
      let foods = this.nearbyFoods
      let chefs = this.nearbyChefs

      // One Icon per location to prevent svg on top of another svg
      let iconList = []

      let markers = []
      if (this.type !== 'Chef' && foods && foods.hits) {
        for (let index = 0; index < foods.hits.length; index++) {
          const food = foods.hits[index]._source

          if (food && food.location) {
            if (iconList.includes(food.uid)) {
              console.log(`food ${food.uid} icon already exists `)
              continue
            }
            iconList.push(food.uid)
            let defaultIcon = 'svg/chef.svg'
            let newIcon = defaultIcon

            if (this.foodCategories) {
              if (
                Array.isArray(food.categories.length) &&
                food.categories.length > 0
              ) {
                newIcon = this.foodCategories[food.categories[0]]
              } else {
                newIcon = this.foodCategories[food.categories]
              }

              newIcon = newIcon || defaultIcon
              console.log('Marker icon set to ', newIcon)

              /*console.log(
                `Settting icon ${food.dish.categories} to  ${newIcon}`
              )*/
            }
            let marker = {
              type: 'food',
              _id: food.id,
              position: {
                lat: food.location.latitude || food.location._latitude,
                lng: food.location.longitude || food.location._longitude
              },
              icon: {
                url: newIcon,

                scaledSize: { width: 30, height: 30 }
              }
            }
            // console.log('this marker will be added to the list', marker, food)
            markers.push(marker)
          }
        }
      }
      if (this.type === 'Chef' && chefs) {
        console.log('Setting Chef Markers', chefs.length)
        for (let index = 0; index < chefs.length; index++) {
          const chef = chefs[index]
          if (chef.address && chef.address.latitude) {
            let icon = `chef/chef-4.svg`
            if (chef.featured) {
              icon = `chef/cooking-2.svg`
            }
            if (chef.cookingStatus === 'cooking') {
              icon = `chef/cooking-3.svg`
            }
            let marker = {
              type: 'chef',
              _id: chef.id,
              position: {
                lat: chef.address.latitude,
                lng: chef.address.longitude
              },
              icon: {
                url: icon,
                scaledSize: { width: 35, height: 35 }
              }
            }
            console.log('Chef Marker', marker)

            markers.push(marker)
          }
        }
      }

      return markers
    }
  },
  methods: {
    centerOnUser() {
      if (this.userPosition) {
        this.reportedMapCenter = this.userPosition
      }
    },
    setUserPosition(position) {
      console.log('user position', position)

      if (!this.userPosition) {
        this.$store.dispatch('saveUserLocation', position)
        this.userPosition = position
        this.reportedMapCenter = this.userPosition
        if (this.type !== 'Chef') {
          this.$store.dispatch('loadNearbyFoods')
        }
      }
    },
    selectMarker(marker) {
      console.log('marker is selected:', marker)
      if (marker.type === 'chef') {
        this.$router.push(`/chef/${marker._id}`)
      } else {
        this.$router.push(`/dish/${marker._id}`)
      }
    },
    updateCenter(latLng) {
      // console.log('updateCenter', latLng.lat(), latLng.lng())

      if (latLng) {
        this.reportedMapCenter = {
          lat: latLng.lat(),
          lng: latLng.lng()
        }
      } else {
        console.warn('LatLng is null')
      }
    },
    sync() {
      this.center = this.reportedMapCenter
      console.log('Updating center :', this.center)
    },
    updateZoom(zoom) {
      console.log('new zoom', zoom, getRange(zoom))
      this.zoom = zoom
    }
  }
}
</script>
<style scoped>
.demo {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.map {
  flex: 100% 1 1;
}
</style>
