<template>
  <div>
    <v-btn fab bottom right color="pink" dark fixed @click.stop="dialog = !dialog">
      <v-icon>search</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <v-card-title class="grey lighten-4 py-4 title">Search Filter</v-card-title>
        <v-container class="pa-4">
          <v-row>
            <v-col cols="12" sm="6">
              <v-switch v-model="availableOnly" :label="`Available Foods`" color="green"></v-switch>
            </v-col>
            <v-col v-if="type === 'default'" cols="12" sm="6">
              <v-switch v-model="cookingNowOnly" :label="`Cooking now only`" color="purple"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pr-4" sm="5">
              <v-select
                label="Category"
                :items="categoryList"
                v-model="categories"
                item-text="name"
                item-value="name"
                multiple
                chips
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select label="Diet" :items="dietList" v-model="diets" multiple chips></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pr-4" sm="5">
              <v-slider
                :label="`Max Price:${price_range} `"
                thumb-label
                step="1"
                ticks
                v-model="price_range"
              ></v-slider>
            </v-col>
            <v-col cols="12" sm="6">
              <v-slider
                :label="`Distance:${distance_range} `"
                min="1"
                thumb-label
                step="5"
                ticks
                v-model="distance_range"
              ></v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5">
              <food-origin @updateOrigins="updateOrigins" />
            </v-col>
            <v-col cols="12" sm="6" >
              <v-slider
                :label="`Discount(${discount}%):`"
                min="0"
                max="100"
                thumb-label
                step="1"
                ticks
                v-model="discount"
              ></v-slider>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pa-4">
          <v-btn color="secondary" @click="setFilter">Set Filter</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="reset">Reset</v-btn>
          <v-btn text color="primary" @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FoodOrigin from './FoodOrigin'
export default {
  name: 'search-filter',
  props: ['type'],
  components: {
    FoodOrigin
  },
  data: () => ({
    dialog: false,
    cookingNowOnly: false,
    availableOnly: null,
    price_range: 20,
    distance_range: 5,
    discount: 0,
    diets: [],
    origins: [],
    categories: []
  }),

  computed: {
    ...mapGetters(['searchFilters']),
    dietList() {
      return this.$store.getters.diets
    },
    categoryList() {
      return this.$store.getters.categories
    }
  },
  mounted() {
    console.log('this.searchFilters', this.searchFilters)
    if (this.searchFilters) {
      this.distance_range = this.searchFilters.distance_range || 5
      this.diets = this.searchFilters.diets || []
      this.categories = this.searchFilters.categories || []
      this.origins = this.searchFilters.origins || []
      this.cookingNowOnly = this.searchFilters.cookingNowOnly
      this.availableOnly = this.searchFilters.availableOnly
      this.discount = this.searchFilters.discount || 0
    }
  },
  methods: {
    reset() {
      this.price_range = 20
      this.distance_range = 5
      this.diets = []
      this.origins = []
      this.categories = []
      this.discount = 0
      this.$store.dispatch('updateOrigins', [])
    },
    updateOrigins(payload) {
      this.origins = payload
    },
    setFilter() {
      this.dialog = false
      this.$store.dispatch('saveSearchFilter', {
        discount: this.discount,
        price_range: this.price_range,
        distance_range: this.distance_range,
        diets: this.diets,
        origins: this.origins,
        categories: this.categories,
        cookingNowOnly: this.cookingNowOnly,
        availableOnly: this.availableOnly
      })
      this.$store.dispatch('loadNearbyFoods')
    }
  }
}
</script>
