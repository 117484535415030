<template>
  <v-container>
    <v-card v-if="!loading && food" class="elevation-0 transparent">
      <dish-detail :dish="food.dish" :food="food"></dish-detail>
    </v-card>
  </v-container>
</template>

<script>
import DishDetail from './DishDetail'
import { mapGetters } from 'vuex'

export default {
  components: {
    DishDetail
  },
  mounted() {
    let foodId = this.$route.params.food_id
    this.$store.dispatch('findById', foodId)
  },
  computed: {
    ...mapGetters({
      user: 'user',
      food: 'food',
      loading: 'loading'
    })
  }
}
</script>
