import Geohash from 'latlon-geohash'
import firebase from '@/firebase-init'

var checkLoading = function(state) {
  let now = new Date().getTime()
  let started = new Date(state.loading_start_time)
  started.setSeconds(started.getSeconds() + 15)

  if (now > started && state.loading) {
    clearInterval(state.loading_timer)
    state.loading = false
    state.loading_timer = null
    console.log('Stopping loading timer', state.loading)
  }
}
export default {
  state: {
    loading: false,
    loading_start_time: null,
    error: null,
    message: null,
    searchLocation: null,
    //chefPortalAddress: null,
    messages: {}
  },
  mutations: {
    // setChefPortalAddress(state, payload) {
    //   state.chefPortalAddress = payload
    // },
    setTimer(state, payload) {
      state.loading_start_time = payload
    },
    setLoading(state, payload) {
      state.loading = payload
      if (payload) {
        state.loading_start_time = new Date().getTime()
        let timer = setInterval(checkLoading.bind(null, state), 5000)
        state.loading_timer = timer
      } else {
        if (state.loading_timer && state.loading_timer > 0) {
          clearInterval(state.loading_timer)
          state.loading_timer = 0
        }
      }
    },
    setError(state, payload) {
      state.message = null
      state.error = payload
    },
    setMessage(state, payload) {
      state.error = null
      state.message = payload
    },
    setSearchLocation(state, payload) {
      state.searchLocation = payload
    },
    clearError(state) {
      state.error = null
      state.message = null
    },
    addMessage(state, payload) {
      state.messages[payload.key] = payload.message
    }
  },
  actions: {
    clearError({ commit }) {
      commit('clearError')
    },
    setError({ commit }, payload) {
      commit('setError', payload)
    },
    setMessage({ commit }, payload) {
      commit('setMessage', payload)
    },
    setLoading({ commit }, payload) {
      commit('setLoading', payload)
    },
    updateSearchLocation(context, location) {
      console.log('Updating search location ', location)
      context.commit('setSearchLocation', location)
      context.dispatch('loadNearbyFoods')
    },
    setSearchLocation({ commit, getters, dispatch }, location) {
      let uid = getters.user ? getters.user.id : null
      if (location && location.latitude) {
        location.lowGeohash = Geohash.encode(
          location.latitude,
          location.longitude,
          7
        )
        location.ighGeohash = Geohash.encode(
          location.latitude,
          location.longitude
        )
        if (uid && location.formatted_address) {
          let payload = {}
          payload.formatted_address = location.formatted_address
          payload.latitude = location.latitude || null
          payload.longitude = location.longitude || null
          payload.locality = location.locality || null
          payload.lowGeohash = location.lowGeohash || null
          payload.postal_code = location.postal_code || null
          payload.ighGeohash = location.ighGeohash || null

          firebase
            .firestore()
            .doc(`profile/${uid}`)
            .set({ search_location: payload }, { merge: true })
            .then(() => {
              console.log('Location saved for ', uid, payload)
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
      if (!getters.userLocation) {
        //
      }

      dispatch('setUserLocation', location)
      commit('setSearchLocation', location)
    },
    loadMessage({ commit }, key) {
      return firebase
        .firestore()
        .doc(`/admin/messages/en/${key}`)
        .get()
        .then(data => {
          if (data.exists) {
            let message = data.data()
            console.log('message:', message)
            commit('addMessage', { key: key, message: message })
          }
        })
        .catch(error => {
          console.error('Error', error)
        })
    }
  },
  getters: {
    loading(state) {
      return state.loading
    },
    error(state) {
      return state.error
    },
    message(state) {
      return state.message
    },
    getMessage: state => key => {
      return state.messages[key]
    },
    searchLocation: state => state.searchLocation
    //chefPortalAddress: state => state.chefPortalAddress
  }
}
