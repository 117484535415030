<template>
  <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover>
    <v-carousel-item v-for="(result, i) in results" :key="i">
      <template v-if="!result.data.image.url">
        <div @click="gotoPage(result)" class="hover-handle">
          <v-card height="300px" :color="result.data.background_color">
            <v-row class="fill-height" align="center" justify="center">
              <v-card-title>
                <span>
                  <prismic-rich-text :field="result.data.product_title" />
                  <prismic-rich-text
                    style="color:red"
                    :field="result.data.subtitle"
                  />
                </span>
              </v-card-title>
            </v-row>
            <v-row class="fill-height" align="center" justify="center">
              <v-card-subtitle></v-card-subtitle>
            </v-row>
          </v-card>
        </div>
      </template>
      <template v-else>
        <v-img
          class="align-end hover-handle"
          height="300px"
          :src="result.data.image.url"
          @click="gotoPage(result)"
        >
          <v-row class="fill-height" align="center" justify="center">
            <v-card-title>
              <span>
                <prismic-rich-text :field="result.data.product_title" />
                <prismic-rich-text
                  style="color:red"
                  :field="result.data.subtitle"
                />
              </span>
            </v-card-title>
          </v-row>
          <v-row class="fill-height" align="center" justify="center">
            <v-card-subtitle></v-card-subtitle>
          </v-row>
        </v-img>
      </template>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      results: [],
      location: null
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile'
    })
  },
  mounted() {},
  methods: {
    getContent() {
      this.$prismic.client
        .query(
          [
            this.$prismic.Predicates.at('document.type', 'product'),
            this.$prismic.Predicates.at('document.tags', ['web'])
          ],

          { orderings: '[my.product.display_order]', pageSize: 8, page: 1 }
        )
        .then(response => {
          this.location = this.getLocation()

          if (response.results_size > 0) {
            response.results.forEach(record => {
              if (
                !record.data ||
                !record.data.location ||
                !record.data.location_range
              ) {
                this.results.push(record)
              } else {
                if (
                  this.isInRadius(
                    this.location,
                    record.data.location,
                    record.data.location_range
                  )
                ) {
                  this.results.push(record)
                }
              }
            })
          }
        })
    },
    getLocation() {
      if (this.profile) {
        let addresses = this.profile.addresses
        if (!addresses || addresses.length === 0) return null
        for (let i = 0; i < addresses.length; i++) {
          if (addresses[i] && addresses[i].longitude && addresses[i].latitude) {
            return {
              latitude: addresses[i].latitude,
              longitude: addresses[i].longitude
            }
          }
        }
      }
      return null
    },
    isInRadius(here, dest, radius) {
      if (here) {
        const distance = this.getDistanceInKm(
          here.latitude,
          here.longitude,
          dest.latitude,
          dest.longitude
        )
        if (distance <= radius) return true
      }
      return false
    },
    getDistanceInKm(lat1, lon1, lat2, lon2) {
      const R = 6371 // Radius of the earth in km
      const dLat = this.deg2rad(Math.abs(lat2 - lat1)) // deg2rad below
      const dLon = this.deg2rad(Math.abs(lon2 - lon1))
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
          Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c // Distance in km
      return d
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    },
    gotoPage(selectedItem) {
      console.log(selectedItem, 'selectedItem')
      if (
        selectedItem.data &&
        selectedItem.data.link &&
        selectedItem.data.link.url
      ) {
        const itemInfo = this.urlParser(selectedItem.data.link.url)
        console.log(itemInfo, 'itemInfo')
        if (
          (itemInfo.hostname.startsWith('takein') ||
            itemInfo.hostname.startsWith('view.takein')) &&
          itemInfo.typeOf &&
          itemInfo.slug
        ) {
          if (itemInfo.typeOf === 'product') {
            this.$store
              .dispatch('getDishInfoFromSlug', itemInfo.slug)
              .then(dish => {
                console.log(dish, 'response from getDishInfoFromSlug')
                if (!dish) return
                this.$router.push('/dish/' + dish.id)
              })
          } else {
            // producer
            this.$store
              .dispatch('getChefInfoFromSlug', itemInfo.slug)
              .then(res => {
                console.log(res, 'response from getChefInfoFromSlug')
                if (!res || res.length === 0) return
                this.$router.push('/chef/' + res[0].id)
              })
          }
        } else {
          window.open(selectedItem.data.link.url)
        }
      } else {
        // window.open('https://www.google.com/')
      }
    },
    urlParser(address) {
      const parser = document.createElement('a')
      parser.href = address
      return {
        hostname: parser.hostname || '',
        typeOf: parser.pathname.split('/')[1] || '',
        slug: parser.pathname.split('/')[2] || ''
      }
    }
  },
  created() {
    this.getContent()
  }
}
</script>
<style scoped>
.hover-handle {
  cursor: pointer !important;
}
</style>
