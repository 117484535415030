<template>
  <div>
    <v-navigation-drawer
      fixed
      clipped
      temporary
      app
      v-if="!isMobile && userIsAuthenticated"
      class="no-print"
      v-model="drawer"
    >
      <v-list dense >
        <!-- Will be remove after coming soon-->
        <template >
          <v-list-item to="/foods">
            <v-list-item-action>
              <v-icon>fas fa-search</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-html="$t('Common.search_title')">
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/chefs">
            <v-list-item-action>
              <v-icon>far fa-user</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              <v-list-item-title v-html="$t('Chef.search_title')">
              </v-list-item-title>
            </v-list-item-title>
          </v-list-item>
        </template>
        <div v-if="userIsAuthenticated">
          <template v-for="(item, i) in menuItems">
            <v-row  v-if="item.heading" :key="i">
              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
              <v-col cols="6" class="text-center">
                <a href="#!" class="body-2 black--text">EDIT</a>
              </v-col>
            </v-row>
            <v-divider
              v-else-if="item.divider"
              :key="i"
              dark
              class="my-3"
            ></v-divider>
            <v-list-group
              v-else-if="item.children"
              v-model="item.model"
              :key="item.text"
              :prepend-icon="item.model ? item.icon : item['icon-alt']"
              append-icon=""
            >
              <v-list-item slot="activator" class="pr-0 pl-0">
                <v-list-item-content>
                  <v-list-item-title
                    v-if="item.text_key"
                    v-html="$t(item.text_key)"
                  >
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                :to="child.link"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon :color="child.color ? child.color : ''" style="width:24px;">{{
                    child.icon
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="child.text_key"
                    v-html="$t(child.text_key)"
                  >
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else :key="i" router :to="item.link">
              <v-list-item-action>
                <v-icon style="width:24px;">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  v-if="item.text_key"
                  v-html="$t(item.text_key)"
                >
                </v-list-item-title>
                <v-list-item-title v-else> {{ item.text }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item @click="onLogout">
            <v-list-item-action>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-html="$t('Common.logout')">
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-else>
          <v-list-item to="/signin">
            <v-list-item-action>
              <v-icon>lock_open</v-icon>
            </v-list-item-action>
            <v-list-item-content>{{ $t('Common.signin') }}</v-list-item-content>
          </v-list-item>
          <v-list-item to="/signup">
            <v-list-item-action> <v-icon>face</v-icon> </v-list-item-action>
            <v-list-item-content>{{ $t('Common.signup') }}</v-list-item-content>
          </v-list-item>
          
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="takein_logo darken-1"
      dark
      dense
      clipped-left
      class="noprint"
      fixed
      v-if="!isMobile"
    >
      <v-toolbar-title>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="userIsAuthenticated"></v-app-bar-nav-icon>
        
        <v-btn text icon to="/"> <v-icon class="mx-1">home</v-icon> </v-btn>
        <v-btn text icon to="/foods" v-if="profile">
          <v-icon class="mx-1">restaurant</v-icon>
        </v-btn>
        <v-btn text icon to="/chefs" v-if="profile">
          <v-icon class="mx-1">far fa-user</v-icon>
        </v-btn>
        <v-btn text icon to="/deal/deals-of-the-day?deal_amount=10" v-if="profile">
          <v-icon>fas fa-funnel-dollar</v-icon>
        </v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-if="userIsAuthenticated">
        <cart />
        <!--
          <v-badge v-if="activeOrderCount>0"
                   left
                   color="red"
                   @click.native="showOrders">
            <span slot="badge">{{activeOrderCount}}</span>
            <v-icon>shopping_cart</v-icon>
          </v-badge>
        -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click.stop="rightDrawer = !rightDrawer" v-on="on">
              <v-badge color="red" overlap :content="totalNotificationCount" :value="totalNotificationCount">
                <!-- <span v-if="totalNotificationCount > 0" slot="badge">{{
                  totalNotificationCount
                }}</span> -->
                <v-icon>notifications</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span class="white--text"
            >{{ totalNotificationCount }} unread notifications</span
          >
        </v-tooltip>

        <user-menu></user-menu>
      </template>

      <template v-else>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text small to="/signup">
            {{ $t('Common.signup') }}
          </v-btn>
          <v-btn text small to="/signin"> {{ $t('Common.login') }} </v-btn>
        </v-toolbar-items>
      </template>
    </v-app-bar>

    <notifications
      v-if="userIsAuthenticated"
      :open="rightDrawer"
      :order_count="activeOrderCount"
      :userSettings="userSettings"
      :validProfile="validProfile"
      :notification_count="newMessageCount"
      :invitation_count="newInvitationCount"
    />
  </div>
</template>

<script>
import Notifications from '@/components/common/NotificationHeader'
import Cart from './common/HeaderCart'

import UserMenu from 'fod-core/src/components/profile/UserMenu.vue'
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    drawer: false,
    rightDrawer: false,

    items: []
  }),
  props: {
    source: String
  },
  components: {
    UserMenu,
    Cart,
    Notifications
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      isChef: 'isChef',
      validProfile: 'validProfile',
      loading: 'loading',
      //chefPortalAddress: 'chefPortalAddress',
      chefApprovalStatus: 'chefApprovalStatus',
      userSettings: 'userSettings',
      activeOrders: 'activeOrders',
      newMessageCount: 'newMessageCount',
      newInvitationCount: 'newInvitationCount'
    }),

    devMode() {
      return process.env.VUE_APP_ENV !== 'production'
        ? `(${process.env.VUE_APP_ENV})`
        : ''
    },
    isMobile() {
      return this.$route.query.mobile
        ? this.$route.query.mobile === 'true'
        : false
    },
    userIsAuthenticated() {
      return (
        this.$store.getters.user !== null &&
        this.$store.getters.user !== undefined
      )
    },
    activeOrderCount() {
      return this.activeOrders ? this.activeOrders.length : 0
    },
    totalNotificationCount() {
      let count = this.activeOrderCount
      count = count + this.newMessageCount
      count = count + this.newInvitationCount
      if (!this.validProfile) {
        count++
      }
      if (!this.userSettings || !this.userSettings.settings) {
        count++
      }
      return count
    },
    menuItems() {
      let items = []
      let extra = []

      extra = [
        { icon: 'shopping_cart', text: 'Orders', link: '/order/list' },
        { divider: true },

        { icon: 'settings', text: 'Settings', link: '/settings' },
        { icon: 'payment', text: 'Accounts', link: '/accounts' },
        { icon: 'contacts', text: 'Profile', link: '/my/profile' },
        {
          icon: 'fas fa-user-friends',
          text: 'Friends',
          link: '/invite/list'
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Favorites',
          model: false,
          children: [
            {
              color: 'pink',
              icon: 'favorite',
              text: 'My Favorite Products',
              text_key: 'Common.favorite_dishes',
              link: '/favorite/dish'
            },
            {
              color: 'purple',
              icon: 'fas fa-star',
              text: 'My Favorite Producers',
              link: '/favorite/chef'
            },
            {
              color: 'green',              
              icon: 'fas fa-thumbs-up',
              text: 'My Followings',
              link: '/social/followingchef'
            }
          ]
        },
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Support',
          model: false,
          children: [
            {
              icon: 'vpn_key',
              color: 'red',
              text_key: 'Common.change_password',
              link: '/change-password'
            },

            {
              color: 'info',
              icon: 'help',
              text_key: 'Common.help',
              link: '/help'
            }
           
          ]
        }
      ]

     
      items = [...items, ...extra]
      return items
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch('logout')
      this.$router.push('/')
    }
    // showOrders() {
    //   console.log('showOrders')
    //   this.$router.push('/order/list')
    // }
  }
}
</script>
