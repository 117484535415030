import Vue from 'vue'
import Vuex from 'vuex'
import shared from './shared'
import user from './user/user'
import cook from './cook/cook'
import profile from './profile/profile'
// import stream from './profile/stream'
import account from './account/account'
import order from './order/order'
import review from './review/review'
import settings from './settings/settings'
import { messageStore } from 'fod-core'
import invite from 'fod-core/src/store/invite'
import base_profile from 'fod-core/src/store/base_profile'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    shared: shared,
    user: user,
    cook: cook,
    profile: profile,
   // stream: stream,
    account: account,
    order: order,
    review: review,
    settings,
    invite,
    base_profile,
    message: messageStore
  }
})
