import firebase, { analytics } from '@/firebase-init'

export default {
  state: {
    review: null,
    chefRating: null
  },
  mutations: {
    setReview(state, payload) {
      state.review = payload
    },
    setChefRating(state, payload) {
      state.chefRating = payload
    }
  },
  actions: {
    // Set Review as
    // /reviews/chefs/<chefId>/<orderId>/
    saveOrderReview({ commit, getters }, payload) {
      let uid = getters.user.id
      payload.uid = uid
      payload.updatedTime = new Date().getTime()
      payload.type = 'order'

      analytics.logEvent('review_order', {
        buyer_user: uid,
        order_id: payload.orderId,
        producer_id: payload.producer_id,
        producer_ratings:
          payload.producer_ratings && payload.producer_ratings.ratings
            ? payload.producer_ratings.ratings.overall
            : null
      })

      commit('setLoading', true)
      const orderRef = firebase
        .firestore()
        .collection('reviews')
        .doc(payload.orderId)

      return orderRef
        .set(payload)
        .then(function() {
          commit('setLoading', false)
          commit('setReview', payload)
          console.log('Review successfully updated!')
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    },
    /// reviews/chefs/<chefId>/<orderId>/
    loadOrderReview({ commit }, payload) {
      //let uid = getters.user.id
      console.log(
        `Loading reviews for chef ${payload.id} and order ${payload.orderId}`
      )
      
      commit('setLoading', true)
      const orderRef = firebase
        .firestore()
        .collection('reviews')
        .doc(payload.orderId)

      return orderRef
        .get()
        .then(data => {
          
          if (data.exists) {
            let review = data.data()

            //if (review.reviewer === uid) {
            commit('setLoading', false)
            commit('setReview', review)
            console.log('Review successfully loaded!')
            return review
            /*} else {
              commit('setError', { message: 'Unable to load different user review' })
            } */
          }
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    },
    getChefRating({ commit }, chefId) {
      //let uid = getters.user.id

      console.log(`Loading ratings for chef ${chefId} `)
      //commit('setLoading', true)
      // admin/accounts/chefs/<chefId>
      const orderRef = firebase
        .firestore()
        .collection('reviews').where('producer_id', '==', chefId)
      return orderRef
        .get()
        .then(querySnapshot => {
          console.log(querySnapshot, 'from getChefRating!!!')
          const reviewsList = [];

          if (querySnapshot.size > 0) {
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                reviewsList.push(obj)
              } else {
                console.log('No data exists')
              }
            });
          }
          if(reviewsList.length > 0){
            commit('setChefRating', reviewsList)
            console.log('Review successfully loaded!', reviewsList)
          }
          return reviewsList
          /*} else {
            commit('setError', { message: 'Unable to load different user review' })
          } */
          
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error reading document: ', error)
        })
    },
    saveDishReview({ commit, getters }, payload) {
      let uid = getters.user.id
      ;(payload.uid = uid), (payload.updatedTime = new Date().getTime())
      payload.type = 'dish'

      commit('setLoading', true)
      analytics.logEvent('review_dish', {
        buyer_user: uid
      })

      const orderRef = firebase
        .firestore()
        .collection('reviews')
        .doc(payload.orderId)
      return orderRef
        .set(payload)
        .then(function() {
          commit('setLoading', false)
          commit('setReview', payload)
          console.log('Review successfully updated!')
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    },
    loadDishReview({ commit }, payload) {
      //let uid = getters.user.id
      console.log(
        `Loading reviews for dish ${payload.foodId} and order ${payload.orderId}`
      )
      commit('setLoading', true)
      const orderRef = firebase
        .firestore()
        .collection('reviews')
        .doc(payload.orderId)
      return orderRef
        .get()
        .then(data => {
          if (data.exists) {
            let review = data.data()

            //if (review.reviewer === uid) {
            commit('setLoading', false)
            commit('setReview', review)
            console.log('Review successfully loaded!')
            return review
            /*} else {
              commit('setError', { message: 'Unable to load different user review' })
            } */
          }
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          commit('setLoading', false)
          console.error('Error updating document: ', error)
        })
    }
  },
  getters: {
    review: state => {
      return state.review
    },
    chefRating: state => {
      return state.chefRating
    }
  }
}
