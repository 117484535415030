<template>
  <v-footer height="auto" absolute class="no-print white pa-2 app--footer ">
    <span class="caption"
      >FOD Live Inc. &copy; {{ new Date().getFullYear() }}</span
    >
    <v-spacer></v-spacer>
    <small>
      <a class="mx-2" href="https://about.takein.com">About</a>
      <a class="mx-2" href="https://view.takein.com/blog">Blog</a>
      <a
        rel="nofollow"
        target="_blank"
        content="noindex"
        class="mx-2"
        href="https://storage.googleapis.com/download.takein.com/apps/PRIVACYPOLICY.html"
        >Privacy Policy</a
      >
      <a
        rel="nofollow"
        target="_blank"
        content="noindex"
        href="https://storage.googleapis.com/download.takein.com/apps/TERMSOFUSE.html"
        >Term of use</a
      >
    </small>
    <v-spacer></v-spacer>

    <span class="caption mr-1 hidden-sm-and-down">
      Delicious, Authentic, Local
    </span>
    <v-icon color="pink" small>favorite</v-icon>
  </v-footer>
</template>

<script>
export default {}
</script>
<style>
.v-footer--absolute,
.app--footer {
  z-index: 2;
}
</style>
