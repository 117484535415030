<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
    v-model="show"
    left
    origin="right top"
    z-index="99"
    content-class="cart-dropdown"
    transition="slide-y-transition"
    nudge-top="-20"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-badge right overlap>
          <template v-slot:badge>
            <span slot="badge" v-if="cart && cart.length > 0">{{
              totalAmount
            }}</span>
          </template>
          <v-icon color="white">shopping_cart</v-icon>
          <i class="zmdi grey--text zmdi-shopping-cart zmdi-hc-fw font-lg"></i>
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <div class="dropdown-top d-custom-flex justify-space-between primary">
        <span class="white--text fw-bold">Cart</span>
        <span
          class="v-badge warning d-flex align-center white--text"
          v-if="cart && cart.length > 0"
          >{{ totalAmount }} NEW</span
        >
      </div>
      <div class="text-center pa-4" v-if="cart == ''">
        <span class="d-block font-3x mb-15 error--text">
          <i class="zmdi zmdi-shopping-cart"></i>
        </span>
        <h3>{{ $t('Common.noItemsFound') }}</h3>
      </div>
      <div v-else class="dropdown-content">
        <vue-perfect-scrollbar style="height:280px" :settings="settings">
          <v-list two-line>
            <template v-for="(cart, index) in cart">
              <v-list-item :key="index" class="border-bottom">
                <div class="product-img mr-3 d-flex align-center">
                  <img height="55" width="55" :src="dishImage(cart)" />
                </div>
                <v-list-item-content>
                  <span class="fs-14">{{ cart.dish.name }}</span>
                  <span class="fs-12 grey--text">
                    $ {{ productPrice(cart) || 0 }} X {{ cart.orderAmount }}
                  </span>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="deleteProductFromCart(cart)" icon>
                    <i class="zmdi zmdi-close"></i>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </vue-perfect-scrollbar>
        <delete-confirmation-dialog
          ref="deleteConfirmationDialog"
          heading="Are You Sure You Want To Delete?"
          message="Are you sure you want to delete this product permanently?"
          @onConfirm="onDeleteProductFromCart"
        >
        </delete-confirmation-dialog>
      </div>
      <v-card-actions v-if="cart != ''">
        <v-btn color="primary" v-if="horizontal" small to="/order/cart">
          {{ $t('message.viewCart') }}
        </v-btn>
        <v-btn
          v-else
          small
          color="primary"
          @click="showCart"
          to="/order/cart"
          >{{ $t('Common.viewCart') }}</v-btn
        >
        <v-spacer></v-spacer>
        <span class="fs-14 grey--text"
          >{{ $t('Common.total') }}: ${{ getTotalPrice }}</span
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { FoodMixin } from 'fod-core/src/mixins/foodMixin'
//import { getCurrentAppLayout } from 'Helpers/helpers'

export default {
  props: ['horizontal'],
  mixins: [FoodMixin],
  data() {
    return {
      show: false,
      selectDeletedProduct: null,
      settings: {
        maxScrollbarLength: 160
      }
    }
  },
  computed: {
    ...mapGetters({
      home: 'cart'
    }),
    cart() {
      return this.home ? this.home.cart : null
    },
    getTotalPrice() {
      let totalPrice = 0

      if (this.cart && this.cart.length > 0) {
        for (const item of this.cart) {
          let mod_sub = this.getModifiersSubTotal(item.modifiers)
          totalPrice += item.totalPrice
            ? item.totalPrice
            : (this.productPrice(item) || 0) * (item.orderAmount || 1)
          totalPrice += mod_sub
        }

        return totalPrice.toFixed(2) || 0
      } else {
        return totalPrice || 0
      }
    },
    totalAmount() {
      let total = 0
      if (this.cart && this.cart.length > 0) {
        for (const item of this.cart) {
          total += parseInt(item.orderAmount)
        }

        return parseInt(total)
      } else {
        return total
      }
    }
  },
  methods: {
    // getCurrentAppLayoutHandler() {
    //   return getCurrentAppLayout(this.$router)
    // },
    productPrice(product) {
      return product.last_call ? product.last_call.price : product.dish.price
    },
    dishImage(payload) {
      return payload.dish &&
        payload.dish.photos &&
        payload.dish.photos.length > 0
        ? payload.dish.photos[0].imageUrl
        : null
    },
    /**
     * open dialog to delete product
     */
    deleteProductFromCart(product) {
      this.$refs.deleteConfirmationDialog.openDialog()
      this.selectDeletedProduct = product
    },
    /**
     * delete product
     */
    onDeleteProductFromCart() {
      this.$refs.deleteConfirmationDialog.close()
      this.$store.dispatch('onDeleteProductFromCart', this.selectDeletedProduct)
    },
    showCart() {
      this.show = false
    }
  }
}
</script>
