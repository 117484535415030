<template>
  <div>
    <v-card>
      <v-card-text class="pa-0">
        <v-autocomplete
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          hide-no-data
          hide-selected
          item-text="searchable"
          item-value="id"
          placeholder="Search Chef..."
          prepend-icon="mdi-account-search"
          @change="onChange"
          return-object
          class="px-2"
        >
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img :src="data.item.avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.handle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { FoodMixin } from 'fod-core/src/mixins/foodMixin'

export default {
  mixins: [FoodMixin],

  data: () => ({
    lists: [],
    isLoading: false,
    search: null
  }),

  computed: {
    items() {
      return this.lists
    }
  },
  watch: {
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true
      if (val && val.substring(0, 1) === '@') {
        this.$store
          .dispatch('SearchChefByHandle', val.substring(1))
          .then(lists => {
            this.lists = lists.map(e => {
              return {
                id: e.id,
                handle: e.handle,
                name: e.name,
                avatar: e.avatar,
                ratings: (e.stats || {}).ratings, 
                searchable: '@' + e.handle
              }
            })
          })
          .finally(() => (this.isLoading = false))
      } else {
        this.$store
          .dispatch('SearchChefs', val)
          .then(lists => {
            
            if (lists) {
              this.lists = lists.map(e => {
                return {
                  id: e.id,
                  handle: e.handle,
                  name: e.name,
                  avatar: e.avatar,
                  ratings: (e.stats || {}).ratings,
                  searchable: e.name
                }
              })
            }
          })
          .finally(() => (this.isLoading = false))
      }
    }
  },
  methods: {
    onChange(item) {
      if (item) {
        this.$router.push('/chef/' + item.id)
        this.$store.dispatch('SearchChefs', item)
        this.lists = []
      }
    }
  }
}
</script>
