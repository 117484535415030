<template>
  <div>
    <v-toolbar>
      <v-autocomplete
        hide-details
        single-line
        placeholder="Search Food"
        editable
        label="Search Foodx"
        overflow
        item-text="name"
        item-value="id"
        :items="items"
        :menu-props="{ maxHeight: 'auto' }"
        :search-input.sync="search"
        @change="onChange"
        @keyup.enter="onSubmit"
        v-model="select"
        :hide-no-data="true"
      >
        <template slot="selection" slot-scope="data">{{ data.item.name }}</template>
        <template slot="item" slot-scope="data">
          <img
            v-if="data.item.photos && data.item.photos.length > 0"
            :src="data.item.photos[0].imageUrl"
          />
          {{ data.item.name }}
        </template>
      </v-autocomplete>

      <v-btn icon>
        <v-icon>search</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import axios from 'axios'
import { FoodMixin } from 'fod-core/src/mixins/foodMixin'

export default {
  mixins: [FoodMixin],
  props: ['type'],
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: []
    }
  },
  watch: {
    search(val) {
      val && this.querySelections(val)
    }
  },
  methods: {
    onChange(foods) {
      if (foods && foods.length > 0) {
        console.log('onChange ', foods)
        this.$router.push('/dish/' + foods)
      }
    },
    onSubmit() {
      if (this.select && this.select.length > 0) {
        console.log('onSubmit ', this.select[0])
        this.$store.dispatch('loadNearbyFoods', { text: this.select[0] })
      }
      this.items = []
      this.select = []
      this.search = null
    },
    querySelections(text) {
      this.loading = true
      this.items = []

      if (text.length > 1) {
        let location = this.searchOrUserLocation
        if (!location) {
          this.$store.dispatch('setError', {
            message: 'The location is not set!'
          })
          return
        }
        //let api = process.env.VUE_APP_API
        let api = `https://search2.${process.env.VUE_APP_DOMAIN}`
        axios
          .post(`${api}/food`, {
            text,
            field: 'name,photos,ratings,hideNotAvailable',
            exact: true,
            size: 5,
            location
          })
          .then(({ data }) => {
            let list = []
            data.hits = data.hits.filter(
              food => food._source && !food._source.hideNotAvailable
            )
            data.hits.forEach(food => {
              if (food._source) {
                food._source.id = food._id
                list.push(food._source)
              }
            })
            this.items = list
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.$store.dispatch('setError', {
              message: 'Can not load the order ' + error
            })
          })
      }
    }
  }
}
</script>
<style scoped>
img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 3px;
  margin-right: 10px;
  width: 100px;
}
div a {
  height: 80px;
}
</style>
