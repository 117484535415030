<template>
  <div id="app" class="page-container">
    <v-app>
      <app-header />
      <v-content class="pb-3">
        <template v-if="loading">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-progress-circular
                indeterminate
                class="primary--text"
                :width="7"
                :size="70"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </template>
        <router-view class="view one"></router-view>
        <router-view class="view two" name="a"></router-view>
        <router-view class="view three" name="b"></router-view>
        <router-view class="view three" name="chef"></router-view>

        <v-snackbar
          v-if="error"
          :timeout="6000"
          color="red"
          :multi-line="true"
          :vertical="false"
          v-model="snackbar"
        >
          {{ error.message }}<br />
          {{ error.body }}
          <v-btn dark text @click="clearMessage">Close</v-btn>
        </v-snackbar>

        <v-snackbar
          v-if="message"
          :timeout="10000"
          :color="message.color || 'info'"
          :multi-line="true"
          :vertical="false"
          v-model="snackbar"
        >
          {{ message.title }} <br />
          {{ message.body }}
          <v-btn dark text @click="clearMessage">Close</v-btn>
        </v-snackbar>
        <app-fab />
      </v-content>
      <app-footer />
    </v-app>
  </div>
</template>

<script>
import AppHeader from './components/Header'
import AppFab from './components/AppFab'
import AppFooter from './components/Footer'
import { mapGetters } from 'vuex'

export default {
  components: { AppHeader, AppFab, AppFooter },
  name: 'app',
  data() {
    return {
      snackbar: true,
      profileMessage: null
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      error: 'error',
      message: 'message'
    })
  },
  methods: {
    clearMessage() {
      // this.snackbar = false
      this.$store.dispatch('clearError')
    }
  },
  watch: {
    error: function() {
      if (this.error && this.error.message) {
        this.snackbar = true
      }
    },
    message: function() {
      if (this.message && this.message.body) {
        this.snackbar = true
      }
    }
  }
}
</script>
<style>
@media print {
  .no-print,
  .noprint,
  .no-print * {
    display: none !important;
  }
}
</style>
