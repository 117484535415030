<template>
  <div>
    <v-row>
      <search-filter v-if="type !== 'Chef'" :type="filter_type" />
    </v-row>
    <v-row align="center" justify="space-between">
      <v-col cols="12" md="8" class="pr-3">
        <search-food v-if="type !== 'Chef'" />
        <search-chef v-if="type === 'Chef'" />
      </v-col>
      <v-col cols="12" md="4">
        <template>
          <v-card>
            <v-text-field
              flat
              readonly
              :label="searchLocation ? searchLocation.formatted_address : ''"
              prepend-inner-icon="place"
              @click.stop="openDialogue"
              class="px-2"
            ></v-text-field>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-dialog v-model="location_dialog" width="500">
      <v-card>
        <v-card-text>
          <v-toolbar>
            <v-input hide-details single-line placeholder="Set Location">
              <vue-google-autocomplete
                id="map"
                ref="selectCity"
                focus="mapEvent"
                blur="mapEvent"
                inputChange="mapEvent"
                change="mapEvent"
                placeholder="Type your address"
                types
                v-on:placechanged="setCity"
              ></vue-google-autocomplete>
            </v-input>
          </v-toolbar>
          <v-list>
            <v-list-item @click="setUserLocationOnMap(true)">
              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="info">near_me</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Current Location</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-if="profile">
              <v-list-item
                v-for="(address, key) in profile.addresses"
                :key="key"
                @click="setUserLocationFromProfile(address)"
              >
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon
                      color="cyan"
                      v-if="address.name && address.name.toLowerCase() == 'home'"
                    >home</v-icon>
                    <v-icon v-else>place</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                    address.name ? address.name : address.address1
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="searchLocation"
                @click="setUserLocationFromProfile(searchLocation)"
              >
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="green">where_to_vote</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ searchLocation.formatted_address }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="location_dialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import SearchFilter from './common/SearchFilter'
import FoodSearchAuto from '@/components/cook/public/FoodSearchAuto'
import ChefSearchAuto from '@/components/profile/public/ChefSearchAuto'
import { MapMixin } from '@/mixins/mapMixin'
import { mapGetters } from 'vuex'

export default {
  props: ['user', 'type'],
  mixins: [MapMixin],
  data() {
    return {
      searchText: '',
      location_dialog: false,
      flagSetLocation: false,
      filter_type: 'default'
    }
  },
  components: {
    VueGoogleAutocomplete,
    'search-filter': SearchFilter,
    'search-food': FoodSearchAuto,
    'search-chef': ChefSearchAuto
  },
  computed: {
    ...mapGetters({
      location: 'userLocation',
      searchLocation: 'searchLocation',
      loading: 'loading',
      profile: 'profile'
    }),
    title() {
      return `Search ${this.type}`
    },
    showSetLocation() {
      return (
        this.flagSetLocation ||
        !this.searchLocation ||
        !this.searchLocation.formatted_address
      )
    }
  },
  watch: {
    profile: function() {
      if (this.profile && this.profile.search_location) {
        this.$store.dispatch(
          'updateSearchLocation',
          this.profile.search_location
        )
      }
    },
    $route(to) {
      if (to.name === 'Foods') {
        this.filter_type = 'default'
      } else {
        // Deal
        this.filter_type = 'deal'
      }
    }
  },
  mounted() {
    if (!this.location && !this.searchLocation) {
      console.log('Setting focus on selectCity')
      if (this.$refs.selectCity) {
        this.$refs.selectCity.focus()
      }
    }
    if (this.profile && this.profile.search_location) {
      this.$store.dispatch('updateSearchLocation', this.profile.search_location)
    } else {
      this.setPastAddress()
    }
  },
  methods: {
    setCity: function(addressData, placeResultData) {
      if (addressData) {
        addressData.formatted_address = placeResultData
          ? placeResultData.formatted_address
          : null
        this.$store.dispatch('setSearchLocation', addressData)
        this.$store.dispatch('loadNearbyFoods')
        this.flagSetLocation = false
      }
      this.location_dialog = false
    },
    search() {
      console.log('Searching', this.searchText)
      if (this.searchText.length > 0) {
        this.$store.dispatch('search' + this.type, this.searchText)
      }
    },
    mapEvent(event) {
      console.log('Map Event', event)
    },
    setShowLocation() {
      this.flagSetLocation = true
    },
    setPastAddress() {
      if (this.user) {
        this.$store
          .dispatch('getUserSettings', 'search_location')
          .then(address => {
            this.$store.commit('setSearchLocation', address)
          })
      }
    },
    setUserLocationFromProfile(address) {
      this.$store.dispatch('setSearchLocation', address)
      this.$store.dispatch('loadNearbyFoods')
      this.location_dialog = false
    },
    // first clears the text input then opens dialogue
    openDialogue() {
      // this.$refs.selectCity.clear()
      this.location_dialog = true
    }
  }
}
</script>
<style scoped>
#map .v-input__prepend-outer,
#map .v-input__append-outer,
#map .v-input__slot,
#map .v-messages {
  border: 1px dashed rgba(255, 0, 0, 0.4);
}
.v-input input {
  width: 100%;
}
</style>
