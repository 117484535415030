import firebase, { perf, analytics } from '@/firebase-init'
import promotion from 'fod-core/src/store/producer/promotion.js'

import axios from 'axios'
import _ from 'lodash'

//import * as geofirex from 'geofirex'
//const geo = geofirex.init(firebase)

const ThumbPartner = '%2F'
const ThumbPrefix = 'thumb_'

function changeImageUrl(source, pattern) {
  let pos = source.lastIndexOf(ThumbPartner)
  let dest =
    source.substring(0, pos + ThumbPartner.length) +
    pattern +
    source.substring(pos + ThumbPartner.length - 1 + 1)
  return dest
}
export default {
  state: {
    /*
    Chef: Public user will view profile of a chef
    Profile: Chef user want to view/mange the profile
    */
    chef: null,
    nearbyChefs: [],
    profile: null,
    // userProfile = buyer's profile
    userProfile: null,
    chefApprovalStatus: null,
    album: [],
    users: [],
    userCache: {},
    promotion: null
  },
  mutations: {
    setProfile(state, payload) {
      let user = firebase.auth().currentUser
      state.profile = { uid: user.uid, ...payload }
    },
    setUserProfile(state, payload) {
      state.userProfile = payload      
      if (payload) {
        state.users[payload.id] = payload
        state.userCache[payload.id] = payload
      }
    },
    
    setChef(state, payload) {
      state.chef = payload
    },
    setAlbum(state, payload) {
      state.album = payload
    },
    setChefApprovalStatus(state, payload) {
      state.chefApprovalStatus = payload
    },
    clearNearbyChefs(state) {
      state.nearbyChefs = []
    },
    addNearbyChef(state, payload) {
      console.log('Trying to add nearbyChefs', payload)
      if (!state.nearbyChefs.find(f => f.id === payload.id)) {
        state.nearbyChefs.push(payload)
      }
    },
    setNearbyChefs(state, payload) {
      state.nearbyChefs = payload
    },
    setPromotion(state, payload) {
      state.promotion = payload
    }
  },
  actions: {
   
    updateProfile({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = getters.user.id
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .update(payload)
        .then(() => {
          let profile = getters.profile
          profile = _.merge(profile, payload)
          commit('profile', profile)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    saveProfile({ commit, getters, dispatch }, payload) {
      commit('setLoading', true)

      let user = firebase.auth().currentUser

      let profile = {
        type: payload.type || null,
        name: payload.name,
        handle: payload.handle || null,
        name_search: payload.name
          ? payload.name.toLowerCase().replace(/ /g, '')
          : null,
        businessName: payload.businessName || null,
        businessLogo: payload.businessLogo || null,
        profileStatus: payload.profileStatus || null,
        email: payload.email || null,
        phone: payload.phone || null,
        avatar: payload.avatar || null,
        website: payload.website || null,
        facebook: payload.facebook || null,
        pinterest: payload.pinterest || null,
        instagram: payload.instagram || null,
        yelp: payload.yelp || null,
        twitter: payload.twitter || null,
        description: payload.description || null,
        //photoUrl: getters.user.photoUrl || null,
        invitation_id: payload.invitation_id || null,
        updatedTime: new Date().getTime()
      }

      if (!payload.avatar) {
        profile.avatar = getters.user.avatar || null
      }

      if (payload.status) {
        profile.status = payload.status
      }
      if (payload.experiences) {
        profile.experiences = payload.experiences
      }
      if (payload.permits) {
        profile.permits = payload.permits
      }
      let uid = getters.user ? getters.user.id : null

      console.log(`Saving profile for ${uid}  email ${payload.email}`)
      //let user = firebase.auth().currentUser

      if (payload.newProfile) {
        firebase
          .firestore()
          .collection('profile')
          .doc(uid)
          .set(profile, { merge: true })
          .then(() => {
            dispatch('loadProfile')
            commit('setLoading', false)
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          })
      } else {
        firebase
          .firestore()
          .collection('profile')
          .doc(uid)
          .update(profile)
          .then(() => {
            if (payload.image) {
              const filename = payload.image.name
              const ext = filename.slice(filename.lastIndexOf('.'))
              let key = Math.random()
                .toString(36)
                .substring(6)
              return firebase
                .storage()
                .ref('profile/' + uid + '/' + key + ext)
                .put(payload.image)
            }
          })
          .then(() => {
            if (payload.email) {
              user
                .updateProfile({
                  displayName: payload.name,
                  email: payload.email
                })
                .then(() => {
                  user
                    .updateEmail(payload.email)
                    .then(function() {
                      // Update successful.
                    })
                    .catch(function(error) {
                      console.error(error)
                      return error
                    })
                })
                .catch(function(error) {
                  console.error(error)
                  return error
                })
            } else {
              console.warn('User email and name is missing', payload)
            }
          })
          .then(fileData => {
            if (fileData) {
              fileData.ref.getDownloadURL().then(imageUrl => {
                imageUrl = changeImageUrl(imageUrl, ThumbPrefix)
                console.log(
                  `Updating user:${uid}  profilePicture: ${imageUrl} url`
                )
                firebase
                  .firestore()
                  .collection('profile')
                  .doc(uid)
                  .update({ profilePicture: imageUrl })
              })
            }
          })
          .then(() => {
            dispatch('loadProfile')
            commit('setLoading', false)
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          })
      }
    },
    /*saveProfileAddress({ commit, getters, dispatch }, payload) {
      commit('setLoading', true)
      let key = getters.user.id
      let userLocation = getters.userLocation
      if (payload.latitude && payload.longitude) {
        let location = new firebase.firestore.GeoPoint(
          payload.latitude,
          payload.longitude
        )
        payload.location = location
      } else {
        if (userLocation) {
          let location = new firebase.firestore.GeoPoint(
            userLocation.latitude,
            userLocation.longitude
          )
          payload.location = location
          payload.userLocation = userLocation
        }
      }
      console.log('location', location)
      firebase
        .firestore()
        .collection('profile')
        .doc(key)
        .update({ address: payload })
        .then(() => {
          dispatch('loadProfile')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },*/
    savePermit({ commit, getters, dispatch }, payload) {
      commit('setLoading', true)
      let key = getters.user.id
      firebase
        .firestore()
        .collection('profile')
        .doc(key)
        .update(payload)
        .then(() => {
          dispatch('loadProfile')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    getChefInfoFromSlug(ctx, slug) {
      return firebase
        .firestore()
        .collection('profile')
        .where('profileStatus', '==', 'public')
        .where('handle', '==', slug)
        .get()
        .then(querySnapshot => {
          let list = []
          if (querySnapshot.empty) {
            console.info('Handle not found ', slug)
            return list
          }
          querySnapshot.forEach(function(data) {
            let profile = { id: data.id, ...data.data() }
            list.push(profile)
          })
          return list
        })
    },
    SearchChefByHandle(ctx, handle) {
      // const strlength = handle.length
      // const strFrontCode = handle.slice(0, strlength - 1)
      // const strEndCode = handle.slice(strlength - 1, handle.length)

      // var startcode = handle
      // var endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)
      return (
        firebase
          .firestore()
          .collection('profile')
          .where('profileStatus', '==', 'public')
          // only chef has profileStatus, no need extra query
          //.where('type', '==', 'chef')

          // .where('handle', '>=', startcode)
          // .where('handle', '<=', endcode)
          // .limit(5)
          .get()
          .then(querySnapshot => {
            let list = []
            if (querySnapshot.empty) {
              console.info('Handle not found ', handle)
              return list
            }
            querySnapshot.forEach(function(data) {
              let profile = { id: data.id, ...data.data() }
              list.push(profile)
            })
            return list
          })
      )
    },
    // SearchChefs: async ({ commit, getters }, payload) => {
    SearchChefs({ getters, commit }, payload = {}) {
      let current_location = getters.searchLocation || getters.userLocation

      if (!current_location) {
        //dispatch('setError', { message: 'Search Location is not set' })
        console.log('Chef Search Location is not set')
        //commit('setSearchLocation', null)
        return
      }
      let location = {
        lat: current_location.latitude,
        lon: current_location.longitude
      }
      // commit('setSearchLocation', location)
      let filter = getters.searchFilters || {}
      if (filter.center) {
        location = filter.center
      }
      //let api = process.env.VUE_APP_API
      //const trace = perf.trace('chefSearchTrace')
      //trace.start()
      /*
      const profiles = geo.collection('profile', ref =>
        ref.where('type', '==', 'chef')
      )

      const center = geo.point(location.lat, location.lon)
      console.log('Center:', center)

      const radius = 100
      const field = 'user_location'

      //profiles.add({ name: 'Phoenix', user_location: center.data })

      const query = profiles.within(center, radius, field)

      query.subscribe(e => console.log('Result firestore from query:', e))
*/
      let api = `https://search2.${process.env.VUE_APP_DOMAIN}`
      return axios
        .post(`${api}/chef`, {
          text: payload.text || undefined,
          field:
            'name,title,profilePicture,description,businessHours,photoUrl,status,avatar,rating,address,featured,overallRating,meta,cookingStatus,stream_id,stream_status',
          exact: true,
          distance: filter.distance_range
            ? filter.distance_range + 'km'
            : undefined,
          size: payload.text || 10,
          location
        })
        .then(({ data }) => {
          let list = []
          console.log('Found Chefs: ', data.total)
          if (data.hits) {
            data.hits.forEach(chef => {
              if (chef._source) {
                chef._source.id = chef._id
                list.push(chef._source)
              }
            })
          } else {
            console.warn('No Chef found')
          }
          commit('setNearbyChefs', list)
          this.loading = false
          // trace.stop()
          return list
        })
        .catch(error => {
          this.loading = false
          this.$store.dispatch('setError', {
            message: 'Can not load the order ' + error
          })
          // trace.stop()
        })
    },

    loadProfile({ commit, dispatch, getters }) {
      let user = getters.user

      let ref = firebase
        .firestore()
        .collection('profile')
        .doc(user.id)
      commit('setLoading', true)
      return ref
        .get()
        .then(data => {
          let profile = { user: user.email }
          if (data.exists) {
            profile = {
              id: user.id,
              user: user.email,
              ...data.data()
            }
            if (!profile.phone) {
              profile.phone = user.phoneNumber ? user.phoneNumber : null
            }
          } else {
            console.log('Profile does not exist', profile)
          }

          if (!profile.email) {
            profile.email = user.email
          }
          if (!profile.name) {
            profile.name = user.name
          }

          if (profile.type === 'chef') {
            firebase
              .firestore()
              .collection('admin')
              .doc('accounts')
              .collection('chefs')
              .doc(user.id)
              .get()
              .then(data => {
                if (data.exists) {
                  let account = data.data()
                  commit('setChefApprovalStatus', account.approvalStatus)
                  dispatch('setBankAccount', account.bank).then(() => {
                    commit('setLoading', false)
                  })
                }
              })
              .catch(error => {
                console.error('Error', error)
                commit('setUserProfile', null)
                commit('setChefApprovalStatus', 'not available')
                commit('setLoading', false)
              })
          } else {
            commit('setLoading', false)
          }
          commit('setProfile', profile)
          return profile
        })
        .catch(error => {
          commit('setLoading', false)
          console.error(error)
          console.error(`unable to read profile`, user)
          ref
            .set({ name: user.name, email: user.email, uid: user.id })
            .then(() => {
              commit('setMessage', { title: 'Please, refresh your browser' })
            })
        })
    },
    checkHandle({ getters }, payload) {
      let profile = getters.profile
      console.log('Checking handle:', payload)
      if (profile.handle === payload) {
        return false
      }
      let api = `https://derby.${process.env.VUE_APP_DOMAIN}`
      return axios.get(`${api}/handle?name=${payload}`).then(({ data }) => {
        if (data) {
          return data.code == 200
        }
      })
    },

    loadUserProfileByHandle({ commit, getters }, payload) {
      commit('setLoading', true)
      console.log('Loading profile by handle:', payload)
      let user = getters.userFromCache(payload.uid)
      if (user) {
        commit('setUserProfile', user)
        return
      }

      return firebase
        .firestore()
        .collection('profile')
        .where('profileStatus', '==', 'public')
        .where('handle', '==', payload.tag)
        .get()
        .then(querySnapshot => {
          let profile = null
          if (querySnapshot.empty) {
            console.info('Handle not found ', payload.tag)
          }
          querySnapshot.forEach(function(data) {
            profile = data.data()
            profile.id = data.id
            commit('setUserProfile', profile)
            commit('setLoading', false)
          })
          commit('setLoading', false)
          if (profile) {
            return profile
          }
          commit('setUserProfile', null)
          console.warn('Profile does not exist', profile)
          return null
        })
        .catch(error => {
          commit('setLoading', false)
          console.log(error)
          return error
        })
    },

    loadUserProfile({ commit, getters }, payload) {
      let user = getters.userFromCache(payload.uid)
      if (user) {
        commit('setUserProfile', user)
        return user
      }
      commit('setLoading', true)

      return firebase
        .firestore()
        .collection('profile')
        .doc(payload.uid)
        .get()
        .then(data => {
          let profile = {}
          if (data.exists) {
            profile = { id: payload.uid, ...data.data() }
            commit('setUserProfile', profile)
            commit('setLoading', false)
            return profile
          } else {
            console.log('Profile is missing:', payload.uid)
            commit('setUserProfile', profile)
          }
          console.warn('Profile does not exist', profile)
          commit('setLoading', false)
          return null
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setUserProfile', null)
          console.error(error)
          return error
        })
    },

    loadChefByHandle({ commit, dispatch, state }, handle) {
      commit('setLoading', true)
      commit('setDishList', null)
      commit('setAlbum', null)
      console.log('Loading chef by handle', handle)

      // check local state, if not found then call Firebase
      let local = state.nearbyChefs.find(c => c.handle === handle)
      if (local) {
        console.log('Loaded chef from local cache', local)
        commit('setLoading', false)
        commit('setChef', local)
        return local
      } else {
        analytics.logEvent('load_producer', { handle: handle })
        
        return firebase
          .firestore()
          .collection('profile')
          .where('handle', '==', handle)
          .get()
          .then(data => {
            let chef = null
            if (data.exists) {
              chef = { id: data.id, ...data.data() }

              if (chef.status === 'approved' && chef.profileStatus !== 'hide') {
                dispatch('getChefRating', chef.id)
              } else {
                console.error('Chef is not approved or hidden', handle)
                commit('setError', {
                  message: "Sorry, Chef's profile is hidden!"
                })
                commit('setUserProfile', null)
                commit('setChef', null)
                commit('setLoading', false)
                return null
              }
            } else {
              console.log('No data exist for given profile:', handle)
              chef = null
              commit('setError', {
                title: 'Not found',
                message: 'profile not found!'
              })
            }
            commit('setChef', chef)
            commit('setLoading', false)
            return chef
          })
          .catch(error => {
            commit('setLoading', false)
            commit('setUserProfile', null)
            commit('setError', {
              message: 'Can not find Chef for given id',
              body:
                "Unable to retrieve the profile, may be it's not visible to public"
            })
            console.log(error)
          })
      }
    },
    loadChefById({ commit, dispatch, state }, id) {
      commit('setLoading', true)
      commit('setDishList', null)
      commit('setAlbum', null)

      console.log('Loading chef by id', id)

      // check local state, if not found then call Firebase
      let local = state.nearbyChefs.find(c => c.id === id)
      if (false) {
        console.log('Loaded chef from local cache', local)
        commit('setLoading', false)
        commit('setChef', local)
        commit('setUserProfile', local)
        return local
      } else {
        //const trace = perf.trace('loadChefByIdTrace')
        analytics.logEvent('load_producer', { id })
        // trace.start()
        return firebase
          .firestore()
          .collection('profile')
          .doc(id)
          .get()
          .then(data => {
            let chef = null
            if (data.exists) {
              chef = data.data()
              chef.id = data.id
              if (chef.profileStatus !== 'hide') {
                //if (chef.status === 'approved' && chef.profileStatus !== 'hide') {
                dispatch('getChefRating', chef.id)
              } else {
                console.warn('Chef is not approved or hidden: ', id)
                commit('setLoading', false)
                if (chef.profileStatus === 'hide') {
                  commit('setError', {
                    message: "Sorry, Chef's profile is hidden!"
                  })
                  commit('setUserProfile', null)
                  commit('setChef', null)
                  // trace.stop()
                  return
                }
              }
            } else {
              console.log('No data exist for given profile', id)
              chef = null
              commit('setError', {
                title: 'Not found',
                message: 'profile not found'
              })
            }
            commit('setChef', chef)
            commit('setUserProfile', chef)
            commit('setLoading', false)
            // trace.stop()
            return chef
          })
          .catch(error => {
            commit('setLoading', false)
            //trace.stop()
            commit('setError', {
              message: 'Can not find Chef for given id',
              body:
                "Unable to retrieve the profile, may be it's not visible to public"
            })
            console.log(error)
          })
      }
    },
    clearNearbyChefs({ commit }) {
      commit('clearNearbyChefs')
    },
    addNearbyChef({ commit, dispatch }, id) {
      console.log('Loading chef by id', id)
      firebase
        .firestore()
        .collection('profile')
        .doc(id)
        .get()
        .then(data => {
          let chef = null
          if (data.exists) {
            chef = { id: data.id, ...data.data() }

            if (chef.status === 'approved' && chef.profileStatus !== 'hide') {
              dispatch('getChefRating', chef.id)
              commit('addNearbyChef', chef)
            } else {
              console.log(
                'Skip adding Chef  b/c its not approved or hidden',
                chef
              )
            }
          } else {
            console.error('Unable to load chef for given id', id)
          }
        })
        .catch(error => {
          commit('setError', {
            message: 'Can not find Chef for given id',
            body:
              "Unable to retrieve the profile, may be it's not visible to public"
          })
          console.log(error)
        })
    },
    loadAlbum({ commit }, uid) {
      commit('setLoading', true)
      firebase
        .firestore()
        .collection('albums')
        .doc(uid)
        .get()
        .then(data => {
          const photos = []
          if (data.exists) {
            const list = data.data()
            if (list && list.album) {
              let obj = list.album
              for (let key in obj) {
                var url = obj[key].url || obj[key].imageUrl
                if (url) {
                  photos.push(obj[key])
                } else {
                  console.log('User ' + uid + ' has invalid album ' + data.id)
                }
              }
            }
          }
          commit('setLoading', false)
          commit('setAlbum', photos)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    removePhoto({ commit, getters }, id) {
      commit('setLoading', true)
      let album = getters.album
      album = album.filter(a => a.id !== id)
      firebase
        .firestore()
        .collection('albums')
        .doc(getters.user.id)
        .set({ album: album })
        .then(() => {
          commit('setLoading', false)
          commit('setAlbum', album)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    albumFileUpload({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = getters.user.id
      let album = getters.album

      let key = Math.random()
        .toString(36)
        .substring(6)
      const filename = payload.image.name
      const ext = filename.slice(filename.lastIndexOf('.'))
      firebase
        .storage()
        .ref('album')
        .child(uid)
        .child(key + ext)
        .put(payload.image)
        .then(fileData => {
          fileData.ref.getDownloadURL().then(imageUrl => {
            imageUrl = changeImageUrl(imageUrl, ThumbPrefix)
            album.push({
              id: key,
              url: imageUrl,
              imageUrl: imageUrl,
              description: payload.description
            })
            commit('setAlbum', album)
            return firebase
              .firestore()
              .collection('albums')
              .doc(uid)
              .set({ album: album })
          })
        })
        .then(() => {
          setInterval(() => {
            commit('setLoading', false)
          }, 1000 * 2)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })
    },
    uploadUserAvatar({ commit, getters }, payload) {
      commit('setLoading', true)
      let uid = getters.user.id
      const filename = payload.file.name
      const ext = filename.slice(filename.lastIndexOf('.'))

      let path = 'profile/' + uid + '/avatar/' + uid + ext
      console.log('Uploading to:', path)

      return firebase
        .storage()
        .ref(path)
        .put(payload.file)
        .then(fileData => {
          return fileData.ref.getDownloadURL().then(imageUrl => {
            return firebase
              .firestore()
              .collection('profile')
              .doc(uid)
              .update({ avatar: imageUrl })
          })
        })
        .then(() => {
          commit('setLoading', false)
        })
        .catch(error => {
          console.log('Failed to Uploaded ', path)
          commit('setLoading', false)
          commit('setError', error)
          console.error('error: ', error)
        })
    },
    requestFollow({ commit }, payload) {
      console.log(`Follow Requested from ${payload.from} => ${payload.to}`)

      payload.createdTime = new Date().getTime()
      console.log(payload)
      return firebase
        .firestore()
        .collection('social')
        .doc('followings')
        .collection(payload.from)
        .doc(payload.to)
        .set(payload)
        .then(() => {
          if (!payload.invitation_id) {
            commit('setMessage', {
              title: 'Follow',
              body: 'Send the request to follow.'
            })
          }
        })
        .catch(error => {
          commit('setError', error)
          console.log(error)
        })
    },
    requestUnFollow({ commit }, payload) {
      console.log(`UnFollow Requested from ${payload.from} => ${payload.to}`)
      payload.createdTime = new Date().getTime()
      return firebase
        .firestore()
        .collection('social')
        .doc('followings')
        .collection(payload.from)
        .doc(payload.to)
        .delete()
        .then(() => {
          commit('setMessage', {
            title: 'Unfollow',
            body: 'Send the request to Unfollow.'
          })
        })
        .catch(error => {
          commit('setError', error)
          console.log(error)
        })
    },
    isFollowing({ commit, getters }, payload) {
      if (!getters.user) {
        return false
      }
      let uid = getters.user.id
      console.log(`checking if ${uid} is following ${payload}`)
      return firebase
        .firestore()
        .collection('social')
        .doc('followings')
        .collection(uid)
        .doc(payload)
        .get()
        .then(querySnapshot => {
          if (!querySnapshot.exists) {
            console.log('not following')
            return false
          } else {
            console.log(`${uid} is following ${payload}`)
            return true
          }
        })
        .catch(error => {
          commit('setError', error)
          console.log(error)
        })
    },
    applyForChef({ commit, getters }) {
      commit('setLoading', true)
      let uid = getters.user.id
      firebase
        .firestore()
        .doc(`/profile/${uid}`)
        .update({ type: 'chef', applyDate: new Date().getTime() })
        .then(() => {
          commit('setLoading', false)
          console.log('Done')
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
          console.log(error)
        })
    },

    updateFavorite: async ({ getters, dispatch }, payload) => {
      let uid = getters.user.id
      let action = firebase.firestore.FieldValue.arrayUnion(payload.id)

      if (!payload.add) {
        action = firebase.firestore.FieldValue.arrayRemove(payload.id)
      } else {
        let event = {}
        event[`favorite_${payload.type}`] = payload.id
        analytics.setUserProperties(event)
        analytics.logEvent(`favorite_${payload.type}`, event)
      }

      let update = {
        favorite_dishes: action
      }
      if (payload.type === 'chef') {
        update = {
          favorite_chefs: action
        }
      }

      firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .update(update)
        .then(() => {
          if (payload.add) {
            dispatch('setMessage', { title: 'Added to your favorite list' })
          } else {
            dispatch('setMessage', {
              title: 'Removed from your favorite list',
              color: 'cyan lighten-1'
            })
          }
          console.log(`favorite_dish saved uid:${uid} dish_id:`, payload)
        })
        .catch(e => console.error(e))
    },
    loadFavorites: async ({ commit }, payload) => {
      commit('setLoading', true)
      if (payload && payload.list) {
        let type = payload.type || 'dish'
        let list = []
        await payload.list.forEach(element => {
          firebase
            .firestore()
            .collection(type)
            .doc(element)
            .get()
            .then(d => {
              if (d.exists) {
                let dish = d.data()
                dish.id = d.id
                list.push(dish)
              }
            })
        })
        commit('setLoading', false)
        return list
      }
    },
    loadFollowingChefs({ commit }, payload) {
      commit('setLoading', true)
      const userId = payload
      console.log(userId, 'userId')
      return firebase
        .firestore()
        .collection('social')
        .doc('followings')
        .collection(userId)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.empty) {
            console.debug('Following Chefs not found.')
            return []
          }
          let chefIds = [],
            chefs = []
          querySnapshot.forEach(function(data) {
            chefIds.push(data.data().to)
          })
          // let count = 0
          chefIds.forEach(id => {
            firebase
              .firestore()
              .collection('profile')
              .doc(id)
              .get()
              .then(chef => {
                // count++
                if (chef.data().name) chefs.push(chef.data())
              })
          })
          // if (count >= chefIds.length) {
          commit('setLoading', false)
          // }
          return chefs
        })
    },
    reportComplaint: async (context, payload) => {
      context.commit('setLoading', true)
      let uid = context.getters.user.id
      payload.from = uid
      if (payload && payload.to) {
        return firebase
          .firestore()
          .collection('complaints')
          .doc(payload.to)
          .collection('input')
          .add(payload)
          .then(d => {
            console.log('Filed', d)
            context.commit('setLoading', false)
            return d.id
          })
          .catch(e => {
            context.commit('setLoading', false)
            console.error('Failed', e)
          })
      }
    },
    lookupCoupon: async (context, payload) => {
      return promotion.actions._getCoupon(context, payload)
    },
    lookupPromotion: async (context, payload) => {
      return promotion.actions._getPromotion(context, payload)
    },
    loadMenus(context, payload) {
      context.commit('setLoading', true)

      return (
        firebase
          .firestore()
          .collection(`menu`)
          .where('uid', '==', payload)
          // .where('isPublic', '==', true)
          .get()
          .then(querySnapshot => {
            let menus = []
            if (querySnapshot.empty) {
              console.debug('Menu not found ', payload)
            }
            let counter = 0
            querySnapshot.forEach(function(data) {
              let menu = { id: data.id, ...data.data() }
              if (!menu.position) {
                menu.position = counter
              }
              counter += 1
              menus.push(menu)
            })

            menus.sort((a, b) =>
              a.position > b.position ? 1 : b.position > a.position ? -1 : 0
            )
            context.commit('setLoading', false)
            context.commit('setMenus', menus)
            return menus
          })
      )
    },
    loadMenus2(context, payload) {
      context.commit('setLoading', true)
      return firebase
        .firestore()
        .collection(`profile/${payload}/menus`)

        .where('isPublic', '==', true)
        .get()
        .then(querySnapshot => {
          let menus = []
          if (querySnapshot.empty) {
            console.debug('Menu not found ', payload)
          }
          querySnapshot.forEach(function(data) {
            let menu = { id: data.id, ...data.data() }
            menus.push(menu)
          })
          context.commit('setLoading', false)
          return menus
        })
    }
  },
  getters: {
    profile(state) {
      return state.profile
    },
    userProfile(state) {
      return state.userProfile
    },
    isChef(state) {
      return state.profile ? state.profile.type === 'chef' : false
    },

    album(state) {
      return state.album
    },
    chef(state) {
      return state.chef
    },
    hasAddress(state) {
      return (
        state.profile &&
        state.profile.addresses &&
        state.profile.addresses.length > 0
      )
    },

    validProfile(state, getters) {
      return getters.hasAddress && getters.user && getters.user.emailVerified
    },
    chefApprovalStatus(state) {
      return state.chefApprovalStatus || 'pending'
    },
    nearbyChefs(state) {
      //return state.nearbyChefs.filter( c => c.profileStatus !== 'hide')
      return state.nearbyChefs
    },
    nearbyChef(state) {
      return id => state.nearbyChefs.find(c => c.id === id)
    },
   
    defaultAddress(state) {
      if (!state.profile) {
        return null
      }
      return state.profile.addresses && state.profile.addresses.length > 0
        ? state.profile.addresses[0]
        : state.profile.address
    },

    promotion: state => {
      return state.promotion
    }
  }
}
