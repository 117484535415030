<template>
  <v-row>
    <v-col cols="12">
      <v-alert :value="true" dismissible color="info" icon="info" outlined>
        It seems that you have a business account. Would you like to access
        TakeIn's
        <a class="px-2 py-1 v-btn success" :href="chefPortalLink + url">
          Business Portal?
        </a>
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    url: {
      default: '/product/list'
    }
  },
  computed: {
    ...mapGetters({
      //chefPortalAddress: 'chefPortalAddress',
      loading: 'loading'
    })
  }
}
</script>
