<template>
  <div class="user-profile-widget top-author-wrap">
    <v-card v-if="profile">
      <div :class="isChef?'avatar-wrap':'avatar-user-wrap'" class=" mb-5 pos-relative">
        <span v-if="profile.banner16by9"  class="overlay-content">
          <v-img :src="profile.banner16by9" alt="Banner" height="144px" gradient="to top, rgba(0,0,0,.8), rgba(0,0,0,.2)" />
        </span>
        <div class="user-info">
          
          
          <v-avatar v-if="profile.avatar" slot="activator" size="100px">
            
            <img :src="profile.avatar" alt="Avatar" />
          </v-avatar>
           <v-avatar v-else slot="activator" size="100px">

          </v-avatar>

          
        </div>
      </div>
      <div class="author-detail-wrap">
        <div class="pa-3 authors-info">
          <div class="pt-4">
            <h4 class="mb-0 title">{{ profile.name }}</h4>
          </div>
          <span class="fs-14" v-if="profile.title">{{ profile.title }}</span>
          <ul class="list-unstyled author-contact-info mb-2">
            <li v-if="profile.businessPhone">
              <v-icon small class="mr-3">call</v-icon>
              <a
                :href="'tel:' + profile.businessPhone"
                class="fs-12 grey--text fw-normal"
                >{{ profile.businessPhone }}</a
              >
            </li>
            <li v-if="profile.businessEmail">
              <v-icon small class="mr-3">email</v-icon>
              <a :href="'mailto:' + profile.businessEmail">
                {{ profile.businessEmail }}</a
              >
            </li>
            <li v-if="profile.website">
              <v-icon small class="mr-3">public</v-icon>
              <a :href="profile.website"> {{ profile.website }}</a>
            </li>
            <li v-if="profile.businessName">
              <v-icon small class="mr-3">home</v-icon>
              <span class="fs-12 grey--text fw-normal">{{
                profile.businessName
              }}</span>
            </li>
          </ul>
          <ul class="d-custom-flex social-info list-unstyled">
            <li v-if="profile.facebook">
              <a :href="profile.facebook" target="_blank">
                <v-btn icon class="blue--text text--darken-4">
                  <v-icon medium>fab fa-facebook</v-icon>
                </v-btn>
              </a>
            </li>
            <li v-if="profile.twitter">
              <a :href="profile.twitter" target="_blank">
                <v-btn icon class="light-blue--text">
                  <v-icon medium>fab fa-twitter</v-icon>
                </v-btn>
              </a>
            </li>
            <li v-if="profile.linkedin">
              <a :href="profile.linkedin" target="_blank">
                <v-btn icon class="blue--text text--darken-4">
                  <v-icon medium>fab fa-linkedin</v-icon>
                </v-btn>
              </a>
            </li>
            <li v-if="profile.instagram">
              <a :href="profile.instagram" target="_blank">
                <v-btn icon class="blue--text text--darken-4">
                  <v-icon medium>fab fa-instagram</v-icon>
                </v-btn>
              </a>
            </li>
          </ul>
        </div>
        <div class="d-custom-flex align-center px-3 pb-3" v-if="showButton">
          <v-btn v-if="isChef" color="primary" class="ma-0 mr-3" :to="'/chef/' + profile.id"
            >View Profile</v-btn
          >
          <v-btn color="warning" class="ma-0" @click="toMessages(profile.id)"
            >Send Message</v-btn
          >
        </div>

        <ul v-if="isChef && showStats"
          class="d-custom-flex list-unstyled footer-content text-center w-100 border-top-1 align-end justify-center">
          <li>
            <h5 class="mb-0">{{ this.dishes }}</h5>
            <span class="fs-12 grey--text fw-normal">Dishes</span>
          </li>
          <li>
            <h5 class="mb-0">{{ this.followers }}</h5>
            <span class="fs-12 grey--text fw-normal">Followers</span>
          </li>
        </ul>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    profile: { type: Object },
    showButton:{ type: Boolean, default: true},
    showStats:{ type: Boolean, default: true}
  },
  data() {
    return {
      dishes: 0,
      followers: 0
    
    }
  },
  computed:{
    isChef(){

     return this.profile && this.profile.type === 'chef'
    }
  },
  mounted() {
    if (this.profile.meta && this.profile.meta.stats) {
      this.dishes = this.profile.meta.stats.dishes || 0
      this.followers = this.profile.meta.stats.followers || 0
    }
  },
  methods: {
    toMessages(recipient) {
      this.$store.dispatch('getThread', {recipient: recipient}).then(thread_id => {
        this.$router.push('/messages/' + thread_id)
      })
    }
  }
}
</script>
