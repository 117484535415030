<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        lg="6"
        v-for="modifier in modifiers"
        class="mb-1"
        :key="modifier.id"
      >
        <modifier :modifier="modifier" @updateModifier="updateModifier" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Modifier from './FoodModifier'
export default {
  components: {
    Modifier
  },
  props: {
    modifiers: {
      Type: Object
    }
  },
  data() {
    return {
      selected: {}
    }
  },
  methods: {
    updateModifier(update) {
      
      this.selected[update.modifier.id] =
        this.selected[update.modifier.id] || {}

      this.selected[update.modifier.id].modifier = update.modifier
      if (!Array.isArray(update.selected)) {
        this.selected[update.modifier.id].selected = [update.selected]
      } else {
        this.selected[update.modifier.id].selected = update.selected
      }
      
      this.$emit('updateModifiers', this.selected)
      if (!update.valid) {
        //console.info(`Modifier is not valid yet: ${update.id}`)
      }
    }
  }
}
</script>
