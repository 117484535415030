<template>
  <v-card>
    <v-card-title primary-title v-if="fields.title">
      <prismic-rich-text :field="fields.title" />
    </v-card-title>
    <v-card-text class="text-center" v-if="fields.description">
      <prismic-rich-text :field="fields.description" />
    </v-card-text>

    <v-card-text class="text-center" v-if="fields.logo">
      <prismic-image :field="fields.logo" width="300" />
    </v-card-text>
    <v-card-text class="text-center" v-if="fields.video">
      <v-col cols="12"> <prismic-embed :field="fields.video"/></v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        title: null,
        subTitle: null,
        logo: null,
        richContent: null
      }
    }
  },
  methods: {
    getContent() {
      this.$prismic.client.getSingle('takein-about').then(document => {
        if (document) {
          this.fields.title = document.data.title
          this.fields.subTitle = document.data.subTitle

          this.fields.logo = document.data.logo

          this.fields.description = document.data.description
          this.fields.video = document.data.video
        } else {
          console.error('Blog document type is null')
        }
      })
    }
  },
  created() {
    this.getContent()
  }
}
</script>
