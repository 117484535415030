//import firebase from 'firebase'
//import fs from 'firebase/firestore'

import firebase from 'firebase/app'
import 'firebase/app'
import '@firebase/auth'
import '@firebase/firestore'
import '@firebase/storage'
import 'firebase/performance'
import '@firebase/analytics'

// Initialize Firebase
var config = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: `${process.env.VUE_APP_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.VUE_APP_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: `${process.env.VUE_APP_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.VUE_APP_FCM_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
}
console.log(
  `Setting firebase config to ${process.env.VUE_APP_ENV} in ${
    process.env.NODE_ENV
  } mode`,
  config
)

firebase.initializeApp(config)
const firestore = firebase.firestore()
const perf = firebase.performance()
const analytics = firebase.analytics()

//console.log(firestore)
//const settings = { timestampsInSnapshots: true }
//console.log(fs)
//firestore.settings(settings)

export default firebase
export { firestore, perf, analytics }
