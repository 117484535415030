<template>
  <v-container>
    <v-progress-circular v-if="localLoading" indeterminate color="primary"></v-progress-circular>
    <v-row>
      <v-col cols="12" class="text-center">
        <section>
          <h3 class="headline" v-html="$t('Common.food_title')"></h3>
          <h4 v-html="$t('Common.food_sub_title')"></h4>
          <span>
            <p>{{ $t('Common.result_found', [dishTotal]) }}</p>
          </span>
        </section>
      </v-col>
    </v-row>

    <template v-if="dishes && dishTotal > 0">
      <v-row v-if="dishes.hits && dishes.hits.length > 0" dense>
        <v-col cols="12" md="6" lg="4" v-for="food in dishes.hits" :key="food._id">
          <food-view :dish="food._source"></food-view>
        </v-col>
      </v-row>
      <v-row v-if="showPagination">
        <v-col cols="12">
          <div class="text-center">
            <v-pagination :length="paginationLength" v-model="page"></v-pagination>
          </div>
        </v-col>
      </v-row>

      <v-container v-if="lastCalls && lastCalls.length > 0" class="overflow-x-auto">
        <v-row>
          <v-col cols="10">
            <h3>
              Last Calls
              <v-icon>timer</v-icon>
            </h3>
          </v-col>
          <v-col>
            <v-menu bottom origin="center center" cols="2" v-if="user">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="subscribedToLastCall && subscriptionPaused"
                  @click="pauseLastCall(false)"
                >
                  <v-list-item-title>
                    Resume Notifications
                    <v-icon color="info">play_arrow</v-icon>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="subscribedToLastCall && !subscriptionPaused"
                  @click="pauseLastCall(true)"
                >
                  <v-list-item-title>
                    Pause Notifications
                    <v-icon color="info">pause</v-icon>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="!subscribedToLastCall" @click="subscribeToLastCall">
                  <v-list-item-title>
                    Subscribe
                    <v-icon color="info">email</v-icon>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="unsubscribeToLastCall">
                  <v-list-item-title>
                    Unsubscribe
                    <v-icon color="info">unsubscribe</v-icon>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="hideLastCall">
                  <v-list-item-title>
                    Hide (not interested)
                    <v-icon color="info">close</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <template v-for="lastCall in lastCalls">
            <template v-for="(item, k) in lastCall.items">
              <v-col cols="12" md="6" lg="4" :key="k" v-if="item.dish && item.dish.name">
                <food-view :deal="item" :key="k"></food-view>
              </v-col>
            </template>
          </template>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12" sm="7">
          <h4 v-if="false">Food not found</h4>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import FoodView from './FoodView'
import { mapGetters } from 'vuex'

export default {
  components: {
    FoodView
  },
  props: ['user'],
  data() {
    return {
      page: 1,
      localLoading: false,
      subscriptions: [],
      settings: {
        maxScrollbarLength: 870,
        suppressScrollX: true,
        suppressScrollY: true
      }
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      dishes: 'nearbyDishes',
      userLocation: 'userLocation',
      searching: 'searching',
      defaultPageSize: 'defaultPageSize',
      lastCalls: 'lastCalls'
    }),
    dishTotal() {
      return (this.dishes.total && this.dishes.total.value) || 0
    },
    showPagination() {
      return this.paginationLength > 1
    },
    paginationLength() {
      return Math.floor(this.dishTotal / this.defaultPageSize + 1)
    },
    dishType() {
      return this.$route.path === '/dishes'
    },
    subscribedToLastCall() {
      return (
        this.subscriptions &&
        this.subscriptions.filter(s => s.type == 'last_call').length > 0
      )
    },
    subscriptionPaused() {
      return (
        this.subscriptions &&
        this.subscriptions.filter(s => s.type == 'last_call')[0].isPaused
      )
    }
  },
  watch: {
    page: function() {
      this.$store.dispatch('loadNearbyFoods', {
        from: this.page - 1
      })
    }
  },
  mounted() {
    this.localLoading = true
    this.$store.dispatch('loadNearbyFoods', { from: 0 }).then(() => {
      this.localLoading = false
      this.$store.dispatch('getLastCalls')
    })
    this.$store.dispatch('getSubscription').then(list => {
      this.subscriptions = list
    })
  },
  methods: {
    subscribeToLastCall() {
      this.$store.dispatch('subscribeToLastCall', { radius: 10 })
      this.$store.dispatch('getSubscription').then(list => {
        this.subscriptions = list
      })
    },
    unsubscribeToLastCall() {
      this.$store.dispatch('unsubscribeToChannel', { type: 'last_call' })
      this.subscriptions = []
    },
    pauseLastCall(shouldPause) {
      this.$store.dispatch('updateLastCall', { isPaused: shouldPause })
    },
    hideLastCall() {
      console.log('Subscribed')
    }
  }
}
</script>
