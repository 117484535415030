import Vue from 'vue'
import Router from 'vue-router'
import firebase, { analytics } from '@/firebase-init'
import Home from '@/components/Home'
import ExploreFoods from '@/components/ExploreFoods'
import ExploreChefs from '@/components/ExploreChefs'
import notFound from '@/components/404'
import DishReview from '@/components/review/dish/DishReview'
import FoodDetail from '@/components/cook/public/FoodDetail'
import DishView from '@/components/cook/public/DishView'

//import { NewMessage, ListMessages } from 'fod-core'

import ListMessages from 'fod-core/src/components/message/ListMessages.vue'
import NewMessage from 'fod-core/src/components/message/NewMessage.vue'

import Meta from 'vue-meta'

Vue.use(Router)
Vue.use(Meta)

// Lets add children routes
// https://github.com/tamiat/tamiat/blob/feature/refactor/src/router/routes.js

let router = new Router({
  mode: 'history',
  //fallback: false,
  routes: [
    {
      path: '/',
      component: Home,
      meta: {
        title: 'TakeIn: Fresh Local Food.'
      }
    },
    {
      path: '/notFound',
      component: notFound
    },

    {
      path: '/foods',
      name: 'Foods',
      component: ExploreFoods,
      meta: {
        title: 'TakeIn: Find Nearby local and fresh foods'
      }
    },
    {
      path: '/dishes',
      name: 'Dishes',
      component: ExploreFoods,
      meta: {
        title: 'TakeIn: Nearby Dishes'
      }
    },
    {
      path: '/chefs',
      name: 'Chefs',
      component: ExploreChefs,
      meta: {
        title: 'TakeIn: Nearby Chefs, Local Restaurants, Farmers Market '
      }
    },
    {
      path: '/deal/deals-of-the-day',
      name: 'Deals',
      component: ExploreFoods,
      meta: {
        title: 'TakeIn: Nearby Chefs, Local Restaurants, Farmers Market '
      }
    },
    {
      path: '/dish/:id',
      component: DishView,
      meta: {
        title: 'Dish View'
      }
    },
    {
      path: '/product/:slug',
      component: () => import('@/components/cook/public/ProductView'),
      meta: {
        title: 'Product View'
      }
    },
    {
      path: '/support',
      component: () => import('fod-core/src/components/SupportForm.vue'),
      meta: {
        title: 'TakeIn Support'
      }
    },
    {
      path: '/food/:food_id',
      component: FoodDetail
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/components/About')
    },
    {
      path: '/help',
      name: 'TakeIn Help',
      props: true,
      component: () => import('@/components/Help')
    },
    {
      path: '/my/profile',
      name: 'MyProfile',
      component: () => import('@/components/profile/Profile'),
      meta: {
        title: 'View Profile',
        requiresAuth: true
      }
    },
    {
      path: '/chef/signup',
      component: () => import('@/components/chef/BecomeChef'),
      meta: {
        title: 'Signup'
      }
    },
    {
      path: '/chef/apply',
      component: () => import('@/components/chef/ApplyForChef'),
      meta: {
        title: 'Apply as Chef'
      }
    },

    {
      path: '/review/order/:order_id',
      component: () => import('@/components/review/chef/OrderReview'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/review/customer/:id/:order_id',
      component: () => import('@/components/review/customer/CustomerReview'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/chef/:id',
      name: 'ChefPublicProfile',
      component: () => import('@/components/profile/public/ChefPublicProfile')
    },
    {
      path: '/chef/menu/:id',
      name: 'Chef Menu',
      component: () => import('@/components/profile/public/ChefMenu')
    },
    {
      path: '/profile/:id',
      name: 'PublicProfile',
      component: () =>
        import('fod-core/src/components/profile/PublicProfile.vue'),
      meta: {
        title: 'Profile View'
      }
    },
    {
      path: '/tag/:id',
      name: 'TagProfile',
      //component: () => import('@/components/user/PublicProfile'),
      component: () => import('@/components/profile/public/ChefPublicProfile'),
      meta: {
        title: 'View Chef Profile'
      }
    },
    {
      path: '/producer/:id',
      name: 'ProducerProfile',
      //component: () => import('@/components/user/PublicProfile'),
      component: () => import('@/components/profile/public/ChefPublicProfile'),
      meta: {
        title: 'View Producer Profile'
      }
    },
    {
      path: '/watch/:id',
      component: () => import('@/components/live/ViewLive'),
      meta: {
        title: 'Watch chef live broadcast'
      }
    },

    {
      path: '/order/cart',
      component: () => import('@/components/order/Cart'),
      meta: {
        requiresAuth: true,
        title: 'View cart'
      }
    },
    {
      path: '/order/view/:order_id',
      component: () => import('@/components/order/OrderView'),
      meta: {
        requiresAuth: true,
        title: 'View order detail'
      }
    },
    {
      path: '/order/list',
      component: () => import('fod-core/src/components/order/OrderList.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order/list',
      component: () => import('fod-core/src/components/order/OrderList.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/review/dish/:food_id/:order_id',
      component: DishReview,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/invite',
      component: () => import('fod-core/src/components/profile/Invite.vue'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/invite/list',
      component: () => import('fod-core/src/components/profile/InviteList.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/invite/accept',
      component: () => import('@/components/user/AcceptInvite'),
      meta: {
        requiresAuth: true,
        signup: true,
        param: 'invitation_id'
      }
    },
    {
      path: '/signin',
      name: 'Signin',
      component: () => import('@/components/user/Signin'),
      meta: {
        title: 'Signin to TakeIn'
      }
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: () => import('@/components/user/ForgotPassword'),
      meta: {
        title: 'Forgot password'
      }
    },
    {
      path: '/change-password',
      name: 'ChangePassword',
      component: () => import('@/components/user/ChangePassword'),
      meta: {
        requiresAuth: true,
        title: 'Change password'
      }
    },

    {
      path: '/dish/label/:id',
      component: () => import('@/components/cook/public/FoodPublicLabel'),
      meta: {
        title: 'Food Label'
      }
    },

    {
      path: '/favorite/dish',
      component: () => import('@/components/profile/FavoriteDishes'),
      meta: {
        title: 'Favorite Dishes'
      }
    },
    {
      path: '/favorite/chef',
      component: () => import('@/components/profile/FavoriteChefs'),
      meta: {
        title: 'Favorite Chefs'
      }
    },
    {
      path: '/social/followingchef',
      component: () => import('@/components/profile/FollowingChefs'),
      meta: {
        title: 'Following Chefs'
      }
    },
    {
      path: '/accounts',

      component: () => import('@/components/account/AccountView'),
      meta: {
        requiresAuth: true,
        title: 'Accounts access'
      }
    },

    {
      path: '/accounts/connect-success',
      component: () => import('@/components/account/ConnectSuccess')
    },
    {
      path: '/accounts/connect-fail',
      component: () => import('@/components/account/ConnectFail')
    },
    {
      path: '/accounts/payment',
      component: () => import('@/components/account/PaymentAccount'),
      meta: {
        requiresAuth: true,
        title: 'Payment setup'
      }
    },
    {
      path: '/accounts/payment_history',
      component: () => import('@/components/account/PaymentView'),
      meta: {
        requiresAuth: true,
        title: 'Payment history'
      }
    },
    {
      path: '/settings',
      component: () => import('@/components/setup/Settings'),
      meta: {
        requiresAuth: true,
        title: 'Settings'
      }
    },
    {
      path: '/settings/notification',
      component: () =>
        import('fod-core/src/components/settings/NotificationSettings.vue'),
      meta: {
        requiresAuth: true,
        title: 'Notification Settings'
      }
    },
    {
      path: '/settings/meal-preference',
      component: () => import('@/components/setup/MealPreference')
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: '/settings/lastcall',
      component: () => import('@/components/setup/LastCallPreference')
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: '/signup/:type?',
      name: 'Signup',
      component: () => import('@/components/user/Signup'),
      meta: {
        title: 'Signup to TakeIn'
      }
    },
    {
      path: '/phone-signin',
      name: 'PhoneSignin',
      component: () => import('@/components/user/PhoneSignin')
    },
    {
      path: '/profile',
      name: 'Profile',
      component: () => import('@/components/profile/public/ChefPublicProfile'),
      meta: {
        gtm: 'profile',
        requiresAuth: true,
        title: 'View Profile'
      }
    },
    {
      path: '/profile/address',
      name: 'ProfileAddress',
      component: () =>
        import('fod-core/src/components/profile/ProfileAddress.vue'),
      meta: {
        title: 'Profile address'
      }
    },

    {
      path: '/messages',
      name: 'Messages',
      meta: {
        title: 'Message',
        requiresAuth: true
      },
      component: ListMessages
    },
    {
      path: '/messages/:id',
      name: 'New Message',
      meta: {
        requiresAuth: true,
        title: 'New Message '
      },
      component: NewMessage
    },
    {
      path: '/complaint/chef/:id',
      name: 'New Chef Complaint',
      meta: {
        requiresAuth: true,
        title: 'New Chef Complaint '
      },
      component: () => import('@/components/complaint/ChefComplaint')
    },

    {
      path: '/dish/promo/:producer_id',
      meta: {
        title: 'View Promotion'
      },
      component: () =>
        import('fod-core/src/components/promotions/ViewCouponBox.vue')
    },
    {
      path: '/roi',
      component: () => import('fod-core/src/components/util/RoiCalculator.vue'),
      meta: {
        title: 'RIO Calculator'
      }
    }
  ]
})

// https://github.com/bradtraversy/vue_firestore_employeemanager/blob/auth/src/router/index.js
router.beforeEach((to, from, next) => {
  analytics.logEvent('screen_view', {
    app_name: 'TakeIn',
    screen_name: to.fullPath,
    app_id: 'web',
    app_version: process.env.VUE_APP_VERSION,
    title: to.meta ? to.meta.title : ''
  })
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (!to.matched.length) {
    next({
      path: '/notFound',
      query: {
        path: to.fullPath
      }
    })
  } else {
    next()
  }
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged user
    if (!firebase.auth().currentUser) {
      // Go to login

      let path = '/signin'
      let query = {
        redirect: to.fullPath
      }
      if (to.meta.param) {
        query[to.meta.param] = to.query[to.meta.param]
      }
      if (to.meta.signup) {
        path = '/signup'
      }
      next({
        path,
        query
      })
    } else {
      // Proceed to route
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    // Check if NO logged user
    if (firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      next()
    }
  } else {
    // Proceed to route
    next()
  }
})
/*
router.beforeEach((to, from, next) => {

  if (!to.meta.requiresAuth) {
    next()
    return;
  }
  console.log("firebase.auth().currentUser", firebase.auth().currentUser)
  if (firebase.auth().currentUser) {
    // have user info
    next();
    return;
  }

  next({
    path: '/signin'
  })
  let unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    handleStateChange(user);

  })
  function handleStateChange(user) {
    if (user) {
          store.dispatch('autoSignIn', user)
          store.dispatch('loadFoodTypes')
          store.dispatch('loadProfile')
          next(to)
    }
    unsubscribe();
  }
}

);

*/
/*
  if (to.matched.some(record => record.meta.requiresAuth)) {

    console.log("firebase.auth().currentUser", firebase.auth().currentUser)
    if (!firebase.auth().currentUser) {
      console.log('forAdmins', 2)
      next({
        path: '/signin'
      })
      return
    }
    console.log('to', to)
    console.log('from', from)
    next(to)

  } else {
    next()
  }
}
);*/
export default router
