<template>
  <v-container>
    <v-card v-if="!loading && dish" class="elevation-0 transparent">
      <dish-detail :dish="dish"></dish-detail>
    </v-card>
  </v-container>
</template>

<script>
import DishDetail from './DishDetail'
import { mapGetters } from 'vuex'
import { FoodMixin } from 'fod-core/src/mixins/foodMixin'

export default {
  mixins: [FoodMixin],
  components: {
    DishDetail
  },
  mounted() {
    let id = this.$route.params.id
    this.$store.dispatch('findDishById', id)
  },
  computed: {
    ...mapGetters({
      user: 'user',
      dish: 'dish',
      loading: 'loading'
    })
  }
}
</script>
