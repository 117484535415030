import firebase, { perf, analytics } from '@/firebase-init'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import * as geofirex from 'geofirex'
const geo = geofirex.init(firebase)

export default {
  state: {
    food: null,
    dish: null,
    nearbyFoods: [],
    nearbyDishes: [],
    foodTypes: [],
    ingredients: [],
    dishList: null,
    chefDishList: null,
    foodCategories: null,
    searchFilter: {},
    searching: false,
    pageSize: 12,
    currentOrigins: [],
    menus: [],
    cuisineList: [],
    lastCalls: [],
    lastCall: null,
    emailPromo: null,
    lastCallRestaurants: []
  },
  mutations: {
    setFoodTypes(state, payload) {
      state.foodTypes = payload
    },
    setCuisines(state, payload) {
      state.cuisineList = payload
    },
    setCategories(state, payload) {
      state.foodCategories = payload
    },
    setFood(state, payload) {
      state.food = payload
    },
    setDish(state, payload) {
      state.dish = payload
    },
    setDishList(state, payload) {
      state.dishList = payload
    },
    setChefDishList(state, payload) {
      state.chefDishList = payload
    },
    setIngredients(state, payload) {
      state.ingredients = payload
    },
    setNearbyFoods(state, payload) {
      state.nearbyFoods = payload
    },
    setNearbyDishes(state, payload) {
      state.nearbyDishes = payload
    },
    addNearbyFood(state, payload) {
      if (!state.nearbyFoods.find(f => f.id === payload.id)) {
        state.nearbyFoods.push(payload)
      }
    },
    addNearbyDish(state, payload) {
      if (
        !state.nearbyFoods.find(f => f.dish.id === payload.id) &&
        !state.nearbyDishes.find(f => f.id === payload.id)
      ) {
        state.nearbyDishes.push(payload)
      }
    },
    removeDishList(state, id) {
      if (state.dishList) {
        state.dishList = state.dishList.filter(dish => dish.id !== id)
      }
    },
    setSearchFilter(state, payload) {
      state.searchFilter = payload
    },
    updateSearchFilter(state, payload) {
      state.searchFilter = _.merge(state.searchFilter, payload)
    },

    setSearching(state, payload) {
      state.searching = payload
    },
    setPageSize(state, payload) {
      state.pageSize = payload
    },
    updateOrigins(state, payload) {
      state.currentOrigins = payload
    },
    setMenus(state, payload) {
      state.menus = payload
    },
    setLastCalls(state, payload) {
      state.lastCalls = payload
    },
    setLastCall(state, payload) {
      state.lastCall = payload
    },
    setEmailPromo(state, payload) {
      state.emailPromo = payload
    },
    setLastCallRestaurants(state, payload) {
      state.lastCallRestaurants = payload
    }
  },
  actions: {
    async getDishItemsFromMenuId({ dispatch }, payload) {
      let data = await firebase
        .firestore()
        .collection('menu')
        .doc(payload.id)
        .get()
      let menu = data.data(),
        productIds = [],
        subMenuItemIds = []

      if (
        menu.isActive &&
        (menu.isPublic || (menu.followerOnly && payload.isFollowing))
      ) {
        if (menu.type && menu.children.length > 0) {
          //parent menu
          for (let i = 0; i < menu.children.length; i++) {
            let ret_data = await dispatch('getDishItemsFromMenuId', {
              id: menu.children[i],
              isFollowing: payload.isFollowing
            })
            subMenuItemIds.push(...ret_data)
          }
        } else {
          if (menu.items && menu.items.length > 0) {
            productIds.push(...menu.items)
          }
        }
      }
      return [...productIds, ...subMenuItemIds]
    },
    getDishInfoFromDishId({ commit }, payload) {
      commit('setLoading', true)
      return firebase
        .firestore()
        .collection('dish')
        .doc(payload)
        .get()
        .then(data => {
          commit('setLoading', false)
          return data.data()
        })
        .catch(error => {
          console.log(error)
          return null
        })
    },
    getDishInfoFromSlug({ commit }, payload) {
      console.log(payload, 'payload!!!')
      commit('setLoading', true)
      return firebase
        .firestore()
        .collection('dish')
        .where('visibility', '==', 'public')
        .where('slug', '==', payload)
        .get()
        .then(data => {
          commit('setLoading', false)
          if (data && data.docs && data.docs.length > 0) {
            const obj = data.docs[0].data()
            obj.id = data.id
            commit('setDish', obj)
            return obj
          }
          return null
        })
        .catch(error => {
          console.log(error)
          return null
        })
    },
    getChefFromDish(context, payload) {
      const uid = payload

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .get()
        .then(res => {
          return res.data()
        })
        .catch(error => {
          console.log(error)
          return null
        })
    },
    getMenusPerDishes({}, payload) {
      const chefId = payload.chefId,
        dishIds = payload.dishIds

      return firebase
        .firestore()
        .collection('menu')
        .where('uid', '==', chefId)
        .where('items', 'array-contains-any', [...dishIds])
        .get()
        .then(querySnapshots => {
          let menu,
            menusPerDishes = []

          for (let i = 0; i < dishIds.length; i++) {
            menusPerDishes.push({ dishId: dishIds[i], menus: [] })
          }
          querySnapshots.forEach(data => {
            menu = data.data()
            for (let i = 0; i < menusPerDishes.length; i++) {
              if (menu.items.includes(menusPerDishes[i].dishId)) {
                menusPerDishes[i].menus.push(menu)
              }
            }
          })

          return menusPerDishes
        })
        .catch(error => {
          console.log(error)
          return null
        })
    },
    getPromotion(context, payload) {
      context.commit('setLoading', true)
      let uid = payload.chef_id
      console.log(`Getting promotion of uid ${uid}`)
      return firebase
        .firestore()
        .collection('campaign')
        .doc(uid)
        .collection('coupons')
        .doc(payload.code)
        .get()
        .then(data => {
          if (data.exists) {
            context.commit('setLoading', false)
            return data.data()
          }
        })
        .catch(error => {
          context.commit('setLoading', false)
          console.log(error)
          return null
        })
    },
    loadFoodTypes({ commit }) {
      firebase
        .firestore()
        .collection('types')
        .get()
        .then(querySnapshot => {
          const types = []
          querySnapshot.forEach(data => {
            if (data.exists) {
              const obj = data.data()
              obj.id = data.id
              if (data.id === 'categories') {
                let map = {}
                let list = obj.en
                for (let index = 0; index < list.length; index++) {
                  const element = list[index]
                  map[element.name] = `svg/${element.icon}`
                }
                commit('setCategories', map)
              }

              if (data.id === 'sub_ingredients') {
                //console.log('sub_ingredients', obj)
              }

              // get cuisine data from fb
              if (data.id === 'cuisines') {
                let list = obj.en
                commit('setCuisines', list)
              }

              types.push(obj)
            }
          })
          //console.log('loaded food types:', types)
          commit('setFoodTypes', types)
        })
        .catch(error => {
          console.log(error)
          commit('setLoading', false)
          commit('setError', error)
        })
    },
    setPageSize({ commit }, payload) {
      commit('setPageSize', payload)
    },
    loadNearbyFoods({ commit, getters, dispatch }, payload = {}) {
      let current_location = getters.searchLocation || getters.userLocation
      let pageSize = getters.defaultPageSize

      if (!current_location) {
        //dispatch('setError', { message: 'Search Location is not set' })
        console.log('Search Location is not set')
        //commit('setSearchLocation', null)
        return
      }

      let location = {
        lat: current_location.latitude,
        lon: current_location.longitude
      }
      //commit('setSearchLocation', location)
      let filter = getters.searchFilters || {}
      if (filter.center) {
        location = filter.center
      }

      let statuses = []
      if (filter.cookingNowOnly) {
        statuses.push('cooking')
      }
      if (filter.availableOnly) {
        statuses.push('available')
      }
      console.log('Searching Food:', payload, ' Filter:', filter)

      commit('setLoading', true)

      let query = {
        text: payload.text,
        size: payload.size || pageSize,
        from: payload.from ? payload.from * pageSize : 0,
        diets: filter.diets || undefined,
        origins: filter.origins || undefined,
        categories: filter.categories || undefined,
        price: filter.price_range || undefined,
        deal_amount: filter.discount || undefined,
        status: statuses,
        distance: filter.distance_range
          ? filter.distance_range + 'km'
          : undefined,
        location
      }
      if (filter.origins) {
        commit('updateOrigins', filter.origins)
      }
      const trace = perf.trace('foodSearchTrace')
      trace.start()

      //let api = process.env.VUE_APP_API
      let api = `https://search2.${process.env.VUE_APP_DOMAIN}`
      return axios
        .post(`${api}/food`, query)
        .then(({ data }) => {
          //let list = []
          if (data.hits) {
            console.log(`Found ${data.total} food for query`, query)
            commit('setNearbyDishes', data)
            data.hits = data.hits.filter(dish => !dish._source.hideNotAvailable)

            /*data.hits.forEach(food => {
              console.log('adding food to list: ', food)
              if (food._source) {
                food._source.id = food._id
                list.push(food._source)
              }
            })*/
          } else {
            console.log('No result found', data)
          }
          //commit('setNearbyDishes', list)
          commit('setLoading', false)
          trace.stop()
          return data
        })
        .catch(error => {
          commit('setLoading', false)
          console.error(error)
          trace.stop()
          dispatch('setError', {
            message: 'Can not load the order ' + error
          })
        })
    },
    findDishById({ dispatch }, id) {
      return dispatch('findById', id)
    },

    //findById({ commit, dispatch }, payload) {
    findById: async ({ commit, dispatch }, payload) => {
      const trace = perf.trace('findDishTrace')
      analytics.logEvent('product_view', { dishId: payload })
      trace.start()

      commit('setLoading', true)
      commit('setFood', null)
      commit('setDish', null)
      console.log('Getting dish', payload)
      return await firebase
        .firestore()
        .collection('dish')
        .doc(payload)
        .get()
        .then(data => {
          if (data.exists) {
            const obj = data.data()
            console.log('Dish loaded', payload)
            obj.id = data.id
            if (obj.uid) {
              dispatch('loadChefById', obj.uid)
            }

            commit('setDish', obj)
            trace.stop()
            return obj
          } else {
            console.log(`Requested dish does not exist  ${payload}`)
            //commit('setError', { message: 'Requested food does not exist' })
          }
          commit('setLoading', false)
          trace.stop()
          return null
        })
        .catch(error => {
          console.log(error)
          trace.stop()
          commit('setLoading', false)
          error.body =
            "Unable to retrieve the product, may be it's not visible to public"
          commit('setError', error)
        })
    },
    addNearbyProduct({ commit, state }, payload) {
      console.log('addNearby', payload)

      let searchFilter = state.searchFilter
      let diets,
        categories,
        price_max,
        cookingNowOnly = null
      if (searchFilter) {
        if (searchFilter.cookingNowOnly) {
          cookingNowOnly = searchFilter.cookingNowOnly
        }
        if (searchFilter.diets && searchFilter.diets.length > 0) {
          diets = searchFilter.diets
        }
        if (searchFilter.categories && searchFilter.categories.length > 0) {
          categories = searchFilter.categories
        }
        if (searchFilter.price_range) {
          price_max = searchFilter.price_range
        }
      }

      firebase
        .firestore()
        .collection(payload.type.toLowerCase())
        .doc(payload.id)
        .get()
        .then(data => {
          if (data.exists) {
            const food = data.data()
            food.id = data.id
            let addPlease,
              skip = false

            let dish = food.dish
            console.log('Processing nearby  ' + payload.type, payload.id)
            //console.log(diets)
            //console.log(categories)
            //console.log(price_max)
            //console.log(dish)

            if (cookingNowOnly && food.status !== 'cooking') {
              console.log('Skipping  ' + payload.type, payload.id)
              skip = true
            }
            if (!skip) {
              if (!categories && !diets) {
                addPlease = true
              }
              if (dish) {
                if (diets && dish.diets.length > 0) {
                  if (dish.diets.find(n => diets.includes(n))) {
                    // should add
                    addPlease = true
                  } else {
                    //addPlease = false
                    console.log(`Food did not pass the diets filter ${diets}`)
                  }
                }
                if (
                  categories &&
                  dish.categories &&
                  dish.categories.length > 0
                ) {
                  if (categories.find(n => n === dish.categories)) {
                    // should add
                    addPlease = true
                  } else {
                    //addPlease = addPlease
                    console.log(
                      `Food did not pass the categories filter ${categories}`
                    )
                  }
                }
              }
              if (price_max && food.price > price_max) {
                addPlease = false
              }
            }
            if (addPlease) {
              commit('addNearby' + payload.type, food)
            }
          } else {
            console.log('Requested food does not exist', payload.id)
            //commit('setError', { message: 'Request food does not exist' })
          }
        })
        .catch(error => {
          console.log(error)
          commit('setError', error)
        })
    },
    getLastCall(context, payload) {
      return firebase
        .firestore()
        .collection('last_call')
        .doc(payload.id)
        .onSnapshot(data => {
          if (data.exists) {
            
            const last_call = data.data()
            console.log('Got last_call data:', payload)
            last_call.id = payload.id
            context.commit('setLastCall', last_call)
          }
        })
    },

    getLastCalls(context) {
      let current_location =
        context.getters.searchLocation || context.getters.userLocation
      if (!current_location) {
        console.warn('No location detected')
        return
      }
      let location = {
        lat: current_location.latitude,
        lon: current_location.longitude
      }
      const last_calls = firebase.firestore().collection('last_call')
      const query = geo.query(last_calls)
      const center = geo.point(location.lat, location.lon)
      console.log('Center of last call:', center)

      const radius = 10 //km
      const field = 'location'

      //profiles.add({ name: 'Phoenix', user_location: center.data })

      const result = query.within(center, radius, field)

      result.subscribe(e => {
        context.commit('setLastCalls', e)
        console.log('Last call result firestore from query:', e)
      })
    },
    getSubscription(context) {
      const user = context.getters.user
      if (!user) {
        return
      }
      return firebase
        .firestore()
        .collection('subscription')
        .where('uid', '==', user.id)
        .get()
        .then(querySnapshot => {
          let list = []
          if (querySnapshot.size > 0) {
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                list.push(obj)
              } else {
                console.log('No data exists')
              }
            })
          }
          return list
        })
    },
    subscribeToLastCall(context, data) {
      const user = context.getters.user
      console.log('Subscribing last call', user)
      let current_location =
        context.getters.searchLocation || context.getters.userLocation
      if (!current_location) {
        console.warn('No location detected')
        return
      }
      let location = {
        lat: current_location.latitude,
        lon: current_location.longitude
      }
      console.log(location)
      const geohash = geo.point(
        current_location.latitude,
        current_location.longitude
      )

      let payload = {
        type: 'last_call',
        uid: user.id,
        radius: data.radius, //km
        updatedTime: moment.utc().valueOf(),
        location: geohash,
        isPaused: false
      }

      firebase
        .firestore()
        .collection('subscription')
        .doc(user.id)
        .set(payload, { merge: true })
        .catch(e => {
          console.error(e)
          context.commit('setError', { title: 'Failed' })
        })

      context.commit('setMessage', {
        title: 'Subscribed to Last Calls',
        body: 'You will get notified of last calls within 10km '
      })
    },
    unsubscribeToChannel(context, payload) {
      const user = context.getters.user
      console.log('UnSubscribing ', payload.type)

      let query = firebase
        .firestore()
        .collection('subscription')
        .where('uid', '==', user.id)
      query.get().then(querySnapshot => {
        querySnapshot.forEach(function(doc) {
          doc.ref.delete()
        })
      })
    },
    updateLastCall(context, payload) {
      const user = context.getters.user
      console.log('Updateing Last Call')
      payload.uid = user.id
      const ref = firebase
        .firestore()
        .collection('subscription')
        .doc(user.id)

      ref.update(payload)
    },
    saveSearchFilter({ commit }, filter) {
      commit('setSearchFilter', filter)
    },
    updateSearchFilter({ commit }, filter) {
      commit('updateSearchFilter', filter)
    },
    loadDishList({ commit, getters }, payload = {}) {
      let publicOnly = false
      let id = null
      if (payload.id) {
        publicOnly = true
        id = payload.id
      }
      if (getters.user && !payload.id) {
        id = getters.user.id
      }
      if (!id) {
        console.error('Unable to load dish list id is null')
        return
      }
      if (!payload.force && getters.dishList) {
        console.log('Getting dish-list from local cache')
        return
      }

      let foodRef = firebase.firestore().collection('dish')
      foodRef = foodRef.where('uid', '==', id)
      if (publicOnly) {
        foodRef = foodRef.where('visibility', '==', 'public')
      }

      commit('setLoading', true)
      return foodRef
        .get()
        .then(querySnapshot => {
          let dishList = []
          if (querySnapshot.size > 0) {
            querySnapshot.forEach(data => {
              if (data.exists) {
                const obj = data.data()
                obj.id = data.id
                dishList.push(obj)
              } else {
                console.log('No data exists')
              }
            })
          }

          dishList = dishList.filter(dish => !dish.hideNotAvailable)

          if (publicOnly) {
            commit('setChefDishList', dishList)
          } else {
            commit('setDishList', dishList)
          }
          commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          commit('setLoading', false)
          commit('setError', error)
        })
    },
    setFood({ commit }, payload) {
      commit('setFood', payload)
    },
    setDish({ commit }, payload) {
      commit('setDish', payload)
    },

    searchNearby({ commit, state }, payload) {
      commit('setLoading', true)
      state.nearbyDishes = []
      state.nearbyFood = []
      let search = payload.text.toLowerCase().replace(/ /g, '')
      let foodRed = firebase.firestore().collection(payload.type)
      let name_search = 'name_search'
      if (payload.type == 'food') {
        name_search = 'dish.name_search'
      }
      foodRed = foodRed.where('visibility', '==', 'public')
      foodRed = foodRed.where(name_search, '>', search)
      foodRed = foodRed.where(name_search, '<', search + 'z')
      return foodRed
        .get()
        .then(querySnapshot => {
          const dishList = []
          querySnapshot.forEach(data => {
            if (data.exists) {
              const obj = data.data()
              obj.id = data.id
              dishList.push(obj)
            }
          })
          commit('setLoading', false)
          return dishList
        })
        .catch(error => {
          console.log(error)
          commit('setLoading', false)
          return error
        })
    },
    setEmailPromo({ commit }, payload) {
      commit('setEmailPromo', payload)
    },
    getLastCallRestaurants(context) {
      let current_location =
        context.getters.searchLocation || context.getters.userLocation
      if (!current_location) {
        console.warn('No location detected')
        context.commit('setLastCallRestaurants', [])
        return
      }
      let location = {
        lat: current_location.latitude,
        lon: current_location.longitude
      }
      const last_calls = firebase.firestore().collection('last_call')
      const query = geo.query(last_calls)
      const center = geo.point(location.lat, location.lon)
      console.log('Center of last call:', center)

      const radius = 10 //km
      const field = 'location'

      //profiles.add({ name: 'Phoenix', user_location: center.data })

      const result = query.within(center, radius, field)

      result.subscribe(e => {
        context.commit('setLastCallRestaurants', e)
      })
    }

    /* loadCategories(context, provider) {
      console.log('Loading loadCategories:', provider)

      return firebase
        .firestore()
        .collection('category')
        .where('uid', '==', provider)
        .get()
        .then(snapshot => {
          let list = []
          snapshot.forEach(doc => {
            let menu = doc.data()
            menu.id = doc.id
            list.push(menu)
          })
          context.commit('setLoading', false)
          context.commit('setFoodCategories', list)
          return list
        })
    },
    loadCategories2(context, provider) {
      console.log('Loading loadCategories:', provider)

      return firebase
        .firestore()
        .collection(`profile/${provider}/categories`)
        .get()
        .then(snapshot => {
          let list = []
          snapshot.forEach(doc => {
            let menu = doc.data()
            menu.id = doc.id
            list.push(menu)
          })
          context.commit('setLoading', false)
          context.commit('setFoodCategories', list)
          return list
        })
    } */
  },
  getters: {
    food(state) {
      return state.food
    },
    dish(state) {
      return state.dish
    },
    searchFilters(state) {
      return state.searchFilter
    },
    searching(state) {
      return state.searching
    },
    dishList(state) {
      return state.dishList
    },
    chefDishList(state) {
      return state.chefDishList
    },
    foodCategories(state) {
      return state.foodCategories
    },
    cuisineList(state) {
      return state.cuisineList
    },
    nearbyFoods(state) {
      return state.nearbyFoods
    },
    nearbyDishes(state) {
      let foods = state.nearbyFoods

      if (foods.length > 0) {
        return state.nearbyDishes.filter(
          d => !foods.find(f => f.dish.id === d.id)
        )
      }
      return state.nearbyDishes
    },
    currentOrigins(state) {
      return state.currentOrigins
    },
    cookingNow(state) {
      return state.nearbyFoods && state.nearbyFoods.length > 0
        ? state.nearbyFoods.filter(f => f.status === 'cooking')
        : []
    },
    diets(state) {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'diets')
        : []
      if (list.length > 0) {
        return list[0].en
      }
      return []
    },
    defaultPageSize(state) {
      return state.pageSize
    },
    ingredients(state) {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'ingredients')
        : []
      // merge user ingredients with system level
      let user = state.ingredients || []
      if (list.length > 0) {
        user = user.concat(list[0].en)
      }
      return user.sort(function(a, b) {
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() > b.toLowerCase()) return 1
        return 0
      })
    },
    categories(state) {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'categories')
        : []
      if (list.length > 0) {
        return list[0].en
      }
      return []
    },
    subIngredients: state => id => {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'sub_ingredients')
        : []

      if (list.length > 0 && list[0][id]) {
        return list[0][id]
      }

      return null
    },
    subIngredientList: state => {
      let list = state.foodTypes
        ? state.foodTypes.filter(t => t.id === 'sub_ingredients')
        : []

      if (list.length > 0) {
        // change the object to Array
        //console.log(Object.entries(list[0]))
        return Object.entries(list[0])
      }

      return list
    },

    menus(state) {
      return state.menus
    },
    lastCalls(state) {
      return state.lastCalls
    },
    lastCall(state) {
      return state.lastCall
    },
    emailPromo(state) {
      return state.emailPromo
    },
    lastCallRestaurants(state) {
      return state.lastCallRestaurants
    }
  }
}
