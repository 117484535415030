<template>
  <v-container>
    <food-search type="Food" :user="user"></food-search>
    <nearby-foods :user="user" />
    <map-search></map-search>
  </v-container>
</template>

<script>
import MapSearch from './MapSearch'
import FoodSearch from './FoodSearch'
import NearbyFoods from './cook/public/NearByFoods'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    MapSearch,
    FoodSearch,
    NearbyFoods
  },
  computed: {
    ...mapGetters({
      user: 'user',
      loading: 'loading'
    })
  }
}
</script>
