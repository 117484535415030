<template>
  <v-container>
    <chef-forward v-if="isChef" />
    <v-row align="center" justify="center" v-if="newUser">
      <v-col cols="12" sm="7" class="my-4" justify-self="center">
        <h3 class="d-flex justify-center">{{ $t('Common.new_user') }}</h3>
      </v-col>
    </v-row>
    <v-row v-if="isAndroid">
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            Get the App
            <v-spacer></v-spacer>
            <a href="https://play.google.com/store/apps/details?id=fod.takein">
              <img
                src="https://storage.googleapis.com/download.takein.com/apps/Google_Play_Badge.svg"
                alt="Download Android App Button"
              />
            </a>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isIOS">
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            Get the App
            <v-spacer></v-spacer>
            <a href="https://apps.apple.com/us/app/takein/id1479232566">
              <img
                src="https://storage.googleapis.com/download.takein.com/apps/app_store_badge.svg"
                alt="Download IOS App Button"
              />
            </a>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" v-if="!user">
      <v-col cols="12" sm="11">
        <v-img
          height="450"
          contain
          src="https://storage.googleapis.com/download.takein.com/apps/images/takein_local.jpg"
        >
          <v-row justify="center" align="end">
            <v-col cols="12">
              <v-row justify="center">
                <h1
                  :class="$vuetify.breakpoint.xs ? 'headline' : 'display-1'"
                  class="text-center font-weight-medium black--text"
                >{{ $t('Common.home_title') }}</h1>
              </v-row>
              <v-row justify="center">
                <h1
                  :class="
                    $vuetify.breakpoint.xs
                      ? 'title black--text '
                      : 'headline  white--text'
                  "
                  class="text-center"
                >{{ $t('Common.home_sub_title') }}</h1>
              </v-row>
            </v-col>
          </v-row>
        </v-img>
        <v-parallax
          v-if="false"
          height="400"
          @click="$router.push('/foods')"
          alt="Find Local Foods"
          class="my_parallax"
          src="https://storage.googleapis.com/download.takein.com/apps/images/takein_local.jpg"
        >
          <v-row justify="center" align="end">
            <v-col cols="12">
              <v-row justify="center">
                <h1
                  :class="
                    $vuetify.breakpoint.xs
                      ? 'headline yellow--text'
                      : 'display-1 black--text'
                  "
                  class="text-center font-weight-medium"
                >{{ $t('Common.home_title') }}</h1>
              </v-row>
              <v-row justify="center">
                <h1
                  :class="$vuetify.breakpoint.xs ? 'title' : 'headline'"
                  class="text-center"
                >{{ $t('Common.home_sub_title') }}</h1>
              </v-row>
            </v-col>
          </v-row>
        </v-parallax>
      </v-col>
    </v-row>

    <section>
      <v-row>
        <v-col cols="12">
          <v-row align="center">
            <v-col cols="12" sm="6">
              <v-card height="250px" to="/foods">
                <v-card-text class="text-center hidden-sm-and-down ma-0">
                  <img width="45" src="/svg/Emojione_1F35D.svg" />
                </v-card-text>
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline text-center">{{ $t('Common.nearby_fod') }}</div>
                </v-card-title>
                <v-card-text class="text-center">
                  {{
                  $t('Common.nearby_fod_sub')
                  }}
                </v-card-text>
                <v-card-actions>
                  <v-row justify="center">
                    <v-btn rounded to="/foods" color="accent" dark>Explore</v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col class="hidden-sm-and-up">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card height="250px" to="/chefs">
                <v-card-text class="text-center hidden-sm-and-down">
                  <img width="47" src="/svg/dinner-2814566.svg" />
                </v-card-text>
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline text-center">{{ $t('Common.nearby_chef') }}</div>
                </v-card-title>
                <v-card-text class="text-center">
                  {{
                  $t('Common.nearby_chef_sub')
                  }}
                </v-card-text>
                <v-card-actions>
                  <v-row justify="center">
                    <v-btn rounded to="/chefs" color="cyan" dark class="pa-3">Find</v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <section v-if="true">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <prismic-product />
        </v-col>
      </v-row>
    </section>

    <section v-if="!user">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="11">
          <v-parallax
            contain
            alt="Find best local foods at takein"
            src="https://storage.googleapis.com/download.takein.com/apps/images/woman-1979272_1920_sm.jpg"
          >
            <v-row align="center" justify="center" v-if="!user">
              <v-col>
                <v-row justify="center">
                  <div class="headline pink--text mb-3 text-center">{{ $t('Common.get_started') }}</div>
                </v-row>
                <v-row justify="center">
                  <v-btn
                    class="blue lighten-2 mt-5"
                    dark
                    large
                    href="/signup"
                  >{{ $t('Common.free_signup') }}</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-parallax>
        </v-col>
      </v-row>
    </section>
    <v-row align="center" justify="center" v-if="user">
      <v-col cols="12">
        <v-card class="py-1">
          <v-img
            src="https://storage.googleapis.com/download.takein.com/apps/images/460537.jpeg"
          ></v-img>
          <v-card-title primary-title>
            <div>
              <v-row justify="center">
                <h3 class="headline mb-0 justify-center">{{ $t('Common.invite_title') }}</h3>
              </v-row>
              <div class="fs-16" v-html="$t('Common.invite_sub_title')"></div>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn dark color="takein_logo" to="/invite">Send Invitation</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <section>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="6">
            <prismic-blog /> 
          </v-col>
          <v-col cols="12" sm="6">
            <contact></contact>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!user">
        <v-row align="center" justify="center">
          <v-col cols="12" class="mb-1">
            <div class="text-center">
              <div class="subtitle-1" v-html="$t('Common.home_sub_heading')"></div>
              <h4 class="mt-3">{{ $t('Common.home_sub_heading2') }}</h4>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!user" align="center" justify="center">
          <v-col cols="12" class="mt-3">
            <div class="text-center">
              <v-btn to="/signup" color="takein_logo" large dark>
                {{
                $t('Common.free_signup')
                }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-row align="start" justify="end">
      <v-spacer></v-spacer>
      <v-col cols="6" sm="3" md="2">
        <a href="https://play.google.com/store/apps/details?id=fod.takein">
          <img
            src="https://storage.googleapis.com/download.takein.com/apps/Google_Play_Badge.svg"
            alt="Download Android App Button"
          />
        </a>
      </v-col>
      <v-col cols="6" sm="2" lg="1">
        <a href="https://apps.apple.com/us/app/takein/id1479232566">
          <img
            src="https://storage.googleapis.com/download.takein.com/apps/app_store_badge.svg"
            alt="Download IOS App Button"
          />
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ChefForward from './common/ChefForward'
import { isAndroid, isIOS } from 'mobile-device-detect'
import PrismicProduct from '../prismic/PrismicProduct'
import PrismicBlog from '../prismic/PrismicBlog'

export default {
  components: {
    ChefForward,
    PrismicBlog,
    PrismicProduct,
    contact: () => import('fod-core/src/components/common/ContactTakeIn.vue')
  },
  data() {
    return {}
  },
  metaInfo: () => ({
    title: 'Fresh Local Food, Everywhere You Go ',
    titleTemplate: 'TakeIn: %s',
    htmlAttrs: {
      lang: 'en',
      amp: undefined
    },
    headAttrs: {
      test: true
    },
    meta: [
      {
        name: 'description',
        content:
          'TakeIn is a marketplace for healthy local food. You get better local food experiences on TakeIn because we empower local producers of all types to deliver new culinary adventure, real authenticity, better dietary control and customization, greater economy, and more convenience.<br/><br/>TakeIn locals range from culinary professionals, local restaurants, and market vendors to food trucks, farmers, fishers, and licensed home chefs. All share a passion for delicious local food. You will find what you are looking for, and much more, on TakeIn.'
      },
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ],
    script: [
      {
        innerHTML: '{ "body": "yes" }',
        body: true,
        type: 'application/ld+json'
      }
    ],
    __dangerouslyDisableSanitizers: ['script']
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      newUser: 'newUser',
      isChef: 'isChef'
    }),
    coming_soon() {
      return false
    },
    isAndroid() {
      return isAndroid
    },
    isIOS() {
      return isIOS
    }
  },
  methods: {
    goto(url) {
      console.log('going to ' + url)
      window.location = url
    }
  },
  mounted() {
    if (this.$route.query.app_store) {
      if (this.isAndroid) {
        window.location =
          'https://play.google.com/store/apps/details?id=fod.takein'
      } else if (this.isIOS) {
        window.location = 'https://apps.apple.com/us/app/takein/id1479232566'
      }
    }
  }
}
</script>
<style scoped lang="scss">
.my_parallax {
  //$parallax-transition: 0.3s opacity !default;
  .v-parallax__image {
    opacity: 0.6 !important;
  }
}
.title_color {
  color: #ff3d00;
}
</style>
