import firebase from '@/firebase-init'
import moment from 'moment'

//https://core.telegram.org/bots/payments
export default {
  state: {
    sources: null,
    charges: null,
    bankAccount: null,
    settings: null,
    stripeCustomerInitialized: false
  },
  mutations: {
    updateStripeCustomerInitialized(state, payload) {
      state.stripeCustomerInitialized = payload
    },
    updateSources(state, payload) {
      state.sources = payload
    },
    updateCharges(state, payload) {
      state.charges = payload
    },
    updateBankAccount(state, payload) {
      state.bankAccount = payload
    },
    settings(state, payload) {
      state.settings = payload
    }
  },
  actions: {
    loadSettings({ commit }) {
      return firebase
        .firestore()
        .collection('admin')
        .doc('settings')
        .get()
        .then(snapshot => {
          commit('settings', snapshot.data())
          return snapshot.data()
        })
    },
    activateAccount({ commit, getters, dispatch }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      let uid = getters.user.id
      console.log('Activating account')
      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('account')
        .doc('stripe')
        .set({ email: getters.user.email })
        .then(() => {
          commit('setLoading', false)
          dispatch('checkAccount')
        })
    },
    checkAccount({ commit, getters, dispatch }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      let uid = getters.user.id
      console.log('Checking account uid', uid)

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('account')
        .doc('stripe')
        .get()
        .then(
          snapshot => {
            if (snapshot.exists) {
              console.log(
                'updateStripeCustomerInitialized has value',
                uid,
                snapshot.data(),
                payload
              )
              dispatch('getSources')

              commit(
                'updateStripeCustomerInitialized',
                snapshot.data() !== null
              )
            }
          },
          () => {
            console.log('updateStripeCustomerInitialized has not value', uid)
            commit('updateStripeCustomerInitialized', false)
          }
        )
    },
    getSources({ commit, getters }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Getting source account')

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('sources')
        .get()
        .then(querySnapshot => {
          let source = []
          querySnapshot.forEach(data => {
            const obj = data.data()
            obj._id = data.id
            source.push(obj)
          })
          commit('updateSources', source)
          //dispatch('getSources')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
        })
    },
    deleteSource({ commit, getters, dispatch }, id) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Getting source account')

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('sources')
        .doc(id)
        .delete()
        .then(() => {
          dispatch('getSources')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
        })
    },
    getCharges({ commit, getters }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Getting source account')

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('charges')
        .get()
        .then(querySnapshot => {
          let source = []
          querySnapshot.forEach(data => {
            source.push(data.data())
          })
          commit('updateCharges', source)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
        })
    },
    touchPayment(context) {
      let uid = context.getters.user.id
      firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('sources')
        .doc('ignore')
        .set({ touchTime: moment.utc().valueOf() })
    },
    addSource({ commit, getters, dispatch }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Adding source account')

      return firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('sources')
        .add({ token: payload.id })
        .then(d => {
          commit('setLoading', false)
          dispatch('setMessage', {
            title: 'In Progress',
            body: 'Please wait few second and refresh the page.'
          })
          return d
        })
        .catch(error => {
          commit('setError', error)
          return error
        })
      /*
      firebase
        .database()
        .ref(`/stripe_customers/${uid}/sources`)
        .push({ token: payload.id })
        .then(() => {
          commit('setLoading', false)
        })*/
    },
    submitCharge({ commit, getters }, payload) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      //let uid = getters.user.id
      payload.updatedAt = new Date().getTime()
      console.warn('Charge will be done in server side', payload)

      /*  firebase
        .firestore()
        .collection('profile')
        .doc(uid)
        .collection('charges')
        .add(payload)
        .then(() => {
          //dispatch('loadProfile')
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        })*/

      /* firebase.database().ref(`/stripe_customers/${uid}/charges`).push(payload).then(() => {
        setInterval(() => { commit('setLoading', false) }, 1000 * 2)
      })*/
    },
    setBankAccount({ commit }, bank) {
      commit('updateBankAccount', bank)
    }
    /* use Load
    loadBankAccount({ commit, getters }) {
      if (!getters.user) {
        console.error('error, not user detected')
        return
      }
      commit('setLoading', true)
      let uid = getters.user.id
      console.log('Getting account for ', uid)

      return firebase
        .firestore()
        .collection('admin')
        .doc('accounts')
        .collection('chefs')
        .doc(uid)
        .get()
        .then(data => {
          if (data.exists) {
            if (data.data().bank) {
              const bank = data.data().bank
              bank.id = data.id
              commit('updateBankAccount', bank)
            }
          }
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error)
          commit('setLoading', false)
        })
    },*/
  },
  getters: {
    bankAccount: state => {
      return state.bankAccount
    },
    sources: state => {
      return state.sources
    },
    charges: state => {
      return state.charges
    },
    settings: state => {
      return state.settings
    },
    stripeCustomerInitialized: state => {
      return state.stripeCustomerInitialized
    }
  }
}
