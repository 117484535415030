<template>
  <v-navigation-drawer temporary :right="true" v-model="rightDrawer" fixed app>
    <v-toolbar flat dark class="primary">
      <v-toolbar-title>Notifications</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.stop="rightDrawer = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list subheader dense>
      <v-subheader>All notifications</v-subheader>
      <v-list-item @click.native="showOrders" v-if="order_count > 0">
        <v-list-item-action>
          <v-icon>shopping_cart</v-icon>
        </v-list-item-action>
        <v-list-item-title> {{ order_count }} active orders </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item v-if="notification_count > 0" to="/messages">
        <v-list-item-action> <v-icon>data_usage</v-icon> </v-list-item-action>
        <v-list-item-title>
          {{ notification_count }} new notifications
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="invitation_count > 0" to="/invite/list">
        <v-list-item-action>
          <v-icon>far fa-envelope</v-icon>
        </v-list-item-action>
        <v-list-item-title>
          {{ invitation_count }} new Invitations
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="$router.push('/my/profile')" v-if="!validProfile">
        <v-list-item-action>
          <v-icon color="red">error</v-icon>
        </v-list-item-action>
        <v-list-item-title> Profile is not complete yet. </v-list-item-title>
      </v-list-item>

      <v-list-item
        @click="$router.push('/settings/notification')"
        v-if="!userSettings"
      >
        <v-list-item-action>
          <v-icon color="info">error</v-icon>
        </v-list-item-action>
        <v-list-item-title> Notification is not set yet. </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    open: {
      Type: Boolean
    },
    order_count: {
      Type: Number
    },
    notification_count: {
      Type: Number
    },
    invitation_count: {
      Type: Number
    },
    validProfile: {
      Type: Boolean
    },
    userSettings: {
      Type: Object
    }
  },
  data() {
    return {
      rightDrawer: false
    }
  },
  watch: {
    open: function() {
      this.rightDrawer = this.open
    }
  },
  methods: {
    showOrders() {
      console.log('showOrders')
      this.$router.push('/order/list')
    }
  }
}
</script>
