export var MapMixin = {
  computed: {},
  methods: {
    setUserLocationOnMap(force = false) {
      this.flagSetLocation = false
      this.location_dialog = false
      console.log("Setting User's current location!!!")
      let savedLocation = this.$localStorage.get('userlocation')

      if (!force && savedLocation && savedLocation.latitude) {
        console.log('Saving user location:', savedLocation)
        this.$store.dispatch('saveUserLocation', savedLocation)
      } else {
        let userLocation = this.$store.dispatch('setUserLocation')
        userLocation
          .then(p => {
            if (p != null) {
              console.log('Saving current location to to local storage')
              this.$localStorage.set('userlocation', p)
              this.$store.dispatch('setSearchLocation', p)
            } else {
              console.error(
                'Unable to get the location, setting to saved location'
              )
              if (savedLocation && savedLocation.latitude) {
                this.$store.dispatch('saveUserLocation', savedLocation)
              }
            }
          })
          .catch(e => console.error('Unable to get the location', e))
      }
    }
  }
}
