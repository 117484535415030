export var FoodMixin = {
  computed: {
    imageUrl() {
      let dish = this.product ? this.product : this.dish
      return dish && dish.photos && dish.photos.length > 0
        ? dish.photos[0].url || dish.photos[0].imageUrl
        : null
    },

    isReady() {
      return this.dish && this.dish.status === 'available'
    },
    isCooking() {
      return this.dish && this.dish.status === 'cooking'
    },
    isSoldOut() {
      return (
        this.dish &&
        (this.dish.status === 'sold out' ||
          (this.dish.status === 'available' && this.dish.amountLeft == 0))
      )
    },
    foodStatus() {
      if (this.dish) {
        if (this.dish.status === 'cooking') return 'Cooking Now'
        if (this.dish.status === 'available') return 'Ready'
        if (this.isSoldOut) return 'Sold out'
      }
      if (this.dish) {
        if (this.isNew()) {
          return 'New'
        }
      }
    },
    name() {
      if (this.food && this.food.dish) return this.food.dish.name
      if (this.dish) return this.dish.name
      return null
    },
    myDish() {
      if (this.food && this.food.dish) return this.food.dish
      if (this.dish) return this.dish
      return null
    },
    canOrderNow() {
      return (
        (this.dish.status === 'available' || this.dish.status === 'cooking') &&
        (this.dish.amountLeft > 0 || this.dish.amountLeft == -1))
    },

    unitPrice() {
      if (this.food) {
        return this.food.price
      }
      return this.dish ? this.dish.price : 0
    },
    id() {
      return this.food ? this.food.id : this.dish.id
    },

    searchOrUserLocation() {
      let loc =
        this.$store.getters.searchLocation || this.$store.getters.userLocation
      if (!loc) {
        return
      }
      return {
        lat: loc.latitude,
        lon: loc.longitude
      }
    }
  },
  methods: {
    isNew() {
      let createdTime = this.dish.createdTime

      if (createdTime) {
        let now = new Date()
        now.setDate(now.getDate() - 7)
        let cTime = new Date(createdTime)
        return cTime >= now
      }

      return false
    },
    getSubTotal(item) {

      let sub = 0
      if (item ) {
          let mod_sub = this.getModifiersSubTotal(item.modifiers)

          sub += item.totalPrice
            ? item.totalPrice
            : item.dish.price * item.orderAmount

          sub += mod_sub
        }

        console.log('calling getTotalPrice', sub)
        return sub

    },
    getModifiersSubTotal(modifiers) {
      let total = 0

      if (modifiers) {
        Object.values(modifiers).forEach(item => {
          if (item.modifier && item.modifier.options) {
            let selected = item.modifier.options.filter(option =>
              item.selected.includes(option.id)
            )
            if (selected) {
              selected.forEach(select => {
                if (select.price > 0) {
                  total += parseFloat(select.price)
                }
              })
            }
          }
        })
        console.log('All modifiers sub-total', total)
      }
      return total
    }
  }
}
